import { LazyLoadImage } from 'react-lazy-load-image-component';
import X from "../assets/img/x.png";
import ImagePlaceholder from "../assets/img/img_placeholder.png";
import { file, stringFormat } from "../utils/methods";


const PersonalDetailsForm = ({
  personalchk = void 0,
  handleblue = void 0,
  AadhaarLoadBack = false,
  aadharFileBack = "",
  photoLoad = false,
  AadhaarLoad = false,
  panLoad = false,
  formErrors = [],
  photoFile = "",
  uploadSingleFile = void 0,
  deleteFile = void 0,
  cnicFile = "",
  aadharFile = "",
  personalDetail = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {
  return (
    <>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
          <input
            className="w-100"
            id="firstName"
            type="text"
            onBlur={handleblue}
            name="firstName"
            onChange={(e) => personalchk("firstName", e)}
            value={
              !isDisabled ? personalDetail.firstName : stringFormat(data.firstName)
            }
            autoFocus={true}
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="firstName">
            First Name as per Aadhaar*
          </label>
          <p className="p-error">{errors.firstName || formErrors.firstName}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="middleName"
            type="text"
            onBlur={handleblue}
            name="middleName"
            onChange={(e) => personalchk("middleName", e)}
            value={
              !isDisabled ? personalDetail.middleName : stringFormat(data.middleName)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="middleName">
            Middle Name
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="lastName"
            type="text"
            onBlur={handleblue}
            name="lastName"
            onChange={(e) => personalchk("lastName", e)}
            value={
              !isDisabled ? personalDetail.lastName : stringFormat(data.lastName)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="lastName">
            Last Name
          </label>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pb-3 mt-1">
        <div className="col position-relative">
          <input
            className="w-100"
            id="mobile_no"
            type="number"
            onBlur={handleblue}
            name="mobile_no"
            onChange={(e) => personalchk("mobile", e)}
            value={!isDisabled ? personalDetail.mobile : stringFormat(data.mobileNo)}
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="mobile_no">
            Mobile No*
          </label>
          <p className="p-error">{errors.mobile || formErrors.mobile}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="emailId"
            type="email"
            name="emailId"
            onBlur={handleblue}
            value={!isDisabled ? personalDetail.email : stringFormat(data.emailId)}
            disabled={isDisabled}
            onChange={(e) => personalchk("email", e)}
          />
          <label className="ms-3 position-absolute" htmlFor="emailId">
            Email Id*
          </label>
          <p className="p-error">{errors.email || formErrors.email}</p>
        </div>
      </div>
      <div className="row form col-12 d-flex flex-column">
        {!isDisabled && (
          <label htmlFor="Upload Image">Upload Your Photo*</label>
        )}
        <div>
          {!photoFile.length > 0 && !isDisabled ? (
            <div>
              <label>
                {!photoFile.length && photoLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="file"
                  disabled={photoFile.length === 2}
                  onChange={(e) => uploadSingleFile(e, "Photo")}
                />
              </label>
              {!photoFile.length > 0 && (
                <p className="p-error">
                  {errors.photoFile || formErrors.photoFile}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              <div className="img-preview">
                <LazyLoadImage
                  effect='blur'
                  src={!isDisabled ? file(photoFile[0]) : file(data.photoUrl)}
                  alt="preview"
                />
                {!isDisabled && (
                  <img
                    onClick={() => deleteFile(0, "Photo")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="row row-cols-1 row-cols-lg-2 form position-relative mt-5">
        <div className="col">
          <input
            className="w-100"
            id="Adhaar_no"
            type="number"
            onBlur={handleblue}
            name="Adhaar_no"
            onChange={(e) => personalchk("Adhaar_no", e)}
            value={
              !isDisabled ? personalDetail.Adhaar_no : stringFormat(data.aadhaarNo)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="aadhaar_number">
            Aadhaar No*
          </label>
          <p className="p-error">{errors.Adhaar_no || formErrors.Adhaar_no}</p>
        </div>
      </div>
      {!isDisabled && <h6 className="mt-3">Aadhaar Card Image Front & Back</h6>}
      <div className="d-flex flex-column flex-md-row form position-relative mt-2">
        <div className="">
          <span>Front</span>
          {!aadharFile.length > 0 && !isDisabled ? (
            <div>
              <label>
                {!aadharFile.length && AadhaarLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  />
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="file"
                  disabled={aadharFile.length === 2}
                  onChange={(e) => uploadSingleFile(e, "Aadhaar")}
                />
              </label>
              {!aadharFile.length > 0 && (
                <p className="p-error">
                  {errors.aadharFile || formErrors.aadharFile}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              <div className="img-preview">
                <LazyLoadImage
                  effect='blur'
                  src={
                    !isDisabled
                      ? file(aadharFile[0])
                      : file(data.aadhaarImageFront)
                  }
                  alt="aadharFront"
                  loading="lazy"
                />
                {!isDisabled && (
                  <img
                    onClick={() => deleteFile(0, "Aadhaar")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="ms-md-5">
          <span>Back</span>
          {!aadharFileBack.length > 0 && !isDisabled ? (
            <div>
              <label>
                {!aadharFileBack.length && AadhaarLoadBack ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  />
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="file"
                  disabled={aadharFileBack.length === 2}
                  onChange={(e) => uploadSingleFile(e, "AadhaarBack")}
                />
              </label>
              {!aadharFileBack.length > 0 && (
                <p className="p-error">
                  {errors.aadharFileBack || formErrors.aadharFileBack}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              <div className="img-preview">
                <LazyLoadImage
                  effect='blur'
                  src={
                    !isDisabled
                      ? file(aadharFileBack[0])
                      : file(data.aadhaarImageBack)
                  }
                  alt="aadharBack"
                  loading="lazy"
                />
                {!isDisabled && (
                  <img
                    onClick={() => deleteFile(0, "AadhaarBack")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="row row-cols-1 row-cols-lg-2 form position-relative mt-5">
        <div className="col">
          <input
            className="w-100"
            id="pan"
            type="text"
            name="pan"
            onBlur={handleblue}
            style={{ textTransform: "uppercase" }}
            onChange={(e) => personalchk("pan", e)}
            value={!isDisabled ? personalDetail.pan : stringFormat(data.pancardNo)}
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="pan_number">
            PAN*
          </label>
          <p className="p-error">{errors.pan || formErrors.pan}</p>
        </div>
      </div>
      <div className="row form col-12 d-flex flex-column mt-3">
        <label htmlFor="Upload Image">PAN Card Image*</label>
        <div>
          {!cnicFile.length > 0 && !isDisabled ? (
            <div>
              <label>
                {!cnicFile.length && panLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                    loading="lazy"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="file"
                  disabled={cnicFile.length === 2}
                  onChange={(e) => uploadSingleFile(e, "CNIC")}
                />
              </label>
              {!aadharFileBack.length > 0 && (
                <p className="p-error">
                  {errors.cnicFile || formErrors.cnicFile}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              <div className="img-preview">
                <LazyLoadImage
                  effect='blur'
                  src={
                    !isDisabled ? file(cnicFile[0]) : file(data.pancardImageUrl)
                  }
                  alt="panCard"
                  loading="lazy"
                />
                {!isDisabled && (
                  <img
                    onClick={() => deleteFile(0, "CNIC")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PersonalDetailsForm;
