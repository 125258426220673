import { useState } from "react";
import { Alert, Modal, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { getUpdatePos } from "../store/actions/updatePos";
import { useNavigate } from "react-router-dom";
import { stringFormat } from "../utils/methods";

const POSStatus = ({ data = [], personalDetail = [] }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [remark, setRemark] = useState("");
  const approve = () => {
    makeReq("Approved");
  };

  const reject = () => {
    if (remark === "") {
      toast.error("Please enter remark.");
      return;
    }
    setShow(false);
    makeReq("Rejected");
  };

  function makeReq(status) {
    if (personalDetail.assignedBranch === "") {
      toast.error("Please select branch.");
      return;
    }
    if (personalDetail.branchManager === "") {
      toast.error("Please select branch manager.");
      return;
    }
    getUpdatePos({
      emailId: data.emailId,
      mobileNo: data.mobileNo,
      firstName: data.firstName,
      posStatus: status,
      assignedBranch: personalDetail.assignedBranch,
      branchManager: personalDetail.branchManager,
      remark: status === "Approved" ? "" : remark,
    })
      .then((response) => {
        if (response?.data?.errors) {
          const errors = response.data.errors;
          errors.forEach((element) => {
            toast.error(element.msg);
          });
        }
        if (response.status === 1) {
          response.data === "Approved" && toast.success("Pos Approved Sucessfully.");
          response.data === "Rejected" && toast.success("Pos Rejected Sucessfully.");
          navigate("/pos/all");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      {data?.posStatus !== "Rejected" &&
        data?.posStatus !== "Details Submitted" && (
          <Alert
            variant="success"
            className="d-flex justify-content-between align-items-center approved"
          >
            <span>POS Profile Status</span>
            <div className=" d-flex justify-content-center align-items-center">
              <i
                className="bi bi-check-circle-fill text-success me-2"
                style={{ marginTop: "0.2rem" }}
              ></i>
              <span>Approved</span>
            </div>
          </Alert>
        )}

      {data?.posStatus === "Rejected" && (
        <div>
          <div className="row row-cols-lg-1 form position-relative mt-2">
            <div className="col">
              <input
                className="w-100"
                id="pan"
                type="text"
                name="pan"
                value={stringFormat(data?.remark)}
                disabled={true}
              />
              <label className="ms-3 position-absolute" htmlFor="reason">
                Remark
              </label>
            </div>
          </div>
          <Alert
            variant="danger"
            className="d-flex justify-content-between align-items-center approved mt-2"
          >
            <span>POS Profile Status</span>
            <div className=" d-flex justify-content-center align-items-center">
              <i
                className="bi bi-x-circle-fill text-danger me-2"
                style={{ marginTop: "0.2rem" }}
              ></i>
              <span>Rejected</span>
            </div>
          </Alert>
        </div>
      )}

      {data?.posStatus === "Details Submitted" && (
        <div className="d-flex justify-content-center align-items-center">
          <Card>
            <Card.Body>
              <h5 className="text-center">POS Profile Status</h5>
              <div className="row row-cols-1 row-cols-lg-1 gy-4 form approved_pos">
                <div className="col d-flex align-items-center w-full d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => approve()}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => setShow(true)}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="row row-cols-lg-1 form">
            <div className="col">
              <textarea
                className="w-100"
                id="address1"
                type="text"
                name="address1"
                rows="3"
                autoFocus={true}
                placeholder="Enter remark here"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => reject()}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default POSStatus;
