import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import Constant from "../utils/constant";
import axios from "../config/axios";
import Modal from 'react-bootstrap/Modal';
import AddDoctorManagement from "./AddDoctorManagement";
import EditDoctorManagement from "./EditDoctorManagement";
import ViewDoctorManagement from "./ViewDoctorManagement";
import ReactPaginate from "react-paginate";
import { getDoctorManagementDetail } from "../store/actions/DoctorManagementDetails";
import { useDispatch, useSelector } from "react-redux";
import xls from "../assets/img/xls.png";
import { getAdminRolesInfo } from "../store/actions/personalDetails";
import { ROLES } from "../utils/variables";
import { getSurveyorManagementDetail } from "../store/actions/SurveyorManagementDetails";

const SurveyorList = () => {
    const surveyorList = useSelector((state) => state.surveyorManagementDetails.data.data);
    const total = useSelector((state) => state.surveyorManagementDetails.data.total);
    const [isAdd, setIsAdd] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [checked, setChecked] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [getId, setGetId] = useState();
    const totalPage = Math.ceil(total / 10);
    const [doctorRole, setDoctorRoles] = useState([])
    let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role

    useEffect(() => {
        let role_id = ROLES[role_name]
        if(role_id){
        getAdminRolesInfo(role_id)
        .then((res) => {
            if (res.status) {
            let doctor = res.data.filter(obj => {
                return obj.module_name == "Surveyor Management";
            });
            setDoctorRoles(doctor)
            }
        })
        }
    }, [])

    useEffect(() => {
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [total]);

    useEffect(() => {
        dispatch(getSurveyorManagementDetail(currentPage, itemsPerPage))
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [currentPage, itemsPerPage, checked])

    const paginationHandlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        // dispatch(getDoctorManagementDetail(currentPage, itemsPerPage)).then()
    };

    useEffect(() => {
        setTimeout(() => {
          setShow(true);
        }, 500);
    }, [])
const ShowEntries = ({ totalPage, total, setItemsPerPage, itemsPerPage }) => {
   
  const temp = [];
  for (let i = 1; i <= totalPage; i++) {
    i === totalPage ? temp.push(total) : temp.push(10 * i);
  }
  return (
    <div className="d-flex justify-content-between">
    <div className="d-flex  col-lg-3 align-items-center mb-3 p-0">
      <span>Show</span>
      <select
        className="form-select ms-2 me-2"
        value={itemsPerPage}
        onChange={(e) => setItemsPerPage(e.target.value)}
      >
        {temp.map((d, i) => (
          <option value={d} key={Date.now() + i} defaultValue={itemsPerPage === d}>
            {d}
          </option>
        ))}
      </select>
      <span>Entries</span>
          </div>
          
</div>
  );
};
    return (
        <>
        {show ? <div className="pos cattle-insurance">
        <Header 
            title={"Surveyor Details"}
            btn={doctorRole[0]?.create_action === 1 ? true : false}
            btnText="Add Surveyor"
            btnClass="p-btn"
            onClick={() => setIsAdd(true)}
        />

    <div className="p-4 bg-white shadow rounded-3">
        <ShowEntries
        totalPage={totalPage}
        total={total}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={itemsPerPage}
      />
                    <div className="table-responsive">
                        
            <table className="table table-bordered table-striped" width="auto">
                    <thead style={{ backgroundColor: "#edf5fb" }}>
                        <tr scope="col" className="text-uppercase" style={{fontSize:"13px"}}>
                        <th scope="col" className="text-center">Surveyor Reg ID</th>
                        <th scope="col" className="text-center">Surveyor Name</th>
                        <th scope="col" className="text-center">Designation</th>
                        <th scope="col" className="text-center">Email</th>
                        <th scope="col" className="text-center">Mobile No.</th>
                        <th scope="col" className="text-center">Location</th>
                        </tr>
                    </thead>
                    <tbody style={{fontFamily:"calibri"}}>
                            {surveyorList?.length ? surveyorList?.map((item, i) => (<tr>
                                <td className="text-center" key={i}>{item.surveyor_reg_ID}</td>
                                <td className="text-center">{item.surveyor_name}</td>
                                <td className="text-center">{item.surveyor_designation}</td>
                                <td className="text-center">{item.surveyor_email}</td>
                                <td className="text-center">{item.surveyor_phone}</td>
                                <td className="text-center">{item.surveyor_location}</td>
                            </tr>)) : 
                            <tr>
                                <td colSpan={7} style={{ textAlign: 'center' }}>No Data Found</td>
                            </tr>}
                    </tbody>
            </table>
            </div>
            <div className="table_paginate">
                <ReactPaginate
                    containerClassName="pagination"
                    nextClassName="page-item"
                    pageClassName="page-item"
                    nextLabel="Next"
                    onPageChange={paginationHandlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    disableInitialCallback={true}
                    previousLabel="Previous"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </div>
        </div> : <Loading />}
        </>
    );
};

export default SurveyorList;
