

const EndorsementRemark = ({
  updateValue = void 0,
  personalDetail = [],
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {
  return (
    <>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
          <textarea
            className="w-100"
            id="endorsementRemark"
            rows="4" cols="50"
            name="endorsementRemark"
            maxLength={50}
            onChange={(e) => updateValue("endorsementRemark", e)}
            value={
              !isDisabled
                ? personalDetail.endorsementRemark
                : data.endorsementRemark
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Endorsement Remark">
           Endorsement Remark
          </label>
            <p className="p-error">{errors?.endorsementRemark || formErrors?.endorsementRemark}</p>
        </div>
      </div>



      <hr />
    </>
  );
};

export default EndorsementRemark;
