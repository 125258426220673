import { LazyLoadImage } from 'react-lazy-load-image-component';
import X from "../assets/img/x.png";
import ImagePlaceholder from "../assets/img/img_placeholder.png";
import { file, stringFormat } from "../utils/methods";


const BankDetailsForm = ({
  personalchk = void 0,
  personalDetail = [],
  bankLoad = false,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  chequeFile = "",
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {
  function checkBankCopyName(value) {
    if (isDisabled && data?.bankcopyName === value) {
      return true;
    }
    return false;
  }
  return (
    <>   
      <div className="row row-cols-1 row-cols-lg-2 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name="offers"
            onChange={(e) => personalchk("bankName", e)}
            value={
              !isDisabled ? personalDetail.bankName : stringFormat(data.bankName)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Bank Name
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name="offers"
            onChange={(e) => personalchk("accountHolderName", e)}
            value={
              !isDisabled && personalDetail.firstName
                ? `${personalDetail.firstName} ${personalDetail.middleName} ${personalDetail.lastName}`
                : stringFormat(data.accountHolderName)
            }
            disabled={true}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Account Holder Name
          </label>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-2 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            name="offers"
            onChange={(e) => personalchk("accountNumber", e)}
            value={
              !isDisabled
                ? personalDetail.accountNumber
                : stringFormat(data.accountNumber)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Account Number
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name="offers"
            onChange={(e) => personalchk("ifscCode", e)}
            value={
              !isDisabled ? personalDetail.ifscCode : stringFormat(data.ifscCode)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            IFSC Code
          </label>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-3 form">
        <div className="col d-flex align-items-center">
          {!checkBankCopyName("Cancelled Cheque") ? (
            <input
              type="radio"
              id="Cancelled Cheque"
              name="radio-group"
              className="me-3"
              value="Cancelled Cheque"
              onChange={(e) => personalchk("bankcopyName", e)}
              disabled={isDisabled}
              checked={personalDetail.bankcopyName === "Cancelled Cheque"}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Cancelled Cheque</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkBankCopyName("Passbook") ? (
            <input
              type="radio"
              id="Cancelled Cheque"
              name="radio-group"
              className="me-3"
              value="Passbook"
              onChange={(e) => personalchk("bankcopyName", e)}
              checked={personalDetail.bankcopyName === "Passbook"}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Passbook</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkBankCopyName("Bank Statement") ? (
            <input
              type="radio"
              id="Bank Statement"
              name="radio-group"
              className="me-3"
              onChange={(e) => personalchk("bankcopyName", e)}
              value="Bank Statement"
              disabled={isDisabled}
              checked={personalDetail.bankcopyName === "Bank Statement"}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Bank Statement</span>
        </div>
      </div>
      <div className="row form col-12 d-flex flex-column">
        {!isDisabled && <label htmlFor="Upload File">Upload File</label>}
        <div>
          {(!chequeFile.length > 0 && !personalDetail.bankCopyImageUrl) && !isDisabled ? (
            <div>
              <label>
                {!chequeFile.length && bankLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}

                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="file"
                  disabled={chequeFile.length === 2}
                  onChange={(e) => uploadSingleFile(e, "Cheque")}
                />
              </label>
              {!chequeFile.length > 0 && (
                <p className="p-error">
                  {errors.chequeFile || formErrors.chequeFile}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.bankCopyImageUrl !== null && (
                <div className="img-preview">
                {data?.bankCopyImageUrl?.includes(".pdf")==true ? (
                  <a className='btn btn-primary' href={file(data?.bankCopyImageUrl)} target="_blank">Preview PDF File</a>   
                ):(
                  <LazyLoadImage effect="blur" src={file(data?.bankCopyImageUrl)} alt="preview" />
                )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">

                {(chequeFile?.[0] || personalDetail?.bankCopyImageUrl)?.includes(".pdf")==true ? (
                  <a className='btn btn-primary' href={file(chequeFile[0] || personalDetail?.bankCopyImageUrl)} target="_blank">Preview PDF File</a>   
                ):(
                    <img src={file(chequeFile?.[0] || personalDetail?.bankCopyImageUrl)} alt="preview" />
                  )
                }
                  <img
                    onClick={() => deleteFile(0, "Cheque")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <hr />
    </>
  );
};

export default BankDetailsForm;
