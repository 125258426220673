import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringFormatHandleEmpty, file, format_date, cattleformat_date } from "../utils/methods";
import Select from "react-select";
import { getState } from "../store/actions/listStates";
import { getCity } from "../store/actions/city";
import { getPinCode } from "../store/actions/pinCode";
import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../assets/img/x.png";
import ImagePlaceholder from "../assets/img/img_placeholder.png";
import axios from 'axios';
import { toast } from "react-toastify";
import Constant from "../utils/constant";

const InvestigatorDetails = ({
  personalchk = void 0,
  personalDetail = [],
  searchInvestigatorNumber = [],
  formErrors = [],
  errors = [],
  selectInvestigatorState = "",
  setSelectInvestigatorState = void 0,
  selectInvestigatorCity = "",
  setSelectInvestigatorCity = void 0,
  selectInvestigatorPinCode = "",
  setSelectInvestigatorPinCode = void 0,
  investigatorFileLoad = false,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  investigatorFile = "",
  isDisabled = false,
  data = [],
  isShowView
}) => {

    const [filteredResults, setFilteredResults] = useState([]);
    const [isShow, setIsShow] = useState(isShowView);
    const [searchInput, setSearchInput] = useState('');

    const listState = useSelector((state) => state.posState.data);
    const listCity = useSelector((state) => state.posCity.data);
    const listPinCode = useSelector((state) => state.posPinCode.data);
  
    const dispatch = useDispatch();
    
    useEffect(() => {
      if (selectInvestigatorPinCode !== "") {
      delete formErrors.pincode;
      }
    }, [selectInvestigatorState, selectInvestigatorCity, selectInvestigatorPinCode]);
    
    useEffect(() => {
        !isDisabled && dispatch(getState());
    }, []);

    const setState = (id) => {
        setSelectInvestigatorState(id);
        if (id === "") {
        setSelectInvestigatorCity("");
        setSelectInvestigatorPinCode("");
        }
        dispatch(getCity(id));
    };

    const setCity = (id) => {
        setSelectInvestigatorCity(id);
        if (id === "") {
        setSelectInvestigatorPinCode("");
        }
        dispatch(getPinCode(id));
    };

    const setPinCode = (id) => {
        setSelectInvestigatorPinCode(id);
    };

    const searchItems = (searchValue) => {
      setSearchInput(searchValue)
    }

    const searchByNumber = () => {
        if (searchInput !== '') {
             axios.post( Constant.BASE_URL +'investigator/searchInvestigator', {investigator_phone : searchInput})
            .then(response => {
              setFilteredResults(response)
              setIsShow(response?.data?.status)
              if(response?.data?.status == 0){
                toast.error("Investigator not found")
              }
              if(response?.data?.status == 1){
                toast.success("Investigator is Assigned")
              }
            })
            .catch((error) => {
              toast.error(error.message);
            });
        }
        else{
            setFilteredResults([])
        }
    }

  const {data: assignedUser} = filteredResults;

  return (
    <> 
      {!isDisabled && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            type="number"
            maxLength={10}
            onChange={(e) => {
              searchItems(e.target.value)
              personalchk("investigator_phone_search", e)
            }}
            value={searchInvestigatorNumber.investigator_phone_search}
            name="investigator_phone_search"
            placeholder="Search by phone No."
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Investigator Search
          </label>
        </div>
        <div style={{ width: '20%' }}>
          <button style={{height:"45px", width:"115px", fontSize: '16px', marginTop: '5px'}} className="btn btn-primary" onClick={()=>searchByNumber()}>Assign</button>
        </div>
        {/* {assignedUser?.data ? <div style={{ alignSelf: 'center', fontFamily: "calibri", fontSize: '16px', width: '46%' }}>
          <b>Assigned Investigator :</b> {assignedUser?.data?.investigator_name || ''}, {assignedUser?.data?.investigator_phone || ''}, {assignedUser?.data?.investigator_location || ''}, {format_date(assignedUser?.data?.investigator_createdAt)}
        </div> : ''} */}
      </div>}

      {assignedUser?.data && <label htmlFor="" style={{color:"#29166F", fontSize:"18px"}}>Assigned Investigator</label>}
      {assignedUser?.data && <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3">
        <div>
          <b>Name :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.investigator_name || ''}</span>
        </div>
        <div>
          <b>Phone :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.investigator_phone || ''}</span>
        </div>
        <div>
          <b>Location :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.investigator_location || ''}</span>
        </div>
        <div>
          <b>Date :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px',textTransform:"capitalize" }}>{cattleformat_date(new Date().toISOString().slice(0, 10)) || ''}</span>
        </div>
      </div>} 

      {!isDisabled && <div>
        {filteredResults.length > 1 && 
            filteredResults.map((item) => {
                return (
                    <div className="py-2">
                      {item.mobile}
                    </div>
                )
            })
        }
        {filteredResults === "" && <div className="mb-3">{(filteredResults.length === 0) && "No result found"}</div>}
      </div>}

      {isShow == 0 && !isDisabled && <label htmlFor="" style={{color:"#29166F", fontSize:"18px", marginBottom: '20px', marginBottom: '20px'}}>Or Add New Investigator</label>}

      {isShow == 0 && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
            <input
                className="w-100"
                id="investigator_name"
                type="text"
                name="investigator_name"
                onChange={(e) => personalchk("investigator_name", e)}
                value={
                !isDisabled ? personalDetail.investigator_name : stringFormatHandleEmpty(data.investigator_name)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Investigator Name
            </label>
            <p className="p-error">{errors?.investigator_name || formErrors?.investigator_name}</p>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                id="investigator_reg_ID"
                type="text"
                name="investigator_reg_ID"
                onChange={(e) => personalchk("investigator_reg_ID", e)}
                value={
                !isDisabled ? personalDetail.investigator_reg_ID : stringFormatHandleEmpty(data.investigator_reg_ID)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Investigator Reg ID
            </label>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                type="text"
                name="investigator_address"
                onChange={(e) => personalchk("investigator_address", e)}
                value={
                !isDisabled ? personalDetail.investigator_address : stringFormatHandleEmpty(data.investigator_address)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Investigator Address
            </label>
            <p className="p-error">{errors?.investigator_address || formErrors?.investigator_address}</p>
            </div>
            <div className="col position-relative">
            {!isDisabled ? (
            <Select
                options={listState}
                isClearable={!isDisabled}
                onChange={(e) => (e != null ? setState(e.value) : setState(""))}
                className="searchable-select"
                name="investigator_state"
                // value={personalDetail.investigator_state}
            />
            ) : (
            <input
                className="w-100"
                value={stringFormatHandleEmpty(data.investigator_state)}
                disabled={true}
            />
            )}
            <label className="ms-3 position-absolute" htmlFor="landmark">
            Select State
            </label>
            {selectInvestigatorState === "" && (
            <p className="p-error">{errors?.investigator_state || formErrors?.investigator_state}</p>
            )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectInvestigatorState === "" ? true : false}
                    onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    className="searchable-select"
                    name="investigator_city"
                    // value={personalDetail.investigator_city}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.investigator_city)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select City
                </label>
                {selectInvestigatorState === "" && (
                <p className="p-error">{errors?.investigator_city || formErrors?.investigator_city}</p>
                )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectInvestigatorCity === "" ? true : false}
                    onChange={(e) =>
                    e != null ? setPinCode(e.value) : setPinCode("")
                    }
                    className="searchable-select"
                    name="investigator_zipcode"
                    // value={personalDetail.investigator_zipcode}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.investigator_zipcode)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select Pincode
                </label>
                {selectInvestigatorState === "" && (
                <p className="p-error">{errors?.investigator_zipcode || formErrors?.investigator_zipcode}</p>
                )}
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="investigator_block"
                type="text"
                name="investigator_block"
                onChange={(e) => personalchk("investigator_block", e)}
                value={
                  !isDisabled ? personalDetail.investigator_block : stringFormatHandleEmpty(data.investigator_block)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Block
              </label>
              <p className="p-error">{errors?.investigator_block || formErrors?.investigator_block}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="investigator_village"
                type="text"
                name="investigator_village"
                onChange={(e) => personalchk("investigator_village", e)}
                value={
                  !isDisabled ? personalDetail.investigator_village : stringFormatHandleEmpty(data.investigator_village)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Village
              </label>
              <p className="p-error">{errors?.investigator_village || formErrors?.investigator_village}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="investigator_phone"
                type="number"
                name="investigator_phone"
                onChange={(e) => personalchk("investigator_phone", e)}
                value={
                  !isDisabled ? personalDetail.investigator_phone || assignedUser?.data?.investigator_phone : stringFormatHandleEmpty(data.investigator_phone)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Mobile No.
              </label>
              <p className="p-error">{errors?.investigator_phone || formErrors?.investigator_phone}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="investigator_email"
                type="text"
                name="investigator_email"
                onChange={(e) => personalchk("investigator_email", e)}
                value={
                  !isDisabled ? personalDetail.investigator_email : stringFormatHandleEmpty(data.investigator_email)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Email
              </label>
              <p className="p-error">{errors?.investigator_email || formErrors?.investigator_email}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="investigator_location"
                type="text"
                name="investigator_location"
                onChange={(e) => personalchk("investigator_location", e)}
                value={
                  !isDisabled ? personalDetail.investigator_location : stringFormatHandleEmpty(data.investigator_location)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Location
              </label>
              <p className="p-error">{errors?.investigator_location || formErrors?.investigator_location}</p>
            </div>
        </div>}
        

      {isShow == 0 && <div className="row form col-12 d-flex flex-column">
        {!isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px"}}>Upload File</label>}
        <div>
          {!investigatorFile.length > 0 &&
          !personalDetail.investigator_upload &&
          !isDisabled ? (
            <div>
              <label>
                {!investigatorFile.length && investigatorFileLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="investigatorDocs"
                  disabled={investigatorFile.length === 2}
                  onChange={(e) => uploadSingleFile(e, "investigatorDocs")}
                />
              </label>
              {!investigatorFile.length > 0 && (
                <p className="p-error">
                  {errors.investigatorFile || formErrors.investigatorFile}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.investigator_upload !== null && (
                <div className="img-preview">
                  {data?.investigator_upload?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.investigator_upload)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.investigator_upload)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    investigatorFile?.[0] || personalDetail?.investigator_upload
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        investigatorFile[0] || personalDetail?.investigator_upload
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        investigatorFile?.[0] || personalDetail?.investigator_upload
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "investigatorDocs")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>}



    </>
  );
};

export default InvestigatorDetails;
