import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringFormatHandleEmpty, file, format_date, cattleformat_date } from "../utils/methods";
import Select from "react-select";
import { getState } from "../store/actions/listStates";
import { getCity } from "../store/actions/city";
import { getPinCode } from "../store/actions/pinCode";
import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../assets/img/x.png";
import ImagePlaceholder from "../assets/img/img_placeholder.png";
import axios from 'axios';
import { toast } from "react-toastify";
import Constant from "../utils/constant";

const AddDoctor = ({
  personalchk = void 0,
  personalDetail = [],
  searchDoctorNumber = [],
  formErrors = [],
  errors = [],
  selectDoctorState = "",
  setSelectDoctorState = void 0,
  selectDoctorCity = "",
  setSelectDoctorCity = void 0,
  selectDoctorPinCode = "",
  setSelectDoctorPinCode = void 0,
  loaderCount = false,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  doctorPhotoUpload = "",
  isDisabled = false,
  data = [],
  isEdit = false,
  idDisabled = false,
  isShowView
}) => {

    const listState = useSelector((state) => state.posState.data);
    const listCity = useSelector((state) => state.posCity.data);
    const listPinCode = useSelector((state) => state.posPinCode.data);
  
    const dispatch = useDispatch();
    
    useEffect(() => {
      if (selectDoctorPinCode !== "") {
      delete formErrors.pincode;
      }
    }, [selectDoctorState, selectDoctorCity, selectDoctorPinCode]);
    
    useEffect(() => {
        !isDisabled && dispatch(getState());
    }, []);

    const setState = (id) => {
        setSelectDoctorState(id);
        if (id === "") {
        setSelectDoctorCity("");
        setSelectDoctorPinCode("");
        }
        dispatch(getCity(id));
    };

    const setCity = (id) => {
        setSelectDoctorCity(id);
        if (id === "") {
        setSelectDoctorPinCode("");
        }
        dispatch(getPinCode(id));
    };

    const setPinCode = (id) => {
        setSelectDoctorPinCode(id);
    };

  return (
    <> 
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
            <input
                className="w-100"
                id="doctor_name"
                type="text"
                name="doctor_name"
                onChange={(e) => personalchk("doctor_name", e)}
                value={
                !isDisabled ? personalDetail.doctor_name : stringFormatHandleEmpty(data.doctor_name)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Veterinary Doctor Name
            </label>
            <p className="p-error">{errors?.doctor_name || formErrors?.doctor_name}</p>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                id="doctor_designation"
                type="text"
                name="doctor_designation"
                onChange={(e) => personalchk("doctor_designation", e)}
                value={
                !isDisabled ? personalDetail.doctor_designation : stringFormatHandleEmpty(data.doctor_designation)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Designation
            </label>
            <p className="p-error">{errors?.doctor_designation || formErrors?.doctor_designation}</p>
            </div>
            <div className="col position-relative">
            {!idDisabled ? ( <input
                className="w-100"
                id="doctor_reg_ID"
                type="text"
                name="doctor_reg_ID"
                onChange={(e) => personalchk("doctor_reg_ID", e)}
                value={
                !isDisabled ? personalDetail.doctor_reg_ID : stringFormatHandleEmpty(data.doctor_reg_ID)
                }
                disabled={isDisabled}
          />) : (
                <input
                className="w-100"
                value={(personalDetail.doctor_reg_ID)}
                disabled={true}
            />
            )}
            <label className="ms-3 position-absolute" htmlFor="others">
                Doctor Reg ID
            </label>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                type="text"
                name="doctor_address"
                onChange={(e) => personalchk("doctor_address", e)}
                value={
                !isDisabled ? personalDetail.doctor_address : stringFormatHandleEmpty(data.doctor_address)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Doctor Address
            </label>
            <p className="p-error">{errors?.doctor_address || formErrors?.doctor_address}</p>
            </div>
            <div className="col position-relative">
            {!isDisabled ? <>
            {isEdit ?<Select
                options={listState}
                isClearable={!isDisabled}
                onChange={(e) => (e != null ? setState(e.value) : setState(""))}
                className="searchable-select"
                name="doctor_state"
                defaultValue={{ label: personalDetail.doctor_state, value: personalDetail.doctor_state }}
            /> : <Select
                options={listState}
                isClearable={!isDisabled}
                onChange={(e) => (e != null ? setState(e.value) : setState(""))}
                className="searchable-select"
                name="doctor_state"
                // value={personalDetail.doctor_state}
            />}
            </> : (
            <input
                className="w-100"
                value={stringFormatHandleEmpty(data.doctor_state)}
                disabled={true}
            />
            )}
            <label className="ms-3 position-absolute" htmlFor="landmark">
            Select State
            </label>
            {selectDoctorState === "" && (
            <p className="p-error">{errors?.doctor_state || formErrors?.doctor_state}</p>
            )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? <>
                {isEdit ? <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectDoctorState === "" ? true : false}
                    onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    className="searchable-select"
                    name="doctor_city"
                    defaultValue={{ label: personalDetail.doctor_city, value: personalDetail.doctor_city }}
                /> : 
                <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectDoctorState === "" ? true : false}
                    onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    className="searchable-select"
                    name="doctor_city"
                    // value={personalDetail.doctor_city}
                />}
                </> : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.doctor_city)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select City
                </label>
                {selectDoctorState === "" && (
                <p className="p-error">{errors?.doctor_city || formErrors?.doctor_city}</p>
                )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? <>
                {isEdit ? <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectDoctorCity === "" ? true : false}
                    onChange={(e) =>
                    e != null ? setPinCode(e.value) : setPinCode("")
                    }
                    className="searchable-select"
                    name="doctor_zipcode"
                    defaultValue={{ label: personalDetail.doctor_zipcode, value: personalDetail.doctor_zipcode }}
                /> : 
                <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectDoctorCity === "" ? true : false}
                    onChange={(e) =>
                    e != null ? setPinCode(e.value) : setPinCode("")
                    }
                    className="searchable-select"
                    name="doctor_zipcode"
                    // value={personalDetail.doctor_zipcode}
                />}
                </> : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.doctor_zipcode)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select Pincode
                </label>
                {selectDoctorState === "" && (
                <p className="p-error">{errors?.doctor_zipcode || formErrors?.doctor_zipcode}</p>
                )}
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_block"
                type="text"
                name="doctor_block"
                onChange={(e) => personalchk("doctor_block", e)}
                value={
                  !isDisabled ? personalDetail.doctor_block : stringFormatHandleEmpty(data.doctor_block)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Block
              </label>
              <p className="p-error">{errors?.doctor_block || formErrors?.doctor_block}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_village"
                type="text"
                name="doctor_village"
                onChange={(e) => personalchk("doctor_village", e)}
                value={
                  !isDisabled ? personalDetail.doctor_village : stringFormatHandleEmpty(data.doctor_village)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Village
              </label>
              <p className="p-error">{errors?.doctor_village || formErrors?.doctor_village}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_phone"
                type="number"
                name="doctor_phone"
                onChange={(e) => personalchk("doctor_phone", e)}
                value={
                  !isDisabled ? personalDetail.doctor_phone : stringFormatHandleEmpty(data.doctor_phone)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Mobile No.
              </label>
              <p className="p-error">{errors?.doctor_phone || formErrors?.doctor_phone}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_email"
                type="text"
                name="doctor_email"
                onChange={(e) => personalchk("doctor_email", e)}
                value={
                  !isDisabled ? personalDetail.doctor_email : stringFormatHandleEmpty(data.doctor_email)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Email
              </label>
              <p className="p-error">{errors?.doctor_email || formErrors?.doctor_email}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_location"
                type="text"
                name="doctor_location"
                onChange={(e) => personalchk("doctor_location", e)}
                value={
                  !isDisabled ? personalDetail.doctor_location : stringFormatHandleEmpty(data.doctor_location)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Location
              </label>
              <p className="p-error">{errors?.doctor_location || formErrors?.doctor_location}</p>
            </div>
        </div>
        

      <div className="row form col-12 d-flex flex-column">
        {!isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px"}}>Upload File</label>}
        <div>
          {!doctorPhotoUpload.length > 0 &&
          !personalDetail.doctor_upload &&
          !isDisabled ? (
            <div>
              <label>
                {!doctorPhotoUpload.length && loaderCount ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="doctor_upload"
                  disabled={doctorPhotoUpload.length === 2}
                  onChange={(e) => uploadSingleFile(e, "doctor_upload")}
                />
              </label>
              {!doctorPhotoUpload.length > 0 && (
                <p className="p-error">
                  {errors.doctorPhotoUpload || formErrors.doctorPhotoUpload}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.doctor_upload !== null && (
                <div className="img-preview">
                  {data?.doctor_upload?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.doctor_upload)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.doctor_upload)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    doctorPhotoUpload?.[0] || personalDetail?.doctor_upload
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        doctorPhotoUpload[0] || personalDetail?.doctor_upload
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        doctorPhotoUpload?.[0] || personalDetail?.doctor_upload
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "doctor_upload")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>



    </>
  );
};

export default AddDoctor;
