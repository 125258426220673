import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequestInvestigatorCattleDetail, getCattleDetail, getSearchResult } from "../store/actions/cattleDetail";
import { getCattleInfo } from "../store/actions/cattleInfo";
import { getAdminRolesInfo, updateInvestigatorRequestStatus } from "../store/actions/personalDetails";
import Header from "../components/Header";
import DatePicker from "react-datepicker";
import { cattleformat_date, editformat_date } from "../utils/methods";
import Loading from "../components/Loading";
import { getState } from "../store/actions/listStates";
import { ROLES } from "../utils/variables";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { updatePhotoDoctorUpload } from "../store/actions/Image";
import { checkImgValidation } from "../utils/methods";
import Constant from "../utils/constant";
import xls from "../assets/img/xls.png"
const InvestigatorRequestList = () => {
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const investigatorDetailState = useSelector((state) => state.cattleDetail.data.data);
  const total = useSelector((state) => state.cattleDetail.data.total);
  const [insuranceDate, setInsuranceDate] = useState("");
  const [emailId, setEmailId] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [proposerName, setProposerName] = useState("");
  const [stateName, setStateName] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const SearchResult = useSelector((state) => state.posposSearchResult.data);
  let searchTotal = useSelector((state) => state.posposSearchResult.data.total);
  const [searchPage, setSearchPage] = useState(1);
  const [show, setShow] = useState(false);
  const totalPage = Math.ceil(total / 10);
  var entries;
  const listState = useSelector((state) => state.posState.data);
  const dispatch = useDispatch();
  const [cattleRole, setCattleRoles] = useState([])
  let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role
  let investigator_phone = JSON?.parse?.(localStorage?.getItem("superUser"))?.investigator_phone
  const [editingId, setId] = useState();
  const [inspectionDate, setInspectionDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [remarks, setRemarks] = useState();
  const [isUpdateActive, setIsUpdateActive] = useState([]);
  const [investigatorPhotoUpload, setInvestigatorPhotoUpload] = useState([]);
  const [loaderCount, setLoaderCount] = useState(false);
  
  useEffect(() => {
    let role_id = ROLES[role_name]
    if(role_id){
      getAdminRolesInfo(role_id)
      .then((res) => {
        if (res.status) {
          let cattle = res.data.filter(obj => {
            return obj.module_name == "Cattle Insurance ";
          });
          setCattleRoles(cattle)
        }
      })
    }
  }, [])

    useEffect(() => {
        dispatch(getState());
    }, []);
  useEffect(() => {
    setPageCount(Math.ceil(total / itemsPerPage));
  }, [total]);

  useEffect(() => {
    setPageCount(Math.ceil(searchTotal / itemsPerPage));
  }, [SearchResult]);

  useEffect(() => {
     dispatch(getRequestInvestigatorCattleDetail(investigator_phone, currentPage, itemsPerPage));
    if (isSearched) {
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
      const payLoad = {
        page: searchPage,
        limit: itemsPerPage,
        stateName: stateName ? stateName : "",
        ownerName: ownerName ? ownerName : "",
        proposerName: proposerName ? proposerName : "",
        insuranceDate: insuranceDate ? cattleformat_date(insuranceDate) : "",
        emailId: emailId ? emailId : "",
      };
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
      dispatch(getSearchResult(payLoad)).then();
      setIsSearched(true);
    } else {
      setPageCount(0);
      setPageCount(Math.ceil(total / itemsPerPage));
    }
  }, [itemsPerPage]);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, [])

  const setposDetail = (id) => {
    setposDetail(id);
       dispatch(getCattleDetail(id));
  };

  const setposInfo = (id) => {
    setposInfo(id);
    dispatch(getCattleInfo(id));
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    if (isSearched) {
      const payLoad = {
        page: event.selected + 1,
        limit: itemsPerPage,
      stateName: stateName ? stateName : "",
        ownerName: ownerName ? ownerName : "",
        proposerName: proposerName ? proposerName : "",
        insuranceDate: insuranceDate ? cattleformat_date(insuranceDate) : "",
        emailId: emailId ? emailId : "",
      };
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
      dispatch(getSearchResult(payLoad)).then();
      setIsSearched(true);
      setSearchPage(event.selected + 1);
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
    } else {
      dispatch(getCattleDetail(event.selected + 1, itemsPerPage));
      setCurrentPage(event.selected + 1);
    }
  };

  const handleActiveUpdate = (id, idx) => {
    const temp = [...isUpdateActive]
    temp[idx]=true
    setIsUpdateActive(temp)
    // setIsUpdateActive(true)
    setId(id);
  }

  const handleAccept = (id, data) => {
    let Confrimtext = "Are you sure you want to Accept the Request?"
    if (window.confirm(Confrimtext) != true) {
      return;
    }
    if(data.status === "New"){
      updateInvestigatorRequestStatus({id: data.id, accept_decline: "Accept"})
          .then((res) => {
            res?.status === 0 && (toast.error(res?.message) );
            if (res?.status === 1) {
              toast.success("Request Accepted.");
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
    }
    setId(id);
  }

  const statusList = [
    // {
    //     label: 'New',
    //     value: 1
    // },
    // {
    //     label: 'Active',
    //     value: 2
    // },
    // {
    //     label: 'Close',
    //     value: 3
    // },
    {
        label: 'Inspection due',
        value: 4
    },
    {
        label: 'Inspection done',
        value: 5
    },
    {
        label: 'Inspection Canceled',
        value: 6
    },
     {
        label: 'Inspection Follow up',
        value: 7
    }
  ]

  const uploadSingleFile = (e, field) => {
    // validtion && setFormErrors(validate(addCattleOwnerDetails));
    if (checkImgValidation(e?.target?.files?.[0]) === false) {
        return;
    }
    if (field === "doctor_upload") {
        setLoaderCount(true);
        const formData = new FormData();
        formData.append("doctor_upload", e.target.files[0]);
        updatePhotoDoctorUpload(formData)
        .then((res) => {
            if (res.status === 0) {
              setLoaderCount(false);
            } else {
              setLoaderCount(false);
              setInvestigatorPhotoUpload([...investigatorPhotoUpload, res.photoURL]);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
  };

  const handleUpdate = (id, acceptOrDecline, data) => {
    let date = moment(inspectionDate).format('YYYY-MM-DD');
    if(acceptOrDecline === "Accept"){
      updateInvestigatorRequestStatus({id, status: selectedStatus || data.status, inspection_date: date, document_upload: investigatorPhotoUpload[0] || data.document_upload, remarks: remarks || data.remark, accept_decline: ""})
          .then((res) => {
            res?.status === 0 && (toast.error(res?.message) );
            if (res?.status === 1) {
              toast.success("Status is Updated.");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
    }else{
      updateInvestigatorRequestStatus({id, accept_decline: acceptOrDecline})
          .then((res) => {
            res?.status === 0 && (toast.error(res?.message) );
            if (res?.status === 1) {
              toast.success("Request is rejected.");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
    }
    setId()
  }

  const ShowEntries = ({ totalPage, total, setItemsPerPage, itemsPerPage }) => {
       const handleExportExcelData = () => {
    fetch(Constant.BASE_URL + `investigator/requestListToInvestigatorExcel/?investigator_phone=${investigator_phone}`, { method: 'post' } )
     .then(response => {
       
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
          a.download = `Investigator-Request-List.xlsx`;
					a.click();
				});
				//window.location.href = response.url;
		});
  }
    const temp = [];
    for (let i = 1; i <= totalPage; i++) {
      i === totalPage ? temp.push(total) : temp.push(10 * i);
    }
    return (
      <div className="d-flex justify-content-between">
      <div className="d-flex  col-lg-3 align-items-center mb-3 p-0">
        <span>Show</span>
        <select
          className="form-select ms-2 me-2"
          value={itemsPerPage}
          onChange={(e) => setItemsPerPage(e.target.value)}
        >
          {temp.map((d, i) => (
            <option value={d} key={Date.now() + i} defaultValue={itemsPerPage === d}>
              {d}
            </option>
          ))}
        </select>
        <span>Entries</span>
            </div>
     <div className="text-right" style={{cursor:"pointer"}}  onClick={()=>handleExportExcelData()} ><img style={{width:"50px", height:"50px", marginBottom:"10px"}} src={xls}></img></div>
            
  </div>
    );
  };

  return (
    <>
    {show ? <div className="pos cattle-insurance">
    <Header 
        title={"Request List"}
        btn={false}
        btnText="Add Doctor"
        btnClass="p-btn"
    />

<div className="p-4 bg-white shadow rounded-3" style={{ height: '75vh' }}>
    <ShowEntries
    totalPage={totalPage}
    total={total}
    setItemsPerPage={setItemsPerPage}
    itemsPerPage={itemsPerPage}
  />
                <div className="table-responsive" style={{ height: '80%' }}>
                    
        <table className="table table-bordered table-striped" width="auto">
                <thead style={{ backgroundColor: "#edf5fb" }}>
                    <tr scope="col" className="text-uppercase" style={{fontSize:"13px"}}>
                    <th scope="col" className="text-center">Case ID</th>
                    <th scope="col" className="text-center">Requested By</th>
                    <th scope="col" className="text-center">Inspection Date</th>
                    <th scope="col" className="text-center">Document</th>
                    <th scope="col" className="text-center">Status</th>
                    <th scope="col" className="text-center">Remarks</th>
                    <th scope="col" colSpan={'2'} className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody style={{fontFamily:"calibri"}}>
                        {investigatorDetailState?.length ? investigatorDetailState?.map((item, i) => (<tr key={i}>
                            <td className="text-center" key={i}>{item.case_ID}</td>
                            <td className="text-center">{item.requested_by}</td>
                            { editingId === item.request_id ? 
                                <td className="text-center">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Select Date"
                                        selected={inspectionDate || editformat_date(item?.inspection_date)}
                                        onKeyDown={(e) => {
                                            if(e.which == 8 || e.code == "Backspace" ||
                                            e.which == 46 || e.code == "Delete") return
                                            
                                            e.preventDefault();
                                        }}
                                        className="searchable-select"
                                name="inspectionDate"
                                minDate={moment().toDate()}
                                        onChange={(date) => setInspectionDate(date)}
                                    />
                                </td> :
                                <td className="text-center">
                                    {item.inspection_date ? cattleformat_date(item?.inspection_date) : '-'}
                                </td>
                            }
                            <td className="text-center" width={'150px'}>
                              {editingId === item.request_id && isUpdateActive[i] ? <div className="row form col-12 d-flex flex-column">
                                <div>
                                  {!investigatorPhotoUpload.length > 0 
                                   ? (
                                    <div>
                                      <label>
                                        {!investigatorPhotoUpload.length && loaderCount ? (
                                          <div
                                            className="Group"
                                            src="images/not-found.png"
                                            alt="alternative"
                                          >
                                            <span className="spinner-border spinner-border-lg"></span>
                                          </div>
                                        ) : (
                                          <input
                                            type="file"
                                            size="60"
                                            name="doctor_upload"
                                            className="custom-file-input"
                                            onChange={(e) => uploadSingleFile(e, "doctor_upload")}
                                          />
                                        )}
                                      </label>
                                    </div>
                                  ) : (
                                    <button className="btn btn-primary" style={{lineHeight:"0.75"}} onClick={() => setInvestigatorPhotoUpload([])}>
                                     Document Uploaded
                                    </button>
                                  )}
                                </div>
                              </div> : item.document_upload == "1" ? 'NA' : 'Uploaded'}  
                            </td>
                            { editingId === item.request_id ? 
                                <td className="text-center">
                                    <div className="col position-relative">
                                        <Select
                                            options={statusList}
                                            isClearable={true}
                                            onChange={(e) => setSelectedStatus(e.label)}
                                            className="searchable-select"
                                            name="status"
                                            // value={{ label: item?.status ,value: item?.status}}
                                            defaultValue={{ label: item?.status ,value: item?.status}}
                                            placeholder={'Select'}
                                        />
                                    </div>
                                </td> :
                                <td className="text-center">
                                    {item.status}
                                </td>
                            }
                            <td className="text-center">
                              {editingId === item.request_id && isUpdateActive[i] ? 
                                <div>
                                  <textarea
                                    className="w-100"
                                    id="remark"
                                    rows="4" cols="50"
                                    name={`remark${i}`}
                                    maxLength={50}
                                    onChange={(e) => setRemarks(e.target.value)}
                                    disabled={false}
                                  />
                                </div> : item.remark ? item.remark : '-'}  
                            </td>
                          {item.accept_decline !=="Decline" && <>  {item.status === "New" ? <>
                            <td className="text-center">
                            {editingId === item.request_id ? <button className="btn btn-primary" style={{lineHeight:"0.75"}} onClick={() => handleUpdate(item.id, "Accept", item)}>
                                     Submit
                                </button> : <button className="btn btn-primary" style={{lineHeight:"0.75"}} onClick={() => handleAccept(item.request_id, item)}>
                                     Accept
                                </button>}
                            </td>
                            <td className="text-center">
                                {item.status === "Inspection Canceled" ? "" : <button className="btn btn-danger" style={{lineHeight:"0.75"}} onClick={() => handleUpdate(item.id, "Decline")}>
                                    Decline
                                </button>}
                            </td>
                          </> : ""}
                          </> }
                           {item.accept_decline=="Accept" &&
                              item.status !== "New" && <td className="text-center" colSpan={'2'}>
                                    {editingId === item.request_id ? <button className="btn btn-primary" style={{lineHeight:"0.75"}} onClick={() => handleUpdate(item.id, "Accept", item)}>
                                        Submit
                                    </button> : <button className="btn btn-primary" style={{lineHeight:"0.75"}} onClick={() => handleActiveUpdate(item.request_id, i)}>
                                        Update
                                    </button>}
                              </td>
                          }
                          {item.accept_decline=="Decline" &&
                         <td className="text-center" colSpan={'2'}>
                                <button className="btn btn-danger" style={{lineHeight:"0.75", cursor: 'default'}}>
                                    Request Declined
                                </button>
                          </td>
                          }
                        </tr>)) : 
                        <tr>
                            <td colSpan={7} style={{ textAlign: 'center' }}>No Data Found</td>
                        </tr>}
                </tbody>
        </table>
        </div>
        <div className="table_paginate">
            <ReactPaginate
                containerClassName="pagination"
                nextClassName="page-item"
                pageClassName="page-item"
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                disableInitialCallback={true}
                previousLabel="Previous"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </div>
    </div>
    </div> : <Loading />}
    </>
  );
};

export default InvestigatorRequestList;
