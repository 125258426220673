import React, {useState, useEffect} from "react";
import Select from "react-select";
import { stringFormat } from "../utils/methods";

const InsurerDetails = ({
    updateData = void 0,
    insurerDetail = [],
    isDisabled = false,
    data = [],
    isEdit = false,
}) => {

    const banks = [
        {
            label: 'Agriculture Insurance Company of India Ltd',
            value: 1
        },
        {
            label: 'Bajaj Allianz General Insurance Co. Ltd',
            value: 2
        },
        {
            label: 'Future Generali India Insurance Co. Ltd',
            value: 3
        },
        {
            label: 'Go Digit General Insurance Limited',
            value: 4
        },
        {
            label: 'HDFC Ergo General Insurance Co. Ltd',
            value: 5
        },
        {
            label: 'ICICI Lombard General Insurance Co. Ltd',
            value: 6
        },
        {
            label: 'IFFCO Tokio General Insurance Co. Ltd',
            value: 7
        },
        {
            label: 'Kotak Mahindra General Insurance Co. Ltd',
            value: 8
        },
        {
            label: 'Liberty General Insurance Limited',
            value: 9
        },
        {
            label: 'Reliance General Insurance Co. Ltd',
            value: 10
        },
        {
            label: 'Royal Sundaram General Insurance Co. Ltd',
            value: 11
        },
        {
            label: 'SBI General Insurance Co. Ltd',
            value: 12
        },
        {
            label: 'Tata AIG General Insurance Co. Ltd',
            value: 13
        },
        {
            label: 'The New India Assurance Co. Ltd',
            value: 14
        },
        {
            label: 'The Oriental Insurance Co. Ltd',
            value: 15
        },
        {
            label: 'United India Insurance Company Limited',
            value: 16
        },
        {
            label: 'National Insurance Company Limited',
            value: 17
        },
    ]

    const insurancePremium = [
        {
            label: 'Normal Areas',
            value: 1
        },
        {
            label: 'North East Region / Himalayan States',
            value: 2
        },
        {
            label: 'UT',
            value: 3
        },
    ]

    const catgories = [
        {
            label: 'BPL/SC/ST',
            value: 1
        },
        {
            label: 'APL',
            value: 2
        },
    ]

    return (
        <>
            {!isEdit && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
                <div className="col position-relative">
                    <Select
                        options={banks}
                        isClearable={true}
                        onChange={(e) => (e != null ? updateData("insurer_name", e) : updateData("insurer_name", ""))}
                        className="searchable-select"
                        name="insurer_name"
                        value={!isDisabled
                            ? insurerDetail.insurer_name
                            : stringFormat(data?.insurer_name)}
                        isDisabled={isDisabled}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Insurer Name">
                        Insurer Name
                    </label>
                </div>
                <div className="col position-relative">
                    <Select
                        options={insurancePremium}
                        isClearable={true}
                        // onChange={(e) => (e != null ? updateData("region", e) : updateData("region", ""))}
                        onChange={(e) => {
                            updateData("insurer_region", e)
                        }}
                        className="searchable-select"
                        name="insurer_region"
                        value={!isDisabled
                            ? insurerDetail.insurer_region
                            : stringFormat(data?.insurer_region)}
                        isDisabled={isDisabled}
                    />
                    <label className="ms-3 position-absolute" htmlFor="region">
                        Region
                    </label>
                </div>
                <div className="col position-relative">
                    <Select
                        options={catgories}
                        isClearable={true}
                        // onChange={(e) => (e != null ? updateData("category", e) : updateData("category", ""))}
                        onChange={(e) => {
                            updateData("insurer_category", e)
                        }}
                        className="searchable-select"
                        name="insurer_category"
                        value={!isDisabled
                            ? insurerDetail.insurer_category
                            : stringFormat(data?.insurer_category)}
                        isDisabled={isDisabled}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Category">
                        Category
                    </label>
                </div>
                <div className="col position-relative">
                    <input
                        className="w-100"
                        id="central_share"
                        type="number"
                        name="central_share"
                        onChange={(e) => updateData("central_share", e)}
                        value={
                        !isDisabled
                            ? insurerDetail.central_share
                            : stringFormat(data.central_share)
                        }
                        disabled={isDisabled}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Aadhaar No.">
                        Central Share
                    </label>
                </div>
                <div className="col position-relative">
                    <input
                        className="w-100"
                        id="state_share"
                        type="number"
                        name="state_share"
                        onChange={(e) => updateData("state_share", e)}
                        value={
                        !isDisabled
                            ? insurerDetail.state_share
                            : stringFormat(data.state_share)
                        }
                        disabled={isDisabled}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Aadhaar No.">
                        State Share
                    </label>
                </div>
                <div className="col position-relative">
                    <input
                        className="w-100"
                        id="benficiary_share"
                        type="number"
                        name="benficiary_share"
                        onChange={(e) => updateData("benficiary_share", e)}
                        value={
                        !isDisabled
                            ? insurerDetail.benficiary_share
                            : stringFormat(data.benficiary_share)
                        }
                        disabled={isDisabled}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Aadhaar No.">
                        Beneficiary Share
                    </label>
                </div>
            </div>
            }
            
{isEdit && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
                <div className="col position-relative">
                    <Select
                        options={banks}
                        isClearable={true}
                        onChange={(e) => (e != null ? updateData("insurer_name", e) : updateData("insurer_name", ""))}
                        className="searchable-select"
                        name="insurer_name"
                        // value={insurerDetail.insurer_name}
                        defaultValue={{ label: insurerDetail.insurer_name, value: 0 }}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Insurer Name">
                        Insurer Name
                    </label>
                </div>
                <div className="col position-relative">
                    <Select
                        options={insurancePremium}
                        isClearable={true}
                        // onChange={(e) => (e != null ? updateData("region", e) : updateData("region", ""))}
                        onChange={(e) => {
                            updateData("insurer_region", e)
                        }}
                        className="searchable-select"
                        name="insurer_region"
                        // value={insurerDetail.insurer_region}
                        defaultValue={{ label: insurerDetail.insurer_region, value: 0 }}
                    />
                    <label className="ms-3 position-absolute" htmlFor="region">
                        Region
                    </label>
                </div>
                <div className="col position-relative">
                    <Select
                        options={catgories}
                        isClearable={true}
                        // onChange={(e) => (e != null ? updateData("category", e) : updateData("category", ""))}
                        onChange={(e) => {
                            updateData("insurer_category", e)
                        }}
                        className="searchable-select"
                        name="insurer_category"
                        // value={insurerDetail.insurer_category}
                        defaultValue={{ label: insurerDetail.insurer_category, value: 0 }}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Category">
                        Category
                    </label>
                </div>
                <div className="col position-relative">
                    <input
                        className="w-100"
                        id="central_share"
                        type="number"
                        name="central_share"
                        onChange={(e) => updateData("central_share", e)}
                        value={insurerDetail.central_share}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Central Share">
                        Central Share
                    </label>
                </div>
                <div className="col position-relative">
                    <input
                        className="w-100"
                        id="state_share"
                        type="number"
                        name="state_share"
                        onChange={(e) => updateData("state_share", e)}
                        value={insurerDetail.state_share}
                    />
                    <label className="ms-3 position-absolute" htmlFor="State Share">
                        State Share
                    </label>
                </div>
                <div className="col position-relative">
                    <input
                        className="w-100"
                        id="benficiary_share"
                        type="number"
                        name="benficiary_share"
                        onChange={(e) => updateData("benficiary_share", e)}
                        value={insurerDetail.benficiary_share}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Beneficiary Share">
                        Beneficiary Share
                    </label>
                </div>
            </div>
            }
        </>
    );
};

export default InsurerDetails;
