import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringFormatHandleEmpty, file, format_date, cattleformat_date } from "../utils/methods";
import Select from "react-select";
import { getState } from "../store/actions/listStates";
import { getCity } from "../store/actions/city";
import { getPinCode } from "../store/actions/pinCode";
import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../assets/img/x.png";
import ImagePlaceholder from "../assets/img/img_placeholder.png";
import axios from 'axios';
import { toast } from "react-toastify";
import Constant from "../utils/constant";

const SurveyorDetails = ({
  personalchk = void 0,
  personalDetail = [],
  searchSurveyorNumber = [],
  formErrors = [],
  errors = [],
  selectSurveyorState = "",
  setSelectSurveyorState = void 0,
  selectSurveyorCity = "",
  setSelectSurveyorCity = void 0,
  selectSurveyorPinCode = "",
  setSelectSurveyorPinCode = void 0,
  surveyorFileLoad = false,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  surveyorFile = "",
  isDisabled = false,
  data = [],
  isShowView,
  setAssignedSurveyor
}) => {

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [isShow, setIsShow] = useState(isShowView);

    const listState = useSelector((state) => state.posState.data);
    const listCity = useSelector((state) => state.posCity.data);
    const listPinCode = useSelector((state) => state.posPinCode.data);
  
    const dispatch = useDispatch();
    
    useEffect(() => {
      if (selectSurveyorPinCode !== "") {
      delete formErrors.pincode;
      }
    }, [selectSurveyorState, selectSurveyorCity, selectSurveyorPinCode]);
    
    useEffect(() => {
        !isDisabled && dispatch(getState());
    }, []);

    const setState = (id) => {
        setSelectSurveyorState(id);
        if (id === "") {
        setSelectSurveyorCity("");
        setSelectSurveyorPinCode("");
        }
        dispatch(getCity(id));
    };

    const setCity = (id) => {
        setSelectSurveyorCity(id);
        if (id === "") {
        setSelectSurveyorPinCode("");
        }
        dispatch(getPinCode(id));
    };

    const setPinCode = (id) => {
        setSelectSurveyorPinCode(id);
    };

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
    }
  
    const searchByNumber = () => {
          if (searchInput !== '') {
           axios.post(Constant.BASE_URL + 'surveyor/searchSurveyor', {surveyor_phone : searchInput})
              .then(response => {
                setFilteredResults(response)
                 setAssignedSurveyor(response?.data?.data)
                setIsShow(response?.data?.status)
                if(response?.data?.status == 0){
                    toast.error("Surveyor not found")
                }
                if(response?.data?.status == 1){
                    toast.success("Surveyor is Assigned")
                }
              })
              .catch((error) => {
                toast.error(error.message);
              });
          }
          else{
              setFilteredResults([])
          }
    }
    
  const {data: assignedUser} = filteredResults;

  return (
    <> 
      {!isDisabled && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            type="number"
            maxLength={10}
            onChange={(e) => {
                searchItems(e.target.value)
                personalchk("surveyor_phone_search", e)
            }}
            value={searchSurveyorNumber.surveyor_phone_search}
            name="surveyor_phone_search"
            placeholder="Search by phone No."
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Surveyor Search
          </label>
        </div>
        <div style={{ width: '20%' }}>
          <button style={{height:"45px", width:"115px", fontSize: '16px', marginTop: '5px'}} className="btn btn-primary" onClick={()=>searchByNumber()}>Assign</button>
        </div>
        {/* {assignedUser?.data ? <div style={{ alignSelf: 'center', fontFamily: "calibri", fontSize: '16px', width: '46%' }}>
          <b>Assigned Surveyor :</b> {assignedUser?.data?.surveyor_name || ''}, {assignedUser?.data?.surveyor_phone || ''}, {assignedUser?.data?.surveyor_location || ''}, {format_date(assignedUser?.data?.createdAt)}
        </div> : ''} */}
      </div>}

      {assignedUser?.data && <label htmlFor="" style={{color:"#29166F", fontSize:"18px"}}>Assigned Surveyor</label>}
      {assignedUser?.data && <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3">
        <div>
          <b>Name :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.surveyor_name || ''}</span>
        </div>
        <div>
          <b>Phone :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.surveyor_phone || ''}</span>
        </div>
        <div>
          <b>Location :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.surveyor_location || ''}</span>
        </div>
        <div>
          <b>Date :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px',textTransform:"capitalize" }}>{cattleformat_date(new Date().toISOString().slice(0, 10)) || ''}</span>
        </div>
      </div>}

      {!isDisabled && <div>
        {filteredResults.length > 1 && 
            filteredResults.map((item) => {
                return (
                    <div className="py-2">
                      {item.mobile}
                    </div>
                )
            })
        }
        {filteredResults === "" && <div className="mb-3">{(filteredResults.length === 0) && "No result found"}</div>}
      </div>}

      {isShow == 0 && !isDisabled && <label htmlFor="" style={{color:"#29166F", fontSize:"18px", marginBottom: '20px', marginBottom: '20px'}}>Or Add New Surveyor</label>}

        {isShow == 0 && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
            <input
                className="w-100"
                id="surveyor_name"
                type="text"
                name="surveyor_name"
                onChange={(e) => personalchk("surveyor_name", e)}
                value={
                !isDisabled ? personalDetail.surveyor_name : stringFormatHandleEmpty(data.surveyor_name)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Veterinary Surveyor Name
            </label>
            <p className="p-error">{errors?.surveyor_name || formErrors?.surveyor_name}</p>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                id="surveyor_designation"
                type="text"
                name="surveyor_designation"
                onChange={(e) => personalchk("surveyor_designation", e)}
                value={
                !isDisabled ? personalDetail.surveyor_designation : stringFormatHandleEmpty(data.surveyor_designation)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Designation
            </label>
            <p className="p-error">{errors?.surveyor_designation || formErrors?.surveyor_designation}</p>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                id="surveyor_reg_ID"
                type="text"
                name="surveyor_reg_ID"
                onChange={(e) => personalchk("surveyor_reg_ID", e)}
                value={
                !isDisabled ? personalDetail.surveyor_reg_ID : stringFormatHandleEmpty(data.surveyor_reg_ID)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Surveyor Reg ID
            </label>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                type="text"
                name="surveyor_address"
                onChange={(e) => personalchk("surveyor_address", e)}
                value={
                !isDisabled ? personalDetail.surveyor_address : stringFormatHandleEmpty(data.surveyor_address)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Surveyor Address
            </label>
            <p className="p-error">{errors?.surveyor_address || formErrors?.surveyor_address}</p>
            </div>
            <div className="col position-relative">
            {!isDisabled ? (
            <Select
                options={listState}
                isClearable={!isDisabled}
                onChange={(e) => (e != null ? setState(e.value) : setState(""))}
                className="searchable-select"
                name="surveyor_state"
                // value={personalDetail.surveyor_state}
            />
            ) : (
            <input
                className="w-100"
                value={stringFormatHandleEmpty(data.surveyor_state)}
                disabled={true}
            />
            )}
            <label className="ms-3 position-absolute" htmlFor="landmark">
            Select State
            </label>
            {selectSurveyorState === "" && (
            <p className="p-error">{errors?.surveyor_state || formErrors?.surveyor_state}</p>
            )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectSurveyorState === "" ? true : false}
                    onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    className="searchable-select"
                    name="surveyor_city"
                    // value={personalDetail.surveyor_city}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.surveyor_city)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select City
                </label>
                {selectSurveyorState === "" && (
                <p className="p-error">{errors?.surveyor_city || formErrors?.surveyor_city}</p>
                )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectSurveyorCity === "" ? true : false}
                    onChange={(e) =>
                    e != null ? setPinCode(e.value) : setPinCode("")
                    }
                    className="searchable-select"
                    name="surveyor_zipcode"
                    // value={personalDetail.surveyor_zipcode}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.surveyor_zipcode)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select Pincode
                </label>
                {selectSurveyorState === "" && (
                <p className="p-error">{errors?.surveyor_zipcode || formErrors?.surveyor_zipcode}</p>
                )}
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="surveyor_block"
                type="text"
                name="surveyor_block"
                onChange={(e) => personalchk("surveyor_block", e)}
                value={
                  !isDisabled ? personalDetail.surveyor_block : stringFormatHandleEmpty(data.surveyor_block)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Block
              </label>
              <p className="p-error">{errors?.surveyor_block || formErrors?.surveyor_block}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="surveyor_village"
                type="text"
                name="surveyor_village"
                onChange={(e) => personalchk("surveyor_village", e)}
                value={
                  !isDisabled ? personalDetail.surveyor_village : stringFormatHandleEmpty(data.surveyor_village)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Village
              </label>
              <p className="p-error">{errors?.surveyor_village || formErrors?.surveyor_village}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="surveyor_phone"
                type="number"
                name="surveyor_phone"
                onChange={(e) => personalchk("surveyor_phone", e)}
                value={
                  !isDisabled ? personalDetail.surveyor_phone || assignedUser?.data?.surveyor_phone : stringFormatHandleEmpty(data.surveyor_phone)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Mobile No.
              </label>
              <p className="p-error">{errors?.surveyor_phone || formErrors?.surveyor_phone}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="surveyor_email"
                type="text"
                name="surveyor_email"
                onChange={(e) => personalchk("surveyor_email", e)}
                value={
                  !isDisabled ? personalDetail.surveyor_email : stringFormatHandleEmpty(data.surveyor_email)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Email
              </label>
              <p className="p-error">{errors?.surveyor_email || formErrors?.surveyor_email}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="surveyor_location"
                type="text"
                name="surveyor_location"
                onChange={(e) => personalchk("surveyor_location", e)}
                value={
                  !isDisabled ? personalDetail.surveyor_location : stringFormatHandleEmpty(data.surveyor_location)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Location
              </label>
              <p className="p-error">{errors?.surveyor_location || formErrors?.surveyor_location}</p>
            </div>
        </div>}
        

      {isShow == 0 && <div className="row form col-12 d-flex flex-column">
        {!isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px"}}>Upload File</label>}
        <div>
          {!surveyorFile.length > 0 &&
          !personalDetail.surveyor_upload &&
          !isDisabled ? (
            <div>
              <label>
                {!surveyorFile.length && surveyorFileLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="surveyorDocs"
                  disabled={surveyorFile.length === 2}
                  onChange={(e) => uploadSingleFile(e, "surveyorDocs")}
                />
              </label>
              {!surveyorFile.length > 0 && (
                <p className="p-error">
                  {errors.surveyorFile || formErrors.surveyorFile}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.surveyor_upload !== null && (
                <div className="img-preview">
                  {data?.surveyor_upload?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.surveyor_upload)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.surveyor_upload)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    surveyorFile?.[0] || personalDetail?.surveyor_upload
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        surveyorFile[0] || personalDetail?.surveyor_upload
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        surveyorFile?.[0] || personalDetail?.surveyor_upload
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "surveyorDocs")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>}



    </>
  );
};

export default SurveyorDetails;
