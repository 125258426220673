import axios from "../../config/axios";
import {
  FETCH_SHARE_MANAGEMENT_FAILURE,
  FETCH_SHARE_MANAGEMENT_REQUEST,
  FETCH_SHARE_MANAGEMENT_SUCCESS,
} from "./types";

export const fetchShareManagementDetailSuccess = (model) => {
  return {
    type: FETCH_SHARE_MANAGEMENT_SUCCESS,
    payload: model,
  };
};
export const fetchShareManagementDetailFailure = (error) => {
  return {
    type: FETCH_SHARE_MANAGEMENT_FAILURE,
    payload: error,
  };
};

export const getShareManagementDetail = (currentPage, pagelimit) => async (dispatch) => {
  // dispatch(fetchCATTLEDetailRequest);
  await axios
    .post(`cattle/listCattleInsurer/?page=${currentPage}&limit=${pagelimit}`)
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(fetchShareManagementDetailSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchShareManagementDetailFailure(errorMsg));
    });
};