import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import AddDoctor from "../components/AddDoctor";
import { viewDoctor } from "../store/actions/personalDetails";
import { toast } from "react-toastify";

const ViewDoctorManagement = (props) => {
  const { id } = props;
  const [show, setShow] = useState(false);
  const [viewDoctorDetail, setViewDoctorDetail] = useState();

  useEffect(() => {
    viewDoctor(id)
    .then((res) => {
        if (res?.status === 1) {
            setViewDoctorDetail(res.data)
        }
    })
    .catch((error) => {
      toast.error(error.message);
    });
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

//   console.log(result, 'result')

  return (
     <div className="pos cattle-insurance">
       {show ? <div className="">
                <AddDoctor
                    isDisabled={true} 
                    data={viewDoctorDetail && viewDoctorDetail}
                    isShowView={0}
                />
      </div> : <Loading />}
    </div>
  );
};

export default ViewDoctorManagement;
