import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
// import { useParams, useNavigate, Link } from "react-router-dom"
import { listRolePermission, addPermissionMaster, listRole } from "../store/actions/personalDetails";
import { toast } from "react-toastify";
// import { getClaimInfo } from "../store/actions/claimInfo";
// import { cattleformat_date } from "../utils/methods";
// import { useDispatch, useSelector } from "react-redux";
// import Constant from "../utils/constant";
// import xls from "../assets/img/xls.png"
// import { getClaimManagementDetail } from "../store/actions/ClaimManagementDetails";
// import ReactPaginate from "react-paginate";
import Select from "react-select";

const RolePermissionDetails = () => {
  const [selectedRole, setSelectedRole] = useState(0);
  const [selectedRoleName, setSelectedRoleName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false)
  const [moduleList, setModuleList] = useState([])
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    setIsLoading(true)
    listRole()
      .then((res) => {
        if (res?.status === 1) {
          setRoleList(res.data)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [])

  useEffect(() => {
    setIsLoading(true)
    listRolePermission(selectedRole)
      .then((res) => {
        if (res?.status === 1) {
          setModuleList(res.data)
        }
        setIsLoading(false)
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [selectedRole])

  if (isLoading) return <Loading />;

  const onCheckAction = (index, event, sectionname) => {
    const newArray = moduleList.map((item, i) => {
      if (index === i) {
        return { ...item, [sectionname]: event.target.checked };
      } else {
        return item;
      }
    });
    setModuleList(newArray)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addPermissionMaster({ role_id: selectedRole, module_permission: moduleList })
      .then((res) => {
        if (res?.status === 1) {
          toast.success("Permission added successfully.");
          // navigate("/cattle-insurance/all");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const roleValues = roleList?.reduce((arr, current, index)=> [...arr, { label:current.role_name , value: current.role_id}], []);

  return (
    <div className="pos cattle-insurance">
      <Header
        title={"Role Permission"}
        btn={false}
        btnText="Add Permission"
        btnLink="/cattle-insurance/add-role-permission"
        btnClass="p-btn"
      />
      <>
        <div className="p-4 bg-white shadow rounded-3">
          <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
            <div className="col position-relative">
              <Select
                options={roleValues}
                isClearable={false}
                onChange={(e) => {
                  setSelectedRole(e.value)
                  setSelectedRoleName(e.label)
                }}
                className="searchable-select"
                name="permission_name"
                value={{ label: selectedRoleName, value: selectedRole }}
              />
              <label className="ms-3 position-absolute" htmlFor="Insurer Name">
                Role Name
              </label>
            </div>
            <div className="col position-relative">
              {selectedRoleName && <p className="mt-3" htmlFor="Insurer Name">
                Module wise permission for {selectedRoleName}
              </p>}
            </div>
          </div>
          <div className="table-responsive">
            {/* <div className="text-right d-flex justify-content-end"><img onClick={()=>handleExportExcelData()} style={{width:"50px", height:"50px", marginBottom:"10px", cursor:"pointer"}} src={xls}></img></div> */}

            <table className="table table-bordered table-striped" width="auto">
              <thead style={{ backgroundColor: "#edf5fb" }}>
                <tr scope="col" className="text-uppercase">
                  <th scope="col" className="text-center">Module Name</th>
                  <th scope="col" className="text-center">Create</th>
                  <th scope="col" className="text-center">View</th>
                  <th scope="col" className="text-center">Edit</th>
                  <th scope="col" className="text-center">Delete</th>
                </tr>
              </thead>

              <tbody style={{ fontFamily: "calibri" }}>
                {moduleList?.length ? moduleList?.map((item, i) => (<tr>
                  <td className="text-center">{item.module_name}</td>
                  <td className="text-center">
                    <input
                      className="me-2"
                      type="checkbox"
                      onChange={(e) => {
                        onCheckAction(i, e, 'create_action');
                      }}
                      name={`create${i}`}
                      value={item.create_action}
                      checked={item.create_action}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      className="me-2"
                      type="checkbox"
                      onChange={(e) => {
                        onCheckAction(i, e, 'view_action');
                      }}
                      name={`view${i}`}
                      value={checked}
                      checked={item.view_action}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      className="me-2"
                      type="checkbox"
                      onChange={(e) => {
                        onCheckAction(i, e, 'edit_action');
                      }}
                      name={`edit${i}`}
                      value={checked}
                      checked={item.edit_action}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      className="me-2"
                      type="checkbox"
                      onChange={(e) => {
                        onCheckAction(i, e, 'delete_action');
                      }}
                      name={`delete${i}`}
                      value={checked}
                      checked={item.delete_action}
                    />
                  </td>
                </tr>)) :
                  <tr>
                    <td colSpan={7} style={{ textAlign: 'center' }}>Please Select Role Name</td>
                  </tr>
                }
              </tbody>
            </table>
            <div className="text-center">
              <button className="btn btn-primary m-2" onClick={handleSubmit} disabled={selectedRoleName ? false : true}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default RolePermissionDetails
