import { FETCH_SHARE_MANAGEMENT_FAILURE, FETCH_SHARE_MANAGEMENT_REQUEST, FETCH_SHARE_MANAGEMENT_SUCCESS } from "../actions/types";

const initialState = {
    data: [],
    loading: true,
    error: ''
};

export const shareManagementDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SHARE_MANAGEMENT_REQUEST:
            return {
                ...state,
            }
        case FETCH_SHARE_MANAGEMENT_SUCCESS:
            return {
                data: action.payload,
                loading: false
            }
        case FETCH_SHARE_MANAGEMENT_FAILURE:
            return {
                data: [],
                error: action.payload
            }
        default:
            return state;
    }
}