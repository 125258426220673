import { combineReducers } from 'redux';
import {
    carMakeModalVariant,
    carMakeReducer,
    bikeMakeReducer,
    carModelsReducer,
    carPolicyTypesReducer,
    bikeModelsReducer,
    rtoReducer,
    authReducer,
    messageReducer,
    bikePolicyTypesReducer,
    bikeMakeModalVariant,   
    posPosInfoReducer,
    AlertReducer,
    cattleInfoReducer,
    doctorManagementDetailReducer,
    surveyorManagementDetailReducer,
    investigatorManagementDetailReducer,
    claimManagementDetailReducer,
    endorsementManagementDetailReducer,
    shareManagementDetailReducer

} from './reducers';

import { posCityReducer } from './reducers/city';
import { posBranchListReducer } from './reducers/branchList';
import {posQualificationReducer} from './reducers/qualification';
import {posStateReducer} from './reducers/state';
import {posPinReducer} from './reducers/pinCode';
import { posRelationManagerReducer } from './reducers/relationmanager';
import { posPosDetailReducer } from './reducers/posDetail';
import { cattleDetailReducer } from './reducers/cattleDetail';
import {posSearchResult} from "./reducers/SearchDetails";
import { posUpdatePosReducer } from './reducers/updatePos';
import { claimInfoReducer } from './reducers/claimInfo';


const rootReducer = combineReducers({
    alertState:AlertReducer,
    posState: posStateReducer,
    posQualification: posQualificationReducer,
    posCity: posCityReducer,
    posPinCode: posPinReducer,
    posBranchList:posBranchListReducer,
    carMake: carMakeReducer,
    bikeMake: bikeMakeReducer,
    carModels: carModelsReducer,
    bikeModels: bikeModelsReducer,
    rto: rtoReducer,
    policyTypes: carPolicyTypesReducer,
    bikePolicyTypes: bikePolicyTypesReducer,
    makeModalVaraint: carMakeModalVariant,
    bikeMakeModalVaraint: bikeMakeModalVariant,
    auth: authReducer,
    message: messageReducer,
    posRelationManager: posRelationManagerReducer,
     cattleDetail:cattleDetailReducer,
    posPosDetail:posPosDetailReducer,
    posposSearchResult:posSearchResult,
    posUpdatePos:posUpdatePosReducer,
    posInfo: posPosInfoReducer,
    
    cattleInfo:cattleInfoReducer,
    claimInfo:claimInfoReducer,
    doctorManagementDetails: doctorManagementDetailReducer,
    surveyorManagementDetails: surveyorManagementDetailReducer,
    investigatorManagementDetail: investigatorManagementDetailReducer,
    claimManagementDetails: claimManagementDetailReducer,
    endorsementManagementDetails: endorsementManagementDetailReducer,
     shareManagementDetails: shareManagementDetailReducer,

});

export default rootReducer;