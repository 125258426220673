import axios from "../../config/axios";
import {
  FETCH_POSDETAIL_FAILURE,
  FETCH_POSDETAIL_REQUEST,
  FETCH_POSDETAIL_SUCCESS,
  GET_SEARCH_RESULT_SUCCESS,
  GET_SEARCH_RESULT_FAILURE,
} from "./types";

export const fetchPosDetailRequest = () => {
  return {
    type: FETCH_POSDETAIL_REQUEST,
  };
};
export const fetchPosDetailSuccess = (model) => {
  return {
    type: FETCH_POSDETAIL_SUCCESS,
    payload: model,
  };
};
export const fetchPosDetailFailure = (error) => {
  return {
    type: FETCH_POSDETAIL_FAILURE,
    payload: error,
  };
};

export const getSearchResultSuccess = (model) => {
  return {
    type: GET_SEARCH_RESULT_SUCCESS,
    payload: model,
  };
};
export const getSearchResultFailure = (error) => {
  return {
    type: GET_SEARCH_RESULT_FAILURE,
    payload: error,
  };
};

export const getPosDetail = (currentPage, pagelimit) => async (dispatch) => {
  // dispatch(fetchPosDetailRequest);
  await axios
    .get(`admin/pos/?page=${currentPage}&limit=${pagelimit}`)
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(fetchPosDetailSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchPosDetailFailure(errorMsg));
    });
};

export const getSearchResult = (payLoad) => async (dispatch) => {
  await axios
    .get(
      `admin/pos/?page=${payLoad.page}&limit=${payLoad.limit}&startDate=${payLoad.startDate}&endDate=${payLoad.endDate}&mobileNo=${payLoad.mobileNo}&posName=${payLoad.posName}&posStatus=${payLoad.posStatus}`
    )
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(getSearchResultSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(getSearchResultFailure(errorMsg));
    });
};
