import { Accordion } from "react-bootstrap";
import Header from "../components/Header";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import {
  uploadProfile,
  updateAdhaarImagesBack,
  updatePan,
  updateEducationCert,
  updateBankCopy,
  updatePhotoCopy,
  updateGoodHealthCopy,
  updateBankImage,
  updateAppFormImage,
  updateDoctorCertificate,
  updatePaymentImage,
  updateAadharCopy,
  updateOtherCopy,
  updateInvestigatorCertificate,
  updatePhotoDoctorUpload
} from "../store/actions/Image";
import { addCattleInfo } from "../store/actions/personalDetails";
import { alertShow } from "../store/actions/alert";
import PersonalDetailsForm from "../components/PersonalDetailsForm";
import { useNavigate } from "react-router-dom";
import ContactDetailsForm from "../components/ContactDetailsForm";
import EducationDetailsFrom from "../components/EducationDetailsFrom";
import BankDetailsCattleForm from "../components/BankDetailsCattleForm";
import BranchForm from "../components/PolicyIssuance";
import Loading from "../components/Loading";
import PaymentDetailsCatttleForm from "../components/PaymentDetailsCatttleForm";
import CattleUsesDetailsFrom from "../components/CattleUsesDetailsFrom";
import AnimalLostDetailsFrom from "../components/AnimalLostDetailsFrom";
import AnimalDetailsFrom from "../components/AnimalDetailsFrom";
import Attachments from "../components/Attachments";
import ProposerDetails from "../components/ProposerDetails";
import OwnerDetails from "../components/OwnerDetails";
import PreviousInsurancePolicyAndClaim from "../components/PreviousInsurancePolicyAndClaim";
import SourceOfFunds from "../components/SourceOfFunds";
import PastInsurerDetails from "../components/PastInsurerDetails";
import PolicyIssuance from "../components/PolicyIssuance";
import InvestigatorDetails from "../components/InvestigatorDetails";
import DoctorDetails from "../components/DoctorDetails";
import { checkImgValidation, format_date } from "../utils/methods";

const AddCattleInfo = () => {
  const fname_regex = /^[a-zA-Z ]+$/;
  const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const mobile_regex = /^[0-9\b]+$/;

  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [addCattleOwnerDetails, setAddCattleOwnerDetails] = useState({
    proposer_segment_type: "",
    proposer_business_type: "",
    proposer_insurer: "",
    proposer_duration_coverage_required: "",
    proposer_aadhar: "",
    proposer_aadhar_photo: "",
    proposer_first_name: "",
    proposer_middle_name: "",
    proposer_last_name: "",
    proposer_address: "",
    proposer_city: "",
    proposer_state: "",
    proposer_zipcode: "",
    proposer_block: "",
    proposer_village: "",
    proposer_email: "",
    proposer_phone: "",
    is_animal_address_different: '',
    animal_address: "",
    animal_state: "",
    animal_city: "",
    animal_zipcode: "",
    animal_block: "",
    animal_village: "",
    owner_proposer_same: "",
    owner_first_name: "",
    owner_middle_name: "",
    owner_last_name: "",
    owner_aadhar: "",
    owner_aadhar_photo: "",
    owner_address: "",
    owner_city: "",
    owner_state: "",
    owner_zipcode: "",
    owner_block: "",
    owner_village: "",
    source_of_fund: "",
    past_any_prev_insurer: "",
    past_declined: "",
    past_imposed_special_condition: "",
    past_refusal: "",
    past_increased_premium: "",
    investigator_phone_search: "",
    doctor_phone_search: "",
    please_specify: ""
  })

  const [investigatorDetails, setInvestigatorDetails] = useState({
    investigator_reg_ID: "",
    investigator_email: "",
    investigator_phone: null,
    investigator_name: "",
    investigator_state: "",
    investigator_city: "",
    investigator_zipcode: null,
    investigator_block: "",
    investigator_village: "",
    investigator_address: "",
    investigator_location: "",
    investigator_upload: ""
  })

  const [doctorDetails, setDoctorDetails] = useState({
    doctor_reg_ID: "",
    doctor_designation: "",
    doctor_email: "",
    doctor_phone: null,
    doctor_name: "",
    doctor_state: "",
    doctor_city: "",
    doctor_zipcode: null,
    doctor_block: "",
    doctor_village: "",
    doctor_address: "",
    doctor_location: "",
    doctor_upload: ""
  })

  const [addPrevClaims, setAddPrevClaims] = useState(
    [
      {
        numbering: 1,
        prev_claims_year: "",
        prev_claims_insurer:"",
        prev_claims_animal_type:"",
        prev_claims_amount: "",
        prev_claims_caim_settled:"",
        prev_claims_remarks:""
      },
      {
        numbering: 2,
        prev_claims_year: "",
        prev_claims_insurer: "",
        prev_claims_animal_type: "",
        prev_claims_amount: "",
        prev_claims_caim_settled: "",
        prev_claims_remarks: ""
      },
      {
        numbering: 3,
        prev_claims_year: "",
        prev_claims_insurer: "",
        prev_claims_animal_type: "",
        prev_claims_amount: "",
        prev_claims_caim_settled: "",
        prev_claims_remarks: ""
      }
    ]
  )

  const [personalDetail, setPersonalDetail] = useState({});


  const [accountFile, setAccountFile] = useState([]);
  const [aadharFile, setAaadharFile] = useState([]);
  const [aadharFileOwner, setAaadharFileOwner] = useState([]);
  const [photoFile, setPhotoFile] = useState([]);
  const [chequeFile, setChequeFile] = useState([]);
  const [animalPhotoFile, setAnimalPhotoFile] = useState([]);
  const [animalGoodHealthFile, setGoodHealthFile] = useState([]);
  const [animalGoodHealthLoad, setAnimalGoodHealthLoad] = useState(false);
  const [doctorFile, setDoctorFile] = useState([]);
  const [investigatorFile, setInvestigatorFile] = useState([]);

  const [animalPhotoLoad, setAnimalPhotoLoad] = useState(false);
  const [bankLoader, setBankLoader] = useState(false);
  const [bankPhotoUpload, setBankPhotoUpload] = useState([]);
  const [otherPhotoUpload, setOtherPhotoUpload] = useState([]);
  const [appFormImg, setAppFormImg] = useState([]);
  const [appFormLoad, setAppFormLoad] = useState(false);
  const [doctorCertImg, setDoctorCertImg] = useState([]);
  const [paymentLoad, setPaymentLoad] = useState(false);
  const [paymentImg, setPaymentImg] = useState([]);
  const [certLoad, setCertLoad] = useState(false);
  const [offersFile, setOffersFile] = useState([]);
  const [cnicFile, setCnicFile] = useState([]);
  const [checked, setChecked] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectState, setSelectState] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const [selectPinCode, setSelectPinCode] = useState("");
  const [selectProposerState, setSelectProposerState] = useState("");
  const [selectProposerCity, setSelectProposerCity] = useState("");
  const [selectProposerPinCode, setSelectProposerPinCode] = useState("");
  const [selectOwnerState, setSelectOwnerState] = useState("");
  const [selectOwnerCity, setSelectOwnerCity] = useState("");
  const [selectOwnerPinCode, setSelectOwnerPinCode] = useState("");
  const [selectInvestigatorState, setSelectInvestigatorState] = useState("");
  const [selectInvestigatorCity, setSelectInvestigatorCity] = useState("");
  const [selectInvestigatorPinCode, setSelectInvestigatorPinCode] = useState("");
  const [selectDoctorState, setSelectDoctorState] = useState("");
  const [selectDoctorCity, setSelectDoctorCity] = useState("");
  const [selectDoctorPinCode, setSelectDoctorPinCode] = useState("");
  const [photoLoad, setphotoLoad] = useState(false);
  const [AadhaarLoad, setAadhaarLoad] = useState(false);
  const [AadhaarOwnerLoad, setAadhaarOwnerLoad] = useState(false);
  const [panLoad, setpanLoad] = useState(false);
  const [eduload, seteduload] = useState(false);
  const [bankLoad, setbankLoad] = useState(false);
  const [doctorFileLoad, setDoctorFileLoad] = useState(false);
  const [investigatorFileLoad, setInvestigatorFileLoad] = useState(false);


  const [validtion, setvalidtion] = useState(false);
  const [AadhaarLoadBack, setAadhaarLoadBack] = useState(false);
  const [animalDetailsCount, setAnimalDetailsCount] = useState(1);
  const [documentCount, setDocumentCount] = useState(0);
  const [loaderCount, setLoaderCount] = useState([]);

   useEffect(() => {
     const temp = {
       ...personalDetail,
    [`animal_gender-${animalDetailsCount - 1}`]: "male",
    [`vaccination_status-${animalDetailsCount-1}`]: "vaccination_yes",
    [`pedType-${animalDetailsCount-1}`]: "ped_yes",
    [`animal_lost_type`]: "yes",            
     }
     setPersonalDetail(temp)
   }, [animalDetailsCount])
  
    useEffect(() => {
     const temp = {
       ...personalDetail,
    [`animal_age-${animalDetailsCount-1}`]: "",
    [`animal_breed-${animalDetailsCount-1}`]: "",
    [`animal_photo-${animalDetailsCount-1}`]: "",
    [`animal_purpose-${animalDetailsCount-1}`]: "",
    [`animal_sum_insured-${animalDetailsCount-1}`]: "",
    [`ear_tag_no-${animalDetailsCount-1}`]: "",
    [`animal_vacination_details-${animalDetailsCount-1}`]: "",
    [`animal_PED_details-${animalDetailsCount-1}`]: "",
    [`animal_type-${animalDetailsCount-1}`]: "",
    [`animal_color-${animalDetailsCount-1}`]: "",
    [`animal_health_certificate-${animalDetailsCount-1}`]: "",
    [`lost_in_year-0`]: "",
    [`cause_of_loss-0`]: "",
    [`no_of_animals_lost-0`]: "",
    [`lost_in_year-1`]: "",
    [`cause_of_loss-1`]: "",
    [`no_of_animals_lost-1`]: "",
    [`lost_in_year-2`]: "",
    [`cause_of_loss-2`]: "",
    [`no_of_animals_lost-2`]: "",
    [`numbering-0`]: 1,
    [`numbering-1`]: 2,
    [`numbering-2`]: 3,
    [`identification_details-0`]: "",
    [`identification_details-1`]: "",
    [`identification_details-2`]: "",
    [`payType`]: "Online",
    [`payee_name`]: "",
    [`paymentBankName`]: "",
    [`recieved_amount`]: "",
    [`transaction_id`]: "",
    [`Cheque_No`]: "",
    [`DD`]: "",
    [`payment_details_photo`]: "",
    [`payment_date`]: "",
    [`accountHolderName`]: "",
    [`bankName`]: "",
    [`branch`]: "",
    [`ifscCode`]: "",
    [`upload_bank_photo`]: "",
    [`policy_start_date`]: "",
    [`policy_end_date`]: "",
    [`policy_issuance_date`]: "",
    [`policy_hold_date`]: "",
    [`policy_cancel_date`]: "",
    [`accountNumber`]: "",
    [`policy_issuance_status`]: "",
    [`policy_number`]: "",
    [`policy_remark`]: "",
       [`animal_photo`]: "",
       [`animal_health_certificate`]: "",
     }
     setPersonalDetail(temp)
   }, [])

   console.log(formErrors, 'formErrors')
  const navigate = useNavigate();
  const validate = (values) => {
    let errors = {...formErrors};
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const fname_regex = /^[a-zA-Z ]+$/;
    const mobile_regex = /^[0-9\b]+$/;
    const pan_regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (values.proposer_segment_type === "") {
      errors.proposer_segment_type = "Segement Type is required!";
    }
    if (values.proposer_business_type === "") {
      errors.proposer_business_type = "Business Type is required!";
    }
    if (values.proposer_insurer === "") {
      errors.proposer_insurer = "Insurer Name is required!";
    }
    if (!values.proposer_first_name) {
      errors.proposer_first_name = "First Name is Required!";
    } else if (!fname_regex.test(values.proposer_first_name)) {
      errors.proposer_first_name = "Enter Valid First Name!";
    }
    if (!values.proposer_last_name) {
      errors.proposer_last_name = "Last Name is Required!";
    } else if (!fname_regex.test(values.proposer_last_name)) {
      errors.proposer_last_name = "Enter Valid First Name!";
    }
    if (values.proposer_address === "") {
      errors.proposer_address = "Address is required!";
    }
    if (values.proposer_duration_coverage_required === "") {
      errors.proposer_duration_coverage_required = "Duration coverage is required!";
    }
    if (values.proposer_email === "") {
      errors.proposer_email = "Email is Required!";
    } else if (!email_regex.test(values.proposer_email)) {
      errors.proposer_email = "Enter Valid Email!";
    }
    if (!values.proposer_phone) {
      errors.proposer_phone = "Mobile No. is Required";
    } else if (values.proposer_phone.length < 10) {
      errors.proposer_phone = "Mobile No. must be 10 Digits";
    } else if (!mobile_regex.test(values.proposer_phone)) {
      errors.proposer_phone = "Enter valid Mobile No.";
    }
    if (!values.investigator_phone_search) {
      errors.investigator_phone_search = "Mobile No. is Required";
    } else if (values.investigator_phone_search.length < 10) {
      errors.investigator_phone_search = "Mobile No. must be 10 Digits";
    } else if (!mobile_regex.test(values.investigator_phone_search)) {
      errors.investigator_phone_search = "Enter valid Mobile No.";
    }
    if (!values.doctor_phone_search) {
      errors.doctor_phone_search = "Mobile No. is Required";
    } else if (values.doctor_phone_search.length < 10) {
      errors.doctor_phone_search = "Mobile No. must be 10 Digits";
    } else if (!mobile_regex.test(values.doctor_phone_search)) {
      errors.doctor_phone_search = "Enter valid Mobile No.";
    }
    if (values.proposer_aadhar === "") {
      errors.proposer_aadhar = "Aadhaar No. is Required";
    } else if (
      !mobile_regex.test(values.proposer_aadhar) ||
      values.proposer_aadhar.length !== 12 ||
      values.proposer_aadhar.startsWith("0") ||
      values.proposer_aadhar.startsWith("1")
    ) {
      errors.proposer_aadhar = "Enter valid Aadhaar No.";
    }
    if (values.is_animal_address_different == 1 && selectState === "") {
      errors.animal_state = "State is required!";
    }
    if (values.is_animal_address_different == 1 && selectCity === "") {
      errors.animal_city = "City is required!";
    }
    if (values.is_animal_address_different == 1 && selectPinCode === "") {
      errors.animal_zipcode = "pincode is required!";
    }
    if (values.is_animal_address_different == 1 && selectPinCode === "") {
      errors.animal_address = "Address is required!";
    }
    if (selectProposerState === "") {
      errors.proposer_state = "State is required!";
    }
    if (selectProposerCity === "") {
      errors.proposer_city = "City is required!";
    }
    if (selectProposerPinCode === "") {
      errors.proposer_zipcode = "pincode is required!";
    }
    if (values.owner_proposer_same == 1 && values.owner_aadhar === "") {
      errors.owner_aadhar = "Aadhaar No. is Required";
    } else if (values.owner_proposer_same == 1 &&
      !mobile_regex.test(values.owner_aadhar) ||
      values.owner_proposer_same == 1 && values.owner_aadhar.length !== 12 || values.owner_proposer_same == 1 && values.owner_aadhar.startsWith("0") ||
      values.owner_proposer_same == 1 && values.owner_aadhar.startsWith("1")
    ) {
      errors.owner_aadhar = "Enter valid Aadhaar No.";
    }
    if (values.owner_proposer_same == 1 && !values.owner_first_name) {
      errors.owner_first_name = "First Name is Required!";
    } else if (values.owner_proposer_same == 1 && !fname_regex.test(values.owner_first_name)) {
      errors.owner_first_name = "Enter Valid First Name!";
    }
    if (values.owner_proposer_same == 1 && !values.owner_last_name) {
      errors.owner_last_name = "Last Name is Required!";
    } else if (values.owner_proposer_same == 1 && !fname_regex.test(values.owner_last_name)) {
      errors.owner_last_name = "Enter Valid First Name!";
    }
    if (values.owner_proposer_same == 1 && values.owner_address === "") {
      errors.owner_address = "Address is required!";
    }
    if (values.owner_proposer_same == 1 && selectOwnerState === "") {
      errors.owner_state = "State is required!";
    }
    if (values.owner_proposer_same == 1 && selectOwnerCity === "") {
      errors.owner_city = "City is required!";
    }
    if (values.owner_proposer_same == 1 && selectOwnerPinCode === "") {
      errors.owner_zipcode = "pincode is required!";
    }
    if (values.source_of_fund === "") {
      errors.source_of_fund = "Field is required!";
    }
    if (values.past_any_prev_insurer === "") {
      errors.past_any_prev_insurer = "Field is required!";
    }
    if (values.payType=="Online" && values.transaction_id === "") {
      errors.transaction_id = "Transaction Id is required!";
      errors = { ...errors,  "Cheque_No":"", "DD":"" }
    }
    if (values.payType=="Cheque" && values.Cheque_No === "") {
      errors.Cheque_No = "Cheque No is required!";
      errors = { ...errors,  "transaction_id": "", "DD":"" }

    }
    if (values.payType=="DD" && values.DD==="") {
      errors.DD = "DD No is required!";
      errors = { ...errors,  "transaction_id": "", "Cheque_No":"" }

    }
    if (values.payment_date === "") {
      errors.payment_date = "Date field is required!";
    }
    if (values.accountHolderName === "") {
      errors.accountHolderName = "Account Holder Name is required!";
    }
    if (values.accountNumber === "") {
      errors.accountNumber = "Account Number is required!";
    }
    if (values.bankName === "") {
      errors.bankName = "Bank Name is required!";
    }
    if (values.ifscCode === "") {
      errors.ifscCode = "Ifsc Code is required!";
    }
    if (values.payee_name === "") {
      errors.payee_name = "Payee Name is required!";
    }
    if (values.recieved_amount === "") {
      errors.recieved_amount = "Amount is required!";
    }
    if (values.paymentBankName === "") {
      errors.paymentBankName = "Bank Name is required!";
    }
    // if (!bankPhotoUpload.length > 0) {
    //   errors.bankPhotoUpload = "Bank file is required!";
    // }
    //   if (!paymentImg.length > 0) {
    //   errors.paymentImg = "Payment file is required!";
    // }
    return errors;
  };

  const createAnimalPayload = () => {
    const payloadArr = []
        const animalDetailKeys = ["animal_gender","animal_age", "animal_breed", "animal_purpose", "animal_sum_insured", "ear_tag_no", "vaccination_status", "pedType", "animal_vacination_details", "animal_PED_details", "animal_type", "animal_color", "animal_photo", "animal_health_certificate"];
    Object.keys(personalDetail).forEach((item) => {
      const [key,idx] = item.split("-")
      if (animalDetailKeys.includes(key)) {
        let keyName = key;
        let value = personalDetail[item] || "";
        switch (key) {
          case "animal_type": keyName = "animal_type"
            break;
          case "animal_gender": keyName = "animal_gender"
            break;
          case "animal_purpose": keyName = "animal_purpose"
            break;
          case "animal_sum_insured": keyName = "animal_sum_insured"
            value = parseInt(value)
            break;
           case "animal_vacination_details": keyName = "animal_vacination_details"
            break;
           case "animal_PED_details": keyName = "animal_PED_details"
            break;
          case "animal_photo": keyName = "animal_photo"
             value = animalPhotoFile[Number(idx)]
            break;
          case "animal_health_certificate": keyName = "animal_health_certificate"
             value = animalGoodHealthFile[Number(idx)]
            break;
          case "vaccination_status": keyName = "animal_vacination"
            value = value=="vaccination_yes" ? 1 : 0
            break;
          case "pedType": keyName = "animal_PED"
            value = value=="ped_yes" ? 1 : 0
            break;
        }
        if(!payloadArr[Number(idx)]) payloadArr[Number(idx)] = {}
      payloadArr[Number(idx)][keyName]=value
    }
    })
    return payloadArr;
  }
    const createAnimalLostPayload = () => {
    const payloadArr = []
        const animalLostDetailKeys = ["animal_lost_type", "lost_in_year", "cause_of_loss", "no_of_animals_lost", "numbering", "identification_details"];
    Object.keys(personalDetail).forEach((item) => {
      const [key,idx] = item.split("-")
      if (animalLostDetailKeys.includes(key)) {
        let keyName = key;
        let value = personalDetail[item] || "";
        switch (key) {
          case "lost_in_year": keyName = "lost_in_year"
          value = parseInt(value)
            break;
          case "no_of_animals_lost": keyName = "no_of_animals_lost"
          value = parseInt(value)
            break;
        }
        if(!payloadArr[Number(idx)]) payloadArr[Number(idx)] = {animal_lost_in_last_three_year:personalDetail.animal_lost_type=="yes" ? 1 : 0}
      payloadArr[Number(idx)][keyName]=value
    }
    })
    return payloadArr;
  }

      const createPaymentDetailsPayload = () => {
    const payloadArr = {}
        const keysArray  = ["payType","payee_name", "paymentBankName", "recieved_amount", "transaction_id", "Cheque_No","DD", "payment_details_photo", "payment_date"];
    Object.keys(personalDetail).forEach((key) => {
      if (keysArray.includes(key)) {
        let keyName = key;
        let value = personalDetail[key] || "";
        switch (key) {
          case "transaction_id": 
          case "Cheque_No":
          case "DD": keyName = "chequeNo_DD_TransactionID"
            value = payloadArr[keyName] || value
            break;
          case "paymentBankName": keyName = "bank_name"
            break;
            case "payType": keyName = "payment_mode"
            break;
          case "payment_details_photo": keyName = "payment_details_photo"
            value = paymentImg[0]
            break;
          case "recieved_amount": 
            value = parseInt(value)
            break;
        }
      payloadArr[keyName]=value
    }
    })
    return payloadArr;
  }

      const createBankPayload = () => {
    const payloadArr = {}
        const keysArray  = ["accountHolderName", "bankName", "branch", "ifscCode","upload_bank_photo", "accountNumber"];
    Object.keys(personalDetail).forEach((key) => {
      if (keysArray.includes(key)) {
        let keyName = key;
        let value = personalDetail[key] || "";
        switch (key) {
          case "accountHolderName": keyName = "account_holder_name"
            break;
          case "accountNumber": keyName = "account_number"
            break;
          case "bankName": keyName = "bank_name"
            break;
          case "ifscCode": keyName = "ifsc_code"
            break;
          case "upload_bank_photo": 
            value = bankPhotoUpload[0]
            break;
        }
      payloadArr[keyName]=value
    }
    })
    return payloadArr;
  }


  const createAttachmentPayload = () => {
   const payloadArr = {}
   payloadArr.application_form = appFormImg[0] || ""
         payloadArr.doctor_certificate = doctorCertImg[0] || ""
         payloadArr.others = otherPhotoUpload?.toString() || "";
         
    return payloadArr;
  }

  const createIssuancePayload = () => {
   const payloadArr = {}
   payloadArr.policy_issuance_status = personalDetail.policy_issuance_status
   payloadArr.policy_issuance_date = personalDetail.policy_issuance_date
   payloadArr.policy_start_date = personalDetail.policy_start_date
   payloadArr.policy_end_date = personalDetail.policy_end_date
   payloadArr.policy_hold_date = personalDetail.policy_hold_date
   payloadArr.policy_cancel_date = personalDetail.policy_cancel_date
   payloadArr.policy_number = personalDetail.policy_number
   payloadArr.policy_remark = personalDetail.policy_remark
    return payloadArr;
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setvalidtion(true);
    // console.log('formsErros', formErrors)
    const errorObj = validate({...addCattleOwnerDetails, ...personalDetail})
    setFormErrors(errorObj);
   const fileteredArr = Object.values(errorObj).filter(item => item);
    if (fileteredArr.length === 0) {
      const data = {
        proposer_segment_type: addCattleOwnerDetails.proposer_segment_type,
        proposer_business_type: addCattleOwnerDetails.proposer_business_type,
        proposer_insurer: addCattleOwnerDetails.proposer_insurer.label,
        proposer_duration_coverage_required: addCattleOwnerDetails.proposer_duration_coverage_required?.value,
        proposer_aadhar: addCattleOwnerDetails.proposer_aadhar,
        proposer_aadhar_photo: aadharFile[0] || "",
        proposer_first_name: addCattleOwnerDetails.proposer_first_name,
        proposer_middle_name: addCattleOwnerDetails.proposer_middle_name,
        proposer_last_name: addCattleOwnerDetails.proposer_last_name,
        proposer_address: addCattleOwnerDetails.proposer_address,
        proposer_city: selectProposerCity,
        proposer_state: selectProposerState,
        proposer_zipcode: selectProposerPinCode,
        proposer_block: addCattleOwnerDetails.proposer_block,
        proposer_village: addCattleOwnerDetails.proposer_village,
        proposer_email: addCattleOwnerDetails.proposer_email,
        proposer_phone: addCattleOwnerDetails.proposer_phone,
        is_animal_address_different: addCattleOwnerDetails.is_animal_address_different ? 1 : 0,
        animal_address: addCattleOwnerDetails.animal_address,
        animal_state: selectState,
        animal_city: selectCity,
        animal_zipcode: selectPinCode,
        animal_block: addCattleOwnerDetails.animal_block,
        animal_village: addCattleOwnerDetails.animal_village,
        owner_proposer_same: addCattleOwnerDetails.owner_proposer_same ? 1 : 0,
        owner_first_name: addCattleOwnerDetails.owner_first_name,
        owner_middle_name: addCattleOwnerDetails.owner_middle_name,
        owner_last_name: addCattleOwnerDetails.owner_last_name,
        owner_aadhar: addCattleOwnerDetails.owner_aadhar,
        owner_aadhar_photo: aadharFileOwner[0] || "",
        owner_address: addCattleOwnerDetails.owner_address,
        owner_city: selectOwnerCity,
        owner_state: selectOwnerState,
        owner_zipcode: selectOwnerPinCode,
        owner_block: addCattleOwnerDetails.owner_block,
        owner_village: addCattleOwnerDetails.owner_village,
        source_of_fund: addCattleOwnerDetails.source_of_fund,
        past_any_prev_insurer: addCattleOwnerDetails.past_any_prev_insurer,
        past_declined: addCattleOwnerDetails.past_declined,
        past_imposed_special_condition: addCattleOwnerDetails.past_imposed_special_condition,
        past_refusal: addCattleOwnerDetails.past_refusal,
        past_increased_premium: addCattleOwnerDetails.past_increased_premium,
        investigator_phone: String(addCattleOwnerDetails.investigator_phone_search),
        doctor_phone: String(addCattleOwnerDetails.doctor_phone_search),
        please_specify: addCattleOwnerDetails.please_specify,
        created_by:JSON?.parse?.(localStorage?.getItem("superUser"))?.email
      };
      const proposer = {
        proposer_aadhar: addCattleOwnerDetails.proposer_aadhar,
        proposer_aadhar_photo: aadharFile[0],
        proposer_first_name: addCattleOwnerDetails.proposer_first_name,
        proposer_middle_name: addCattleOwnerDetails.proposer_middle_name,
        proposer_last_name: addCattleOwnerDetails.proposer_last_name,
        proposer_address: addCattleOwnerDetails.proposer_address,
        proposer_city: selectProposerCity,
        proposer_state: selectProposerState,
        proposer_zipcode: selectProposerPinCode,
        proposer_email: addCattleOwnerDetails.proposer_email,
        proposer_phone: addCattleOwnerDetails.proposer_phone
      };

      const investigator = {
        investigator_reg_ID: investigatorDetails.investigator_reg_ID,
        investigator_email: investigatorDetails.investigator_email,
        investigator_phone: String(investigatorDetails.investigator_phone),
        investigator_name: investigatorDetails.investigator_name,
        investigator_state: selectInvestigatorState,
        investigator_city: selectInvestigatorCity,
        investigator_zipcode: selectInvestigatorPinCode,
        investigator_block: investigatorDetails.investigator_block,
        investigator_village: investigatorDetails.investigator_village,
        investigator_address: investigatorDetails.investigator_address,
        investigator_location: investigatorDetails.investigator_location,
        investigator_upload: investigatorFile[0] || ""
      };

      const doctor = {
        doctor_reg_ID: doctorDetails.doctor_reg_ID,
        doctor_designation: doctorDetails.doctor_designation,
        doctor_email: doctorDetails.doctor_email,
        doctor_phone: String(doctorDetails.doctor_phone),
        doctor_name: doctorDetails.doctor_name,
        doctor_state: selectDoctorState,
        doctor_city: selectDoctorCity,
        doctor_zipcode: selectDoctorPinCode,
        doctor_block: doctorDetails.doctor_block,
        doctor_village: doctorDetails.doctor_village,
        doctor_address: doctorDetails.doctor_address,
        doctor_location: doctorDetails.doctor_location,
        doctor_upload: doctorFile[0] || ""
      };
       
      addCattleInfo({ proposerDetails: proposer, addCattleOwnerDetails: data ,addPrevClaims, addAnimals:createAnimalPayload() , animalLosts:createAnimalLostPayload(), paymentDetails:createPaymentDetailsPayload(), bankDetails:createBankPayload(), attachments:createAttachmentPayload(), policy_issuance_status: createIssuancePayload(), investigatorDetails: investigator, docterDetails: doctor })
        .then((res) => {
          res?.status === 0 && (toast.error(res?.message) );
          if (res?.data?.errors) {
            const data = res.data.errors;
            data.forEach((element) => {
              toast.error(element.msg);
            });
          }
          if (res?.status === 1) {
            toast.success("cattle insurance added successfully.");
            navigate("/cattle-insurance/all");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      toast.error("You have some errors in the form. Please re-check!");
    }
  };


  const personalchk = (sectionname, e, index) => {
  
    const currObjErr = { ...formErrors }
    currObjErr[sectionname] = ""
    // validtion && setFormErrors(validate(personalDetail));
    setFormErrors(currObjErr)
    // validtion && setFormErrors(validate(addCattleOwnerDetails));
    // const errorObj = validate({...addCattleOwnerDetails, ...personalDetail})

    const arr = ["animal_gender", "animal_age", "animal_breed", "animal_purpose", "animal_sum_insured", "ear_tag_no", "vaccination_status", "pedType", "animal_vacination_details", "animal_PED_details", "animal_type", "animal_color", "animal_photo", "animal_health_certificate"];
    const lostAnimalarray = ["animal_lost_type",  "cause_of_loss", "no_of_animals_lost", "identification_details"];
    const paymentArr = ["payType", "payee_name", "paymentBankName", "recieved_amount", "transaction_id", "Cheque_No", "DD", "payment_details_photo"];
    const bankdetailstatusArr = ["accountHolderName", "bankName", "branch", "ifscCode", "upload_bank_photo", "accountNumber"];
    const cattleusesArr = ["male_Details_yrs", "breeding", "draught", "draught_breeding", "male_others", "total_male", "female_details_yrs", "one_to_three", "milk", "dry", "claved", "female_others", "total_female", "total_animal"];
    const policyInsuranceArr = ["policy_issuance_status", "policy_number", "policy_remark"];
    const arrMatchName = sectionname.split("-")[0]
    if (arr.includes(arrMatchName) || policyInsuranceArr.includes(arrMatchName) || lostAnimalarray.includes(arrMatchName) || paymentArr.includes(arrMatchName) || bankdetailstatusArr.includes(arrMatchName) || cattleusesArr.includes(arrMatchName)) {
      setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "payment_date" || sectionname === "policy_start_date" || sectionname === "policy_end_date" || sectionname === "policy_issuance_date" || sectionname === "policy_hold_date" || sectionname === "policy_cancel_date") {
      setPersonalDetail({ ...personalDetail, [sectionname]: e });
      setErrors({ ...errors, [sectionname]: "" });
    }
    // if (cattleusesArr.includes(sectionname)) {
    //   const temp = { ...personalDetail, [sectionname]: e.target.value }
    //   if (sectionname == "male_Details_yrs"  || sectionname == "breeding"  || sectionname == "draught" || sectionname == "draught_breeding" || sectionname == "male_others" ) {
    //     temp.total_male = parseInt(temp.male_Details_yrs) + parseInt(temp.breeding) +  parseInt(temp.draught) +  parseInt(temp.draught_breeding) + parseInt(temp.male_others)  || ""
    //   }

    //   if (sectionname == "female_details_yrs"  || sectionname == "one_to_three" || sectionname == "milk" || sectionname == "dry" || sectionname == "claved" || sectionname == "female_others" ) {
    //      temp.total_female = parseInt(temp.female_details_yrs)  + parseInt(temp.one_to_three) + parseInt(temp.milk) + parseInt(temp.dry) + parseInt(temp.claved)  + parseInt(temp.female_others)|| ""
    //   }
     
    //   temp.total_animal = temp.total_male + temp.total_female || ""
    //     setPersonalDetail(temp );
    //     setErrors({ ...errors, [sectionname]: "" });
    // }
     if ((sectionname === "lost_in_year-0" || sectionname === "lost_in_year-1" || sectionname === "lost_in_year-2") && e.target.value.length <= 4) {
      setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
    }
    if (sectionname === "proposer_email") {
      if (e.target.value !== "" && !email_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
        setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e.target.value });
      } else {
        setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }

    if (sectionname === "investigator_email") {
      if (e.target.value !== "" && !email_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
        setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value });
      } else {
        setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }

    if (sectionname === "doctor_email") {
      if (e.target.value !== "" && !email_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
        setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value });
      } else {
        setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }

    if (sectionname === "proposer_phone" || sectionname === "doctor_phone_search" || sectionname === "investigator_phone_search") {
      if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
      } else {
        e.target.value !== "e" &&
          e.target.value.length <= 10 &&
          setAddCattleOwnerDetails({
            ...addCattleOwnerDetails,
            [sectionname]: parseInt(e.target.value),
          });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }

    if (sectionname === "investigator_phone") {
      if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
      } else {
        e.target.value !== "e" &&
          e.target.value.length <= 10 &&
          setInvestigatorDetails({
            ...investigatorDetails,
            [sectionname]: parseInt(e.target.value),
          });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }

    if (sectionname === "doctor_phone") {
      if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
      } else {
        e.target.value !== "e" &&
          e.target.value.length <= 10 &&
          setDoctorDetails({
            ...doctorDetails,
            [sectionname]: parseInt(e.target.value),
          });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }
    
    if ((sectionname === "proposer_aadhar" || sectionname === "owner_aadhar") && e.target.value.length <= 12) {
      setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e.target.value });
      setErrors({ ...errors, [sectionname]: "" });
    }
     if ((sectionname === "recieved_amount" || sectionname === "transaction_id" || sectionname === "Cheque_No" || sectionname === "DD" ) && e.target.value.length <= 10) {
      setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      setErrors({ ...errors, [sectionname]: "" });
    }
     if (sectionname === "accountNumber" && e.target.value.length <= 20) {
      setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (
      sectionname === "addressLine1" ||
      sectionname === "addressLine2" ||
      sectionname === "landmark" ||
      sectionname === "othersIfAny"
    ) {
      setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
    }
    if (sectionname === "source_of_fund") {
      setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e.target.value });
    }
    if (
      sectionname === "proposer_segment_type" ||
      sectionname === "proposer_business_type" 
    ) {
      setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e.target.value });
    }
    if (sectionname === "proposer_first_name" || sectionname === "proposer_middle_name" || sectionname === "proposer_last_name" ||
    sectionname === "owner_first_name" || sectionname === "owner_middle_name" || sectionname === "owner_last_name") {
      if (e.target.value !== "" && !fname_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only alphabets are allowed!" });
      } else {
        setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }
    if (sectionname === "proposer_insurer" || sectionname === "proposer_duration_coverage_required") {
      setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e});
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (
      sectionname === "past_any_prev_insurer" ||
      sectionname === "past_declined" ||
      sectionname === "past_imposed_special_condition" ||
      sectionname === "past_refusal" ||
      sectionname === "past_increased_premium"
    ) {
      setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: parseInt(e.target.value) });
    }
    if (sectionname === "is_animal_address_different" || sectionname === "owner_proposer_same") {
      setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e.target.checked });
      setErrors({ ...errors, [sectionname]: "" });
    }

    if (sectionname === "prev_claims_year" && e.target.value.length <= 4) {
      const newArray = addPrevClaims.map((item, i) => {
        if (index === i) {
          return { ...item, [sectionname]: parseInt(e.target.value) };
        } else {
          return item;
        }
      });
      setAddPrevClaims(newArray)
      // setAddPrevClaims(previousAddOrevClaims => [...previousAddOrevClaims, addPrevClaims])
      setErrors({ ...errors, [sectionname]: "" });
    }

    if (sectionname === "prev_claims_amount") {
      const newArray = addPrevClaims.map((item, i) => {
        if (index === i) {
          return { ...item, [sectionname]: parseInt(e.target.value) };
        } else {
          return item;
        }
      });
      setAddPrevClaims(newArray)
      // setAddPrevClaims(previousAddOrevClaims => [...previousAddOrevClaims, addPrevClaims])
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "prev_claims_remarks") {
      const newArray = addPrevClaims.map((item, i) => {
        if (index === i) {
          return { ...item, [sectionname]: e.target.value };
        } else {
          return item;
        }
      });
      setAddPrevClaims(newArray)
      // setAddPrevClaims(previousAddOrevClaims => [...previousAddOrevClaims, addPrevClaims])
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "prev_claims_insurer" || sectionname === "prev_claims_animal_type" || sectionname === "prev_claims_caim_settled") {
      const newArray = addPrevClaims.map((item, i) => {
        if (index === i) {
          return { ...item, [sectionname]: e.label };
        } else {
          return item;
        }
      });
      setAddPrevClaims(newArray)
      // setAddPrevClaims(previousAddOrevClaims => [...previousAddOrevClaims, addPrevClaims])
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "proposer_address" || sectionname === "animal_address" || sectionname === "owner_address" || sectionname === "proposer_block" || sectionname === "proposer_village" || sectionname === "animal_block" || sectionname === "animal_village" || sectionname === "owner_block" || sectionname === "owner_village") {
      setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e.target.value});
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "proposer_state" || sectionname === "proposer_city" || sectionname === "proposer_zipcode" || sectionname === "animal_state" || sectionname === "animal_city" || sectionname === "animal_zipcode" || sectionname === "owner_state" || sectionname === "owner_city" || sectionname === "owner_zipcode") {
      setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e});
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "investigator_state" || sectionname === "investigator_city" || sectionname === "investigator_zipcode") {
      setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e});
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "investigator_reg_ID" || sectionname === "investigator_name" || sectionname === "investigator_block" || sectionname === "investigator_village" || sectionname === "investigator_address" || sectionname === "investigator_location") {
      setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value});
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "doctor_state" || sectionname === "doctor_city" || sectionname === "doctor_zipcode") {
      setDoctorDetails({ ...doctorDetails, [sectionname]: e});
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "doctor_reg_ID" || sectionname === "doctor_designation" || sectionname === "doctor_name" || sectionname === "doctor_block" || sectionname === "doctor_village" || sectionname === "doctor_address" || sectionname === "doctor_location") {
      setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value});
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "please_specify") {
      if (e.target.value !== "" && !fname_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only alphabets are allowed!" });
      } else {
        setAddCattleOwnerDetails({ ...addCattleOwnerDetails, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }
  };

  const uploadSingleFile = (e, field) => {
    validtion && setFormErrors(validate(addCattleOwnerDetails));
 if (checkImgValidation(e?.target?.files?.[0]) === false) {
      return;
    }
    if (field === "Account") {
      setAccountFile([...accountFile, URL.createObjectURL(e.target.files[0])]);
    }
    if (field === "Aadhaar") {
      if (e.target.files.length) {
        if (!aadharFile.length) {
          setAadhaarLoad(true);
          const formData = new FormData();
          formData.append("aadhar_number", e.target.files[0]);

          updateAadharCopy(formData)
            .then((res) => {
              if (res.status === 0) {
                setErrors({ ...errors, photoURL: res?.message });
                setAadhaarLoad(false);
              } else {
                setAadhaarLoad(false);
                setAaadharFile([...aadharFile, res.photoURL]);
                setErrors({ ...errors, photoURL: "" });
              }
            })
            .catch((error) => {
              setAadhaarLoad(false);
              setErrors({ ...errors, photoURL: error.message });
            });
        }
      }
    }
    if (field === "AadhaarOwner") {
      if (e.target.files.length) {
        setAadhaarOwnerLoad(true);
        const formData = new FormData();
        formData.append("aadhar_number", e.target.files[0]);

        updateAadharCopy(formData)
            .then((res) => {
              if (res.status === 0) {
                setErrors({ ...errors, photoURL: res?.message });
                setAadhaarOwnerLoad(false);
              } else {
                setAadhaarOwnerLoad(false);
                setAaadharFileOwner([...aadharFileOwner, res.photoURL]);
                setErrors({ ...errors, photoURL: "" });
              }
            })
            .catch((error) => {
              setAadhaarOwnerLoad(false);
              setErrors({ ...errors, photoURL: error.message });
            });
      }
    }
    if (field === "Account") {
      setAccountFile([...accountFile, URL.createObjectURL(e.target.files[0])]);
    }
    if (field === "Photo") {
      setphotoLoad(true);
      const formData = new FormData();
      formData.append("photo", e.target.files[0]);
      uploadProfile(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, photo: res?.message });
            setphotoLoad(false);
          } else {
            setphotoLoad(false);
            setPhotoFile([...photoFile, res.photoUrl]);
          }
        })
        .catch((error) => {
          alertShow("err", error.message);
          setphotoLoad(false);
          setErrors({ ...errors, photo: error.message });
        });
    }
    if (field === "Offers") {
      seteduload(true);
      const formData = new FormData();
      formData.append("EduCert", e.target.files[0]);
      updateEducationCert(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, eduProofImag: res?.message });
            seteduload(false);
          } else {
            seteduload(false);
            setOffersFile([...offersFile, res.eduProofImageUrl1]);
          }
        })
        .catch((error) => {
          seteduload(false);
          setErrors({ ...errors, eduProofImag: error.message });
        });
    }
    if (field === "CNIC") {
      setpanLoad(true);
      const formData = new FormData();
      formData.append("pancard", e.target.files[0]);
      updatePan(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, cnic: res?.message });
            setpanLoad(false);
          } else {
            setpanLoad(false);
            setCnicFile([...cnicFile, res.pancardImageUrl]);
          }
        })
        .catch((error) => {
          setpanLoad(false);
          setErrors({ ...errors, cnic: error.message });
        });
    }
    if (field === "Cheque") {
      setbankLoad(true);
      const formData = new FormData();
      formData.append("bankCopy", e.target.files[0]);
      updateBankCopy(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, updateBank: res?.message });
            setbankLoad(false);
          } else {
            setbankLoad(false);
            setChequeFile([...chequeFile, res.bankCopyImageUrl]);
          }
        })
        .catch((error) => {
          setbankLoad(false);
          setErrors({ ...errors, updateBank: error.message });
        });
    }
    if (field.split("-")[0] === "animal_photo") {
    
      setAnimalPhotoLoad(true);
      const formData = new FormData();
      formData.append("animal_photo", e.target.files[0]);
      updatePhotoCopy(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, photoURL: res?.message });
            setAnimalPhotoLoad(false);
          } else {
            const temp = [...animalPhotoFile];
            setAnimalPhotoLoad(false);
           temp[Number(field.split("-")[1])] = res.photoURL
            setAnimalPhotoFile(temp)
          }
        })
        .catch((error) => {
          setbankLoad(false);
          setErrors({ ...errors, photoURL: error.message });
        });
    }
      if (field.split("-")[0] === "animal_health_certificate") {
      setAnimalGoodHealthLoad(true);
      const formData = new FormData();
      formData.append("animal_health_photo", e.target.files[0]);
      updateGoodHealthCopy(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, photoURL: res?.message });
            setAnimalGoodHealthLoad(false);
          } else {
            const temp = [...animalGoodHealthFile];
            setAnimalGoodHealthLoad(false);
            temp[Number(field.split("-")[1])] = res.photoURL

            setGoodHealthFile(temp);
          }
        })
        .catch((error) => {
          setbankLoad(false);
          setErrors({ ...errors, photoURL: error.message });
        });
    }
    if (field.split("-")[0] === "others") {
      const arr = [...loaderCount];
      const index = field.split("-")[1];
      arr[index]=true
     setLoaderCount(arr)
      const formData = new FormData();
      formData.append("other", e.target.files[0]);
      updateOtherCopy(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, photoURL: res?.message });
            arr[index]=false
            setLoaderCount(arr)
            // setOtherLoader(false);
          } else {
            arr[index]=false
            setLoaderCount(arr)
            const temp = [...otherPhotoUpload];
            // setOtherLoader(false);
            temp[Number(field.split("-")[1])] = res.photoURL
            setOtherPhotoUpload(temp);
          }
        })
        .catch((error) => {
          setbankLoad(false);
          setErrors({ ...errors, photoURL: error.message });
        });
    }
    
      if (field === "bankImage") {
      setBankLoader(true);
      const formData = new FormData();
      formData.append("bank_details", e.target.files[0]);
      updateBankImage(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, photoURL: res?.message });
            setBankLoader(false);
          } else {
            setBankLoader(false);
            setBankPhotoUpload([...bankPhotoUpload, res.photoURL]);
          }
        })
        .catch((error) => {
          setbankLoad(false);
          setErrors({ ...errors, photoURL: error.message });
        });
    }
       
      if (field === "app_form") {
      setAppFormLoad(true);
      const formData = new FormData();
      formData.append("application_form", e.target.files[0]);
      updateAppFormImage(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, photoURL: res?.message });
            setAppFormLoad(false);
          } else {
            setAppFormLoad(false);
            setAppFormImg([...appFormImg, res.photoURL]);
          }
        })
        .catch((error) => {
          setbankLoad(false);
          setErrors({ ...errors, photoURL: error.message });
        });
    }
        
      if (field === "doctor_Certificate") {
      setCertLoad(true);
      const formData = new FormData();
        formData.append("doctor_certificate", e.target.files[0]);
      updateDoctorCertificate(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, photoURL: res?.message });
            setCertLoad(false);
          } else {
            setCertLoad(false);
            setDoctorCertImg([...doctorCertImg, res.photoURL]);
          }
        })
        .catch((error) => {
          setbankLoad(false);
          setErrors({ ...errors, photoURL: error.message });
        });
    }
       if (field === "payment_details_photo") {
      setPaymentLoad(true);
      const formData = new FormData();
      formData.append("payment_details", e.target.files[0]);
      updatePaymentImage(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, photoURL: res?.message });
            setPaymentLoad(false);
          } else {
            setPaymentLoad(false);
            setPaymentImg([...paymentImg, res.photoURL]);
          }
        })
        .catch((error) => {
          setbankLoad(false);
          setErrors({ ...errors, photoURL: error.message });
        });
    }
    if (field === "investigatorDocs") {
      if (e.target.files.length) {
        setInvestigatorFileLoad(true);
        const formData = new FormData();
        formData.append("investigator_upload", e.target.files[0]);

        updateInvestigatorCertificate(formData)
            .then((res) => {
              if (res.status === 0) {
                setErrors({ ...errors, photoURL: res?.message });
                setInvestigatorFileLoad(false);
              } else {
                setInvestigatorFileLoad(false);
                setInvestigatorFile([...investigatorFile, res.photoURL]);
                setErrors({ ...errors, photoURL: "" });
              }
            })
            .catch((error) => {
              setInvestigatorFileLoad(false);
              setErrors({ ...errors, photoURL: error.message });
            });
      }
    }
    if (field === "doctorDocs") {
      if (e.target.files.length) {
        setDoctorFileLoad(true);
        const formData = new FormData();
        formData.append("doctor_upload", e.target.files[0]);

        updatePhotoDoctorUpload(formData)
            .then((res) => {
              if (res.status === 0) {
                setErrors({ ...errors, photoURL: res?.message });
                setDoctorFileLoad(false);
              } else {
                setDoctorFileLoad(false);
                setDoctorFile([...doctorFile, res.photoURL]);
                setErrors({ ...errors, photoURL: "" });
              }
            })
            .catch((error) => {
              setDoctorFileLoad(false);
              setErrors({ ...errors, photoURL: error.message });
            });
      }
    }
  };

  const deleteFile = (e, field) => {
    if (field === "Account") {
      const s = accountFile.filter((item, index) => index !== e);
      setAccountFile(s);
    }
    if (field === "Aadhaar") {
      const s = aadharFile.filter((item, index) => index !== e);
      setAaadharFile(s);
    }
    if (field === "AadhaarOwner") {
      const s = aadharFileOwner.filter((item, index) => index !== e);
      setAaadharFileOwner(s);
    }
    if (field === "Photo") {
      const s = photoFile.filter((item, index) => index !== e);
      setPhotoFile(s);
    }
    if (field === "Offers") {
      const s = offersFile.filter((item, index) => index !== e);
      setOffersFile(s);
    }
    if (field === "CNIC") {
      const s = cnicFile.filter((item, index) => index !== e);
      setCnicFile(s);
    }
    if (field === "Cheque") {
      const s = chequeFile.filter((item, index) => index !== e);
      setChequeFile(s);
    }
    if (field.split("-")[0] === "animal_photo") {
     const temp = [...animalPhotoFile]
      delete temp[e]
      setAnimalPhotoFile(temp);
    }
      if (field.split("-")[0] === "animal_health_certificate") {
     const temp = [...animalGoodHealthFile]
      delete temp[e]
      setGoodHealthFile(temp);
    }
    if (field.split("-")[0] === "others") {
      const temp = [...otherPhotoUpload]
      delete temp[e]
      setOtherPhotoUpload(temp);
    }
    if (field === "bankImage") {
      const s = bankPhotoUpload.filter((item, index) => index !== e);
      setBankPhotoUpload(s);
    }
    if (field === "app_form") {
      const s = appFormImg.filter((item, index) => index !== e);
      setAppFormImg(s);
    }
    if (field === "doctor_Certificate") {
      const s = doctorCertImg.filter((item, index) => index !== e);
      setDoctorCertImg(s);
    }
    if (field === "payment_details_photo") {
      const s = paymentImg.filter((item, index) => index !== e);
      setPaymentImg(s);
    }
    if (field === "doctorDocs") {
      const s = doctorFile.filter((item, index) => index !== e);
      setDoctorFile(s);
    }
    if (field === "investigatorDocs") {
      const s = investigatorFile.filter((item, index) => index !== e);
      setInvestigatorFile(s);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, [])

  return (
    <div className="pos cattle-insurance">
      <Header title="Cattle Insurance" />
      {show ? <div className="p-4 bg-white shadow rounded-3">
        {/* <h4 className="mb-3 title">Please Complete Your Details</h4> */}
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Proposer Details</Accordion.Header>
            <Accordion.Body>
              <ProposerDetails
                personalchk={personalchk}
                personalDetail={addCattleOwnerDetails}
                selectState={selectState}
                setSelectState={setSelectState}
                selectCity={selectCity}
                setSelectCity={setSelectCity}
                selectPinCode={selectPinCode}
                setSelectPinCode={setSelectPinCode}
                selectProposerState={selectProposerState}
                setSelectProposerState={setSelectProposerState}
                selectProposerCity={selectProposerCity}
                setSelectProposerCity={setSelectProposerCity}
                selectProposerPinCode={selectProposerPinCode}
                setSelectProposerPinCode={setSelectProposerPinCode}
                AadhaarLoad={AadhaarLoad}
                aadharFile={aadharFile}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                formErrors={formErrors}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Owner Details</Accordion.Header>
            <Accordion.Body>
              <OwnerDetails
                personalchk={personalchk}
                personalDetail={addCattleOwnerDetails}
                selectOwnerState={selectOwnerState}
                setSelectOwnerState={setSelectOwnerState}
                selectOwnerCity={selectOwnerCity}
                setSelectOwnerCity={setSelectOwnerCity}
                selectOwnerPinCode={selectOwnerPinCode}
                setSelectOwnerPinCode={setSelectOwnerPinCode}
                AadhaarOwnerLoad={AadhaarOwnerLoad}
                aadharFileOwner={aadharFileOwner}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                formErrors={formErrors}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Previous Cattle Insurance Policy and Claim Experience (Last 3 Years)</Accordion.Header>
            <Accordion.Body>
              <PreviousInsurancePolicyAndClaim
                personalchk={personalchk}
                addPrevClaims={addPrevClaims}
                formErrors={formErrors}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item>
           <Accordion.Item eventKey="3">
            <Accordion.Header>Sources of Funds</Accordion.Header>
            <Accordion.Body>
              <SourceOfFunds
                personalchk={personalchk}
                personalDetail={addCattleOwnerDetails}
                formErrors={formErrors}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Past Insurer Details</Accordion.Header>
            <Accordion.Body>
              <PastInsurerDetails
                personalchk={personalchk}
                personalDetail={addCattleOwnerDetails}
                formErrors={formErrors}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item>
           <Accordion.Item eventKey="5">
            <Accordion.Header>Animal Details</Accordion.Header>
            <Accordion.Body>
              <AnimalDetailsFrom
                updateValue={personalchk}
                setAnimalDetailsCount={setAnimalDetailsCount}
                animalDetailsCount={animalDetailsCount}
                personalDetail={personalDetail}
                formErrors={formErrors}
                errors={errors}
                eduload={eduload}
                offersFile={offersFile}
                animalPhotoLoad={animalPhotoLoad}
                animalPhotoFile={animalPhotoFile}
                animalGoodHealthLoad={animalGoodHealthLoad}
                animalGoodHealthFile={animalGoodHealthFile}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
              />
            </Accordion.Body>
          </Accordion.Item>
           <Accordion.Item eventKey="6">
            <Accordion.Header>Animal Lost Details (If Any)</Accordion.Header>
            <Accordion.Body>
              <AnimalLostDetailsFrom
                updateValue={personalchk}
                personalDetail={personalDetail}
                formErrors={formErrors}
                errors={errors}
                eduload={eduload}
                offersFile={offersFile}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
              />
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="7">
            <Accordion.Header>Cattle Uses and Category Details</Accordion.Header>
            <Accordion.Body>
              <CattleUsesDetailsFrom
                updateValue={personalchk}
                personalDetail={personalDetail}
                formErrors={formErrors}
                errors={errors}
                eduload={eduload}
                offersFile={offersFile}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
              />
            </Accordion.Body>
          </Accordion.Item> */}
           <Accordion.Item eventKey="8">
            <Accordion.Header>Payment Details</Accordion.Header>
            <Accordion.Body>
              <PaymentDetailsCatttleForm
                updateValue={personalchk}
                personalDetail={personalDetail}
                bankLoad={bankLoad}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                chequeFile={chequeFile}
                formErrors={formErrors}
                paymentLoad={paymentLoad}
                paymentImg={paymentImg}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item> 
           <Accordion.Item eventKey="9">
            <Accordion.Header>Bank Details</Accordion.Header>
            <Accordion.Body>
              <BankDetailsCattleForm
                updateValue={personalchk}
                personalDetail={personalDetail}
                bankLoad={bankLoad}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                chequeFile={chequeFile}
                formErrors={formErrors}
                bankLoader={bankLoader}
                bankPhotoUpload={bankPhotoUpload}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item> 
           <Accordion.Item eventKey="10">
            <Accordion.Header>Attachments</Accordion.Header>
            <Accordion.Body>
              <Attachments
                personalchk={personalchk}
                personalDetail={personalDetail}
                setDocumentCount={setDocumentCount}
                documentCount={documentCount}
                bankLoad={bankLoad}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                chequeFile={chequeFile}
                formErrors={formErrors}
                appFormLoad={appFormLoad}
                appFormImg={appFormImg}
                certLoad={certLoad}
                doctorCertImg={doctorCertImg}
                errors={errors}
                otherPhotoUpload={otherPhotoUpload}
                loaderCount={loaderCount}
              />
            </Accordion.Body>
          </Accordion.Item>
           <Accordion.Item eventKey="11">
            <Accordion.Header>Policy Issuance Status</Accordion.Header>
            <Accordion.Body>
              <PolicyIssuance
                updateValue={personalchk}
                personalDetail={personalDetail}
                formErrors={formErrors}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>Investigator Details</Accordion.Header>
            <Accordion.Body>
              <InvestigatorDetails
                personalchk={personalchk}
                personalDetail={investigatorDetails}
                searchInvestigatorNumber={addCattleOwnerDetails}
                selectInvestigatorState={selectInvestigatorState}
                setSelectInvestigatorState={setSelectInvestigatorState}
                selectInvestigatorCity={selectInvestigatorCity}
                setSelectInvestigatorCity={setSelectInvestigatorCity}
                selectInvestigatorPinCode={selectInvestigatorPinCode}
                setSelectInvestigatorPinCode={setSelectInvestigatorPinCode}
                investigatorFileLoad={investigatorFileLoad}
                investigatorFile={investigatorFile}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                formErrors={formErrors}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>Doctor Details</Accordion.Header>
            <Accordion.Body>
              <DoctorDetails
                personalchk={personalchk}
                personalDetail={doctorDetails}
                searchDoctorNumber={addCattleOwnerDetails}
                selectDoctorState={selectDoctorState}
                setSelectDoctorState={setSelectDoctorState}
                selectDoctorCity={selectDoctorCity}
                setSelectDoctorCity={setSelectDoctorCity}
                selectDoctorPinCode={selectDoctorPinCode}
                setSelectDoctorPinCode={setSelectDoctorPinCode}
                doctorFileLoad={doctorFileLoad}
                doctorFile={doctorFile}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                formErrors={formErrors}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <BranchForm
          personalchk={personalchk}
          personalDetail={personalDetail}
          formErrors={formErrors}
          errors={errors}
        /> */}
        {/* <div className="row row-cols-1 row-cols-lg-1 ">
          <div className="col d-flex align-items-center">
            <input
              className="me-2"
              type="checkbox"
              onChange={() => {
                setChecked(!checked);
                setPersonalDetail({
                  ...personalDetail,
                  isChecked: !personalDetail.isChecked,
                });
              }}
            />
            <span style={{ fontSize: "0.9rem" }}>
              I Hereby declare that above submitted details are valid and
              submitted by myself only.
            </span>
          </div>
          {!checked && <p className="p-error">{formErrors.isChecked}</p>}
        </div> */}
        <div className="row row-cols-1 row-cols-lg-1 mt-3 form d-flex justify-content-center align-items-center w-100">
           {/* <button className="btn btn-primary m-2" >
            Save Draft
          </button> */}
          <button className="btn btn-primary m-2" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div> : <Loading />}
    </div>
  );
};

export default AddCattleInfo;
