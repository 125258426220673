import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import Logo from "../assets/img/logo.png";
import User from "../assets/img/user.png";
import { useNavigate } from "react-router-dom";

const TopBar = ({ toogleSidebar = void 0, toggle = false }) => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("superUser");
    navigate("/login");
  };

  return (
    <Navbar bg="light" variant="light" className="p-2 shadow fixed-top nav">
      <Container fluid>
        <div className="d-flex align-items-center">
          <i
            className={`bi ${
              toggle ? "bi-x-lg" : "bi-list"
            } handburger d-md-none`}
            onClick={() => toogleSidebar()}
          ></i>
          <Navbar.Brand>
            <img src={Logo} alt="logo" className="logo" />
          </Navbar.Brand>
        </div>
        <Nav />
        <Nav>
          <NavDropdown
             title={
              <div className="d-flex justify-content-end">
                {/* <div className="" style={{marginLeft:"-3rem"}}>
                 <img className="rounded " src={notifications} alt="notifications" />
                </div> */}
                <p style={{ marginBottom: "0", textTransform: "capitalize", marginRight:"15px" }}>{JSON?.parse?.(localStorage?.getItem("superUser"))?.role}</p>
              <div className="nav_img" >
                <img className="rounded " src={User} alt="user_pic" />
                </div>
                </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item onClick={() => logout()} className="pull-right">
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default TopBar;
