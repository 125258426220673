import { Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import AddPOS from "../pages/AddPOS";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import POSDetails from "../pages/POSDetails";
import ViewPOS from "../pages/ViewPOS";
import AddCattleInsurance from "../pages/AddCattleInfo";
import CattleInsuranceDetails from "../pages/CattleInsuranceDetails";
import ViewCattleDetails from "../pages/ViewCattleDetails";
import EditCattleDetails from "../pages/EditCattleDetails";
import ShareDetails from "../pages/ShareDetails";
import EndorsementDetails from "../pages/EndorsementDetails";
import ClaimDetails from "../pages/ClaimDetails";
import AddIntimateClaim from "../pages/AddIntimateClaim";
import ViewClaimDetails from "../pages/ViewClaimDetails";
import EditClaimDetails from "../pages/EditClaimDetails";
import DoctorManagement from "../pages/DoctorManagement";
import InvestigatorManagement from "../pages/InvestigatorManagement";
import RoleDetails from "../pages/RoleDetails";
import RolePermissionDetails from "../pages/RolePermissionDetails";
import AssignedCattleDoctor from "../pages/AssignedCattleDoctor";
import AssignedCattleInvestigator from "../pages/AssignedCattleInvestigator";
import DoctorRequestList from "../pages/DoctorRequestList";
import InvestigatorRequestList from "../pages/InvestigatorRequestList";
import SurveyorList from "../pages/SurveyorManagement";
import SurveyorRequestList from "../pages/SurveyorRequestList";
import AssignedCattleSurveyor from "../pages/AssignedCattleSurveyor";

const AppRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/pos/all" element={<POSDetails />} />
        <Route exact path="/pos/add" element={<AddPOS />} />
        <Route exact path="/pos/:id/view" element={<ViewPOS />} />
        <Route exact path="/cattle-insurance/all" element={<CattleInsuranceDetails />} />
        <Route exact path="/cattle-insurance/add" element={<AddCattleInsurance />} />
        <Route exact path="/cattle/:id/view" element={<ViewCattleDetails />} />
        <Route exact path="/cattle/:id/edit" element={<EditCattleDetails />} />
        <Route exact path="/cattle-insurance/insurer-details" element={<ShareDetails />} />
        <Route exact path="/cattle-insurance/insurer-details/:id" element={<ShareDetails />} />
        <Route exact path="/cattle-insurance/endorsement" element={<EndorsementDetails />} />
        <Route exact path="/cattle-insurance/claim" element={<ClaimDetails />} />
        <Route exact path="/cattle-insurance/intimate-claim" element={<AddIntimateClaim />} />
        <Route exact path="/claim/:id/view" element={<ViewClaimDetails />} />
        <Route exact path="/claim/:id/edit" element={<EditClaimDetails />} />
        <Route exact path="/cattle-insurance/doctor" element={<DoctorManagement />} />
        <Route exact path="/cattle-insurance/investigator" element={<InvestigatorManagement />} />
        <Route exact path="/cattle-insurance/role" element={<RoleDetails />} />
        <Route exact path="/cattle-insurance/role-permission" element={<RolePermissionDetails />} />
        <Route exact path="/cattle-insurance/assigned-doctor" element={<AssignedCattleDoctor />} />
        <Route exact path="/cattle-insurance/assigned-investigator" element={<AssignedCattleInvestigator />} />
        <Route exact path="/cattle-insurance/assigned-surveyor" element={<AssignedCattleSurveyor />} />
        <Route exact path="/cattle-insurance/doctor-dashboard" element={<DoctorRequestList />} />
        <Route exact path="/cattle-insurance/investigator-dashboard" element={<InvestigatorRequestList />} />
        <Route exact path="/cattle-insurance/surveyor-dashboard" element={<SurveyorRequestList />} />
        <Route exact path="/cattle-insurance/surveyor" element={<SurveyorList />} />

      </Routes>
    </Layout>
  );
};

export default AppRoutes;
