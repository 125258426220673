import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateInvestigatorCertificate } from "../store/actions/Image";
import { checkImgValidation } from "../utils/methods";
import AddInvestigator from "../components/AddInvestigator";
import { addInvestigator } from "../store/actions/personalDetails";
import { toast } from "react-toastify";

const AddInvestigatorManagement = (props) => {
    const {setIsAdd} = props;
    const fname_regex = /^[a-zA-Z ]+$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const mobile_regex = /^[0-9\b]+$/;

    const [errors, setErrors] = useState({});
    const [investigatorDetails, setInvestigatorDetails] = useState({
        investigator_reg_ID: "",
        investigator_email: "",
        investigator_phone: null,
        investigator_name: "",
        investigator_state: "",
        investigator_city: "",
        investigator_zipcode: null,
        investigator_block: "",
        investigator_village: "",
        investigator_address: "",
        investigator_location: "",
        investigator_upload: ""
    })
    const [selectInvestigatorState, setSelectInvestigatorState] = useState("");
    const [selectInvestigatorCity, setSelectInvestigatorCity] = useState("");
    const [selectInvestigatorPinCode, setSelectInvestigatorPinCode] = useState("");
    
    const [investigatorPhotoUpload, setInvestigatotPhotoUpload] = useState([]);
    const [investigatorDocumentCount, setInvestigatorDocumentCount] = useState(1);
    const [investigatorLoaderCount, setInvestigatorLoaderCount] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const investigator = {
            investigator_reg_ID: investigatorDetails.investigator_reg_ID,
            investigator_email: investigatorDetails.investigator_email,
            investigator_phone: investigatorDetails.investigator_phone,
            investigator_name: investigatorDetails.investigator_name,
            investigator_state: selectInvestigatorState,
            investigator_city: selectInvestigatorCity,
            investigator_zipcode: selectInvestigatorPinCode,
            investigator_block: investigatorDetails.investigator_block,
            investigator_village: investigatorDetails.investigator_village,
            investigator_address: investigatorDetails.investigator_address,
            investigator_location: investigatorDetails.investigator_location,
            investigator_upload: investigatorPhotoUpload[0] || "", 
        }
        addInvestigator(investigator)
        .then((res) => {
          res?.status === 0 && (toast.error("something went wrong") );
          if (res?.status === 1) {
            toast.success("Investigator added successfully.");
            setIsAdd(false)
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }

    const personalchk = (sectionname, e) => {
        if (sectionname === "investigator_phone") {
            if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
              setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
            } else {
              e.target.value !== "e" &&
                e.target.value.length <= 10 &&
                setInvestigatorDetails({
                  ...investigatorDetails,
                  [sectionname]: parseInt(e.target.value),
                });
            //   setErrors({ ...errors, [sectionname]: "" });
            }
        }
        if (sectionname === "investigator_email") {
            if (e.target.value !== "" && !email_regex.test(e.target.value)) {
              setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
              setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value });
            } else {
              setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value });
            //   setErrors({ ...errors, [sectionname]: "" });
            }
        }
        if (sectionname === "investigator_state" || sectionname === "investigator_city" || sectionname === "investigator_zipcode") {
            setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e});
            // setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "investigator_reg_ID" || sectionname === "investigator_name" || sectionname === "investigator_block" || sectionname === "investigator_village" || sectionname === "investigator_address" || sectionname === "investigator_location") {
            setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value});
            // setErrors({ ...errors, [sectionname]: "" });
        }
    };

    const uploadSingleFile = (e, field) => {
        // validtion && setFormErrors(validate(addCattleOwnerDetails));
        if (checkImgValidation(e?.target?.files?.[0]) === false) {
            return;
        }
        if (field === "investigator_upload") {
            setInvestigatorLoaderCount(true);
            const formData = new FormData();
            formData.append("investigator_upload", e.target.files[0]);
            updateInvestigatorCertificate(formData)
            .then((res) => {
                if (res.status === 0) {
                  setErrors({ ...errors, photoURL: res?.message });
                  setInvestigatorLoaderCount(false);
                } else {
                  setInvestigatorLoaderCount(false);
                  setInvestigatotPhotoUpload([...investigatorPhotoUpload, res.photoURL]);
                }
            })
            .catch((error) => {
                setErrors({ ...errors, photoURL: error.message });
            });
        }
    };
    
    const deleteFile = (e, field) => {
        if (field === "investigator_upload") {
            const s = investigatorPhotoUpload.filter((item, index) => index !== e);
            setInvestigatotPhotoUpload(s);
            setInvestigatorDetails({ ...investigatorDetails, ["investigator_upload"]: null });
        }
    };

  return (
    <div className="pos cattle-insurance">
            <AddInvestigator
                personalchk={personalchk}
                personalDetail={investigatorDetails}
                selectInvestigatorState={selectInvestigatorState}
                setSelectInvestigatorState={setSelectInvestigatorState}
                selectInvestigatorCity={selectInvestigatorCity}
                setSelectInvestigatorCity={setSelectInvestigatorCity}
                selectInvestigatorPinCode={selectInvestigatorPinCode}
                setSelectInvestigatorPinCode={setSelectInvestigatorPinCode}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                setInvestigatorDocumentCount={setInvestigatorDocumentCount}
                investigatorDocumentCount={investigatorDocumentCount}
                investigatorPhotoUpload={investigatorPhotoUpload}
                investigatorLoaderCount={investigatorLoaderCount}
                // formErrors={formErrors}
                errors={errors}
              />
            <div className="row row-cols-1 row-cols-lg-1 mt-3 form d-flex justify-content-center align-items-center w-100">
                <button className="btn btn-primary m-2 mb-4" onClick={handleSubmit}>
                    Submit
                </button>
            </div>
    </div>
  );
};

export default AddInvestigatorManagement;
