import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import IntimateClaim from "../components/IntimateClaim";
import ClaimDoctorDetails from "../components/ClaimDoctorDetails";
import ClaimInvestigatorDetails from "../components/ClaimInvestigatorDetails";
import ClaimSettlement from "../components/ClaimSettlement";
import BankDetailsCattleForm from "../components/BankDetailsCattleForm";
import { toast } from "react-toastify";
import { editClaim } from "../store/actions/personalDetails";
import { useNavigate , useParams} from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { updatePhotoDoctorUpload, updateInvestigatorCertificate, updateBankImage } from "../store/actions/Image";
import { getClaimInfo } from "../store/actions/claimInfo";
import { useDispatch, useSelector } from "react-redux";
import { checkImgValidation } from "../utils/methods";
import SurveyorDetails from "./ClaimSurveyor";

const EditIntimateClaim = () => {
    const fname_regex = /^[a-zA-Z ]+$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const mobile_regex = /^[0-9\b]+$/;

    const { id } = useParams();
    const dispatch = useDispatch();
    const result = useSelector((state) => state.claimInfo.data.data);
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [intimateDetails, setIntimateDetails] = useState({
        claim_intimation_date: "",
        claim_reason: "",
        date_of_happening: "",
        additional_remarks:"",
    })
    const [investigatorDetails, setInvestigatorDetails] = useState({
        investigator_reg_ID: "",
        investigator_email: "",
        investigator_phone: null,
        investigator_name: "",
        investigator_state: "",
        investigator_city: "",
        investigator_zipcode: null,
        investigator_block: "",
        investigator_village: "",
        investigator_address: "",
        investigator_location: "",
        investigator_upload: "",
    })
   const [surveyorDetails, setSurveyorDetails] = useState({
    surveyor_reg_ID: "",
    surveyor_designation: "",
    surveyor_email: "",
    surveyor_phone: null,
    surveyor_name: "",
    surveyor_state: "",
    surveyor_city: "",
    surveyor_zipcode: null,
    surveyor_block: "",
    surveyor_village: "",
    surveyor_address: "",
    surveyor_location: "",
    surveyor_upload: ""
  })
    const [doctorDetails, setDoctorDetails] = useState({
        doctor_reg_ID: "",
        doctor_designation: "",
        doctor_email: "",
        doctor_phone: null,
        doctor_name: "",
        doctor_state: "",
        doctor_city: "",
        doctor_zipcode: null,
        doctor_block: "",
        doctor_village: "",
        doctor_address: "",
        doctor_location: "",
        doctor_upload: "",
    })
    const [claimSettlementDetails, setClaimSettlementDetails] = useState({
        claim_status: "",
        claim_partition: "",
        claim_amount:"",
        additional_remarks:"",
    })
    const [bankDetails, setBankDetails] = useState({
        accountHolderName: "",
        bankName: "",
        branch:"",
        ifscCode:"",
        accountNumber: "",
        upload_bank_photo: ""
    })

    const [searchedTagData, setSearchedTagData] = useState();
    const [selectInvestigatorState, setSelectInvestigatorState] = useState("");
    const [selectInvestigatorCity, setSelectInvestigatorCity] = useState("");
    const [selectInvestigatorPinCode, setSelectInvestigatorPinCode] = useState("");
    const [selectDoctorState, setSelectDoctorState] = useState("");
    const [selectDoctorCity, setSelectDoctorCity] = useState("");
    const [selectDoctorPinCode, setSelectDoctorPinCode] = useState("");
    
    const [doctorPhotoUpload, setDoctorPhotoUpload] = useState([]);
    const [doctorDocumentCount, setDoctorDocumentCount] = useState(1);
    const [loaderCount, setLoaderCount] = useState([]);
    const [investigatorPhotoUpload, setInvestigatotPhotoUpload] = useState([]);
    const [investigatorDocumentCount, setInvestigatorDocumentCount] = useState(1);
    const [investigatorLoaderCount, setInvestigatorLoaderCount] = useState([]);
     const [assignedSurveyor, setAssignedSurveyor] = useState();
       const [assignedDoctor, setAssignedDoctor] = useState();
    const [surveyorFile, setSurveyorFile] = useState([]);
  const [selectSurveyorState, setSelectSurveyorState] = useState("");
  const [selectSurveyorCity, setSelectSurveyorCity] = useState("");
  const [selectSurveyorPinCode, setSelectSurveyorPinCode] = useState("");
    const [surveyorFileLoad, setSurveyorFileLoad] = useState(false); 
    const [bankLoad, setbankLoad] = useState(false);
    const [bankLoader, setBankLoader] = useState(false);
    const [bankPhotoUpload, setBankPhotoUpload] = useState([]);
  console.log('assignedSurveyor', assignedSurveyor)
  
    useEffect(() => {
        if(result){
            const claimDetail = {
                claim_intimation_date: result?.viewClaimrResult?.claim_intimation_date,
                claim_reason: result?.viewClaimrResult?.claim_reason,
                date_of_happening: result?.viewClaimrResult?.date_of_happening,
                additional_remarks: result?.viewClaimrResult?.additional_remarks,
            }
            let convertArrFilesInvestigator = result?.investigatorDetails?.investigator_upload.split(",");
            setInvestigatotPhotoUpload(convertArrFilesInvestigator)
            const investigator = {
                investigator_reg_ID: result?.investigatorDetails?.investigator_reg_ID,
                investigator_email: result?.investigatorDetails?.investigator_email,
                investigator_phone: result?.investigatorDetails?.investigator_phone,
                investigator_name: result?.investigatorDetails?.investigator_name,
                investigator_state: result?.investigatorDetails?.investigator_state,
                investigator_city: result?.investigatorDetails?.investigator_city,
                investigator_zipcode: result?.investigatorDetails?.investigator_zipcode,
                investigator_block: result?.investigatorDetails?.investigator_block,
                investigator_village: result?.investigatorDetails?.investigator_village,
                investigator_address: result?.investigatorDetails?.investigator_address,
                investigator_location: result?.investigatorDetails?.investigator_location,
                investigator_upload: result?.investigatorDetails?.investigator_upload
            }
            let convertArrFilesDoctor = result?.doctor_details?.doctor_upload.split(",");
            setDoctorPhotoUpload(convertArrFilesDoctor || "")
            const doctor = {
                doctor_reg_ID: result?.doctor_details?.doctor_reg_ID,
                doctor_designation: result?.doctor_details?.doctor_designation,
                doctor_email: result?.doctor_details?.doctor_email,
                doctor_phone: result?.doctor_details?.doctor_phone,
                doctor_name: result?.doctor_details?.doctor_name,
                doctor_state: result?.doctor_details?.doctor_state,
                doctor_city: result?.doctor_details?.doctor_city,
                doctor_zipcode: result?.doctor_details?.doctor_zipcode,
                doctor_block: result?.doctor_details?.doctor_block,
                doctor_village: result?.doctor_details?.doctor_village,
                doctor_address: result?.doctor_details?.doctor_address,
                doctor_location: result?.doctor_details?.doctor_location,
                doctor_upload: result?.doctor_details?.doctor_upload

                
            }
            const claimSettlement = {
                claim_status: result?.viewClaimrResult?.claim_status,
                claim_partition: result?.viewClaimrResult?.claim_partition,
                claim_amount: result?.viewClaimrResult?.claim_amount,
                claim_additional_remarks: result?.viewClaimrResult?.claim_additional_remarks,
            }
            const bank = {
                accountHolderName: result?.viewBankDetails[0]?.account_holder_name,
                bankName: result?.viewBankDetails[0]?.bank_name,
                branch: result?.viewBankDetails[0]?.branch,
                ifscCode: result?.viewBankDetails[0]?.ifsc_code,
                accountNumber: result?.viewBankDetails[0]?.account_number,
                upload_bank_photo: result?.viewBankDetails[0]?.upload_bank_photo
          }
          const surveyor = {
        surveyor_reg_ID:  result?.surveyorDetails?.surveyor_reg_ID,
        surveyor_designation: result?.surveyorDetails?.surveyor_designation,
        surveyor_email: result?.surveyorDetails?.surveyor_email,
        surveyor_phone: String(result?.surveyorDetails?.surveyor_phone),
        surveyor_name: result?.surveyorDetails?.surveyor_name,
        surveyor_state: result?.selectSurveyorState,
        surveyor_city: result?.selectSurveyorCity,
        surveyor_zipcode: result?.selectSurveyorPinCode,
        surveyor_block: result?.surveyorDetails?.surveyor_block,
        surveyor_village: result?.surveyorDetails?.surveyor_village,
        surveyor_address: result?.surveyorDetails?.surveyor_address,
        surveyor_location: result?.surveyorDetails?.surveyor_location,
        surveyor_upload: result?.surveyorDetails?.surveyor_upload[0] || ""
      };
            setIntimateDetails(claimDetail)
            setDoctorDetails(doctor)
            // setInvestigatorDetails(investigator)
          setSurveyorDetails(surveyor)
            setBankDetails(bank)
            setClaimSettlementDetails(claimSettlement)
        }
        
      }, [result])
    
    //   console.log(result?.viewClaimrResult?.claim_number, 'intimateDetails.claim_reason.label')
    const handleSubmit = (e) => {
        e.preventDefault();
        const claimDetail = {
            id: result?.viewClaimrResult?.id,
            claim_intimation_date: intimateDetails.claim_intimation_date,
            claim_reason: intimateDetails.claim_reason.label || intimateDetails.claim_reason,
            date_of_happening: intimateDetails.date_of_happening,
           additional_remarks: intimateDetails.additional_remarks,
             created_by : JSON?.parse?.(localStorage?.getItem("superUser"))?.email
        }
        // const investigator = {
        //     investigator_reg_ID: investigatorDetails.investigator_reg_ID,
        //     investigator_email: investigatorDetails.investigator_email,
        //     investigator_phone: investigatorDetails.investigator_phone,
        //     investigator_name: investigatorDetails.investigator_name,
        //     investigator_state: selectInvestigatorState || investigatorDetails.investigator_state,
        //     investigator_city: selectInvestigatorCity || investigatorDetails.investigator_city,
        //     investigator_zipcode: selectInvestigatorPinCode || investigatorDetails.investigator_zipcode,
        //     investigator_block: investigatorDetails.investigator_block,
        //     investigator_village: investigatorDetails.investigator_village,
        //     investigator_address: investigatorDetails.investigator_address,
        //     investigator_location: investigatorDetails.investigator_location,
        //     investigator_upload: investigatorPhotoUpload?.toString() || investigatorDetails.investigator_upload || ""
        // }
       const surveyor = {
        surveyor_reg_ID: surveyorDetails?.surveyor_reg_ID || assignedSurveyor?.surveyor_reg_ID,
        surveyor_designation: surveyorDetails?.surveyor_designation || assignedSurveyor?.surveyor_designation,
        surveyor_email: surveyorDetails?.surveyor_email || assignedSurveyor?.surveyor_email,
        surveyor_phone: surveyorDetails?.surveyor_phone=="undefined" ? assignedSurveyor?.surveyor_phone : String(surveyorDetails?.surveyor_phone),
        surveyor_name: surveyorDetails?.surveyor_name || assignedSurveyor?.surveyor_name,
        surveyor_state: selectSurveyorState || assignedSurveyor?.surveyor_state,
        surveyor_city: selectSurveyorCity || assignedSurveyor?.surveyor_city,
        surveyor_zipcode: selectSurveyorPinCode || assignedSurveyor?.surveyor_zipcode,
        surveyor_block: surveyorDetails?.surveyor_block || assignedSurveyor?.surveyor_block,
        surveyor_village: surveyorDetails?.surveyor_village || assignedSurveyor?.surveyor_village,
        surveyor_address: surveyorDetails?.surveyor_address || assignedSurveyor?.surveyor_address,
        surveyor_location: surveyorDetails?.surveyor_location || assignedSurveyor?.surveyor_location,
        surveyor_upload: surveyorFile[0] || assignedSurveyor?.surveyor_upload || "",
       
      };
        const doctor = {
            doctor_reg_ID: doctorDetails.doctor_reg_ID,
            doctor_designation: doctorDetails.doctor_designation,
            doctor_email: doctorDetails.doctor_email,
            doctor_phone: String(doctorDetails.doctor_phone),
            doctor_name: doctorDetails.doctor_name,
            doctor_state: selectDoctorState || doctorDetails.doctor_state,
            doctor_city: selectDoctorCity || doctorDetails.doctor_city,
            doctor_zipcode: selectDoctorPinCode || doctorDetails.doctor_zipcode,
            doctor_block: doctorDetails.doctor_block,
            doctor_village: doctorDetails.doctor_village,
            doctor_address: doctorDetails.doctor_address,
            doctor_location: doctorDetails.doctor_location,
            doctor_upload: doctorPhotoUpload?.toString() || doctorDetails.doctor_upload || ""
        }
        const bank = {
            account_holder_name: bankDetails?.accountHolderName,
            bank_name: bankDetails?.bankName,
            branch: bankDetails?.branch,
            ifsc_code: bankDetails?.ifscCode,
            account_number: bankDetails?.accountNumber,
            upload_bank_photo: bankDetails?.upload_bank_photo || bankPhotoUpload[0] || ""
        }
        const claimSettle = {
            claim_status: claimSettlementDetails.claim_status.label || claimSettlementDetails.claim_status,
            claim_partition: Number(claimSettlementDetails.claim_partition),
            claim_amount: Number(claimSettlementDetails.claim_amount),
            claim_additional_remarks: claimSettlementDetails.claim_additional_remarks,
            claim_number: result?.viewClaimrResult?.claim_number
        }
        editClaim({claimDetails: claimDetail, surveyorDetails: surveyor, docterDetails: doctor, claimSettlement: claimSettle, bankDetails: bank})
        .then((res) => {
          res?.status === 0 && (toast.error(res?.message || "something went wrong"));
          if (res?.status === 1) {
            toast.success("Claim Intimate Updated successfully.");
            navigate("/cattle-insurance/claim");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }

    const personalchk = (sectionname, e) => {
        const bankdetailstatusArr = ["accountHolderName", "bankName", "branch", "ifscCode", "upload_bank_photo", "accountNumber"];
        const arrMatchName = sectionname.split("-")[0]
        if (bankdetailstatusArr.includes(arrMatchName)) {
            setBankDetails({ ...bankDetails, [sectionname]: e.target.value });
            setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "claim_reason" || sectionname === "claim_intimation_date" || sectionname === "date_of_happening") {
            setIntimateDetails({ ...intimateDetails, [sectionname]: e});
        }
        if (sectionname === "additional_remarks") {
          setIntimateDetails({ ...intimateDetails, [sectionname]: e.target.value});
        }
        if (sectionname === "doctor_state" || sectionname === "doctor_city" || sectionname === "doctor_zipcode") {
            setDoctorDetails({ ...doctorDetails, [sectionname]: e});
            setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "doctor_reg_ID" || sectionname === "doctor_designation" || sectionname === "doctor_name" || sectionname === "doctor_block" || sectionname === "doctor_village" || sectionname === "doctor_address" || sectionname === "doctor_location") {
            setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value});
            setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "doctor_phone") {
            if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
              setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
            } else {
              e.target.value !== "e" &&
                e.target.value.length <= 10 &&
                setDoctorDetails({
                  ...doctorDetails,
                  [sectionname]: parseInt(e.target.value),
                });
              setErrors({ ...errors, [sectionname]: "" });
            }
        }
        if (sectionname === "investigator_email") {
            if (e.target.value !== "" && !email_regex.test(e.target.value)) {
              setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
              setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value });
            } else {
              setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value });
              setErrors({ ...errors, [sectionname]: "" });
            }
        }
        if (sectionname === "doctor_email") {
            if (e.target.value !== "" && !email_regex.test(e.target.value)) {
                setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
                setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value });
            } else {
                setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value });
                setErrors({ ...errors, [sectionname]: "" });
            }
      }
      if (sectionname === "surveyor_email") {
      if (e.target.value !== "" && !email_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
        setSurveyorDetails({ ...surveyorDetails, [sectionname]: e.target.value });
      } else {
        setSurveyorDetails({ ...surveyorDetails, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
      }
        if (sectionname === "surveyor_phone") {
      if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
      } else {
        e.target.value !== "e" &&
          e.target.value.length <= 10 &&
          setSurveyorDetails({
            ...surveyorDetails,
            [sectionname]: parseInt(e.target.value),
          });
        setErrors({ ...errors, [sectionname]: "" });
      }
      }
       if (sectionname === "surveyor_state" || sectionname === "surveyor_city" || sectionname === "surveyor_zipcode") {
      setSurveyorDetails({ ...surveyorDetails, [sectionname]: e});
      setErrors({ ...errors, [sectionname]: "" });
      }
       if (sectionname === "surveyor_reg_ID" || sectionname === "surveyor_designation" || sectionname === "surveyor_name" || sectionname === "surveyor_block" || sectionname === "surveyor_village" || sectionname === "surveyor_address" || sectionname === "surveyor_location") {
      setSurveyorDetails({ ...surveyorDetails, [sectionname]: e.target.value});
      setErrors({ ...errors, [sectionname]: "" });
    }
        if (sectionname === "investigator_state" || sectionname === "investigator_city" || sectionname === "investigator_zipcode") {
            setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e});
            setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "investigator_reg_ID" || sectionname === "investigator_name" || sectionname === "investigator_block" || sectionname === "investigator_village" || sectionname === "investigator_address" || sectionname === "investigator_location") {
            setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value});
            setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "claim_status") {
            setClaimSettlementDetails({ ...claimSettlementDetails, [sectionname]: e});
        }
        if (sectionname === "claim_partition" || sectionname === "claim_amount" || sectionname === "claim_additional_remarks") {
            setClaimSettlementDetails({ ...claimSettlementDetails, [sectionname]: e.target.value});
        }
    };

    const uploadSingleFile = (e, field) => {
        // validtion && setFormErrors(validate(addCattleOwnerDetails));
   if (checkImgValidation(e?.target?.files?.[0]) === false) {
      return;
    }
        if (field.split("-")[0] === "doctor_upload") {
          const arr = [...loaderCount];
          const index = field.split("-")[1];
          arr[index]=true
         setLoaderCount(arr)
          const formData = new FormData();
          formData.append("doctor_upload", e.target.files[0]);
          updatePhotoDoctorUpload(formData)
            .then((res) => {
              if (res.status === 0) {
                setErrors({ ...errors, photoURL: res?.message });
                arr[index]=false
                setLoaderCount(arr)
                // setOtherLoader(false);
              } else {
                arr[index]=false
                setLoaderCount(arr)
                const temp = [...doctorPhotoUpload];
                // setOtherLoader(false);
                temp[Number(field.split("-")[1])] = res.photoURL
                setDoctorPhotoUpload(temp);
                setDoctorDetails({ ...doctorDetails, [`doctor_upload-${index}`]: res.photoURL });
              }
            })
            .catch((error) => {
              setErrors({ ...errors, photoURL: error.message });
            });
        }
        if (field.split("-")[0] === "investigator_upload") {
            const arr = [...investigatorLoaderCount];
            const index = field.split("-")[1];
            arr[index]=true
            setInvestigatorLoaderCount(arr)
            const formData = new FormData();
            formData.append("investigator_upload", e.target.files[0]);
            updateInvestigatorCertificate(formData)
              .then((res) => {
                if (res.status === 0) {
                  setErrors({ ...errors, photoURL: res?.message });
                  arr[index]=false
                  setInvestigatorLoaderCount(arr)
                  // setOtherLoader(false);
                } else {
                  arr[index]=false
                  setInvestigatorLoaderCount(arr)
                  const temp = [...investigatorPhotoUpload];
                  // setOtherLoader(false);
                  temp[Number(field.split("-")[1])] = res.photoURL
                  setInvestigatotPhotoUpload(temp);
                }
              })
              .catch((error) => {
                setErrors({ ...errors, photoURL: error.message });
              });
          }
          if (field === "bankImage") {
            setBankLoader(true);
            const formData = new FormData();
            formData.append("bank_details", e.target.files[0]);
            updateBankImage(formData)
              .then((res) => {
                if (res.status === 0) {
                  setErrors({ ...errors, photoURL: res?.message });
                  setBankLoader(false);
                } else {
                  setBankLoader(false);
                  setBankPhotoUpload([...bankPhotoUpload, res.photoURL]);
                }
              })
              .catch((error) => {
                setbankLoad(false);
                setErrors({ ...errors, photoURL: error.message });
              });
      }
      if (field === "surveyorDocs") {
      if (e.target.files.length) {
        setSurveyorFileLoad(true);
        const formData = new FormData();
        formData.append("doctor_upload", e.target.files[0]);

        updatePhotoDoctorUpload(formData)
            .then((res) => {
              if (res.status === 0) {
                setErrors({ ...errors, photoURL: res?.message });
                setSurveyorFileLoad(false);
              } else {
                setSurveyorFileLoad(false);
                setSurveyorFile([...surveyorFile, res.photoURL]);
                setErrors({ ...errors, photoURL: "" });
              }
            })
            .catch((error) => {
              setSurveyorFileLoad(false);
              setErrors({ ...errors, photoURL: error.message });
            });
      }
    }
      };
    
      const deleteFile = (e, field) => {
        if (field.split("-")[0] === "investigator_upload") {
            const temp = [...investigatorPhotoUpload]
            delete temp[e]
            setInvestigatotPhotoUpload(temp);
            setInvestigatorDetails({ ...investigatorDetails, [`investigator_upload-${e}`]: null });
        }
        if (field.split("-")[0] === "doctor_upload") {
            const temp = [...doctorPhotoUpload]
            delete temp[e]
            setDoctorPhotoUpload(temp);
            setDoctorDetails({ ...doctorDetails, [`doctor_upload-${e}`]: null });
        }
        if (field === "bankImage") {
            const s = bankPhotoUpload.filter((item, index) => index !== e);
            setBankPhotoUpload(s);
            setBankDetails({ ...bankDetails, ["upload_bank_photo"]: null });
        }
         if (field === "surveyorDocs") {
      const s = surveyorFile.filter((item, index) => index !== e);
      setSurveyorFile(s);
      setSurveyorDetails({ ...surveyorDetails, ["surveyor_upload"]: null });
    }
      };

    useEffect(() => {
        dispatch(getClaimInfo(id))
        setTimeout(() => {
        setShow(true);
        }, 1500);
    }, [])

  return (
    <div className="pos cattle-insurance">
      <Header title={"Edit Intimate Claim"} />
      {show ? 
      <>
      <div className="p-4 bg-white shadow rounded-3">
      <Accordion defaultActiveKey="0" flush> 
            <Accordion.Item eventKey="1">
            <Accordion.Header>Claim Form</Accordion.Header>
            <Accordion.Body>
                <IntimateClaim
                    isDisabled={false} 
                    updateData={personalchk}
                    intimateDetails={intimateDetails}
                    setSearchedTagData={setSearchedTagData}
                    data={result?.viewClaimrResult}
                    isEdit={true}
                />
            </Accordion.Body>
          </Accordion.Item> 
          {/* <Accordion.Item eventKey="2">
            <Accordion.Header>Assign Investigator</Accordion.Header>
            <Accordion.Body>
              <ClaimInvestigatorDetails
                isDisabled={false} 
                personalchk={personalchk}
                personalDetail={investigatorDetails}
                selectInvestigatorState={selectInvestigatorState}
                setSelectInvestigatorState={setSelectInvestigatorState}
                selectInvestigatorCity={selectInvestigatorCity}
                setSelectInvestigatorCity={setSelectInvestigatorCity}
                selectInvestigatorPinCode={selectInvestigatorPinCode}
                setSelectInvestigatorPinCode={setSelectInvestigatorPinCode}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                setInvestigatorDocumentCount={setInvestigatorDocumentCount}
                investigatorDocumentCount={investigatorDocumentCount}
                investigatorPhotoUpload={investigatorPhotoUpload}
                investigatorLoaderCount={investigatorLoaderCount}
                // formErrors={formErrors}
                errors={errors}
                data={result && result.investigatorDetails}
                isEdit={true}
                isShowView={0}
              />
            </Accordion.Body>
              </Accordion.Item> */}
                <Accordion.Item eventKey="2">
            <Accordion.Header>Assign Surveyor</Accordion.Header>
            <Accordion.Body>
              <SurveyorDetails
                personalchk={personalchk}
                personalDetail={surveyorDetails}
                // searchSurveyorNumber={addCattleOwnerDetails}
                selectSurveyorState={selectSurveyorState}
                setSelectSurveyorState={setSelectSurveyorState}
                selectSurveyorCity={selectSurveyorCity}
                setSelectSurveyorCity={setSelectSurveyorCity}
                selectSurveyorPinCode={selectSurveyorPinCode}
                setSelectSurveyorPinCode={setSelectSurveyorPinCode}
                surveyorFileLoad={surveyorFileLoad}
                surveyorFile={surveyorFile}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                // formErrors={formErrors}
                setAssignedSurveyor = {setAssignedSurveyor}
                errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Assign Doctor</Accordion.Header>
            <Accordion.Body>
              <ClaimDoctorDetails
                isDisabled={false} 
                personalchk={personalchk}
                personalDetail={doctorDetails}
                setDoctorDocumentCount={setDoctorDocumentCount}
                doctorDocumentCount={doctorDocumentCount}
                selectDoctorState={selectDoctorState}
                setSelectDoctorState={setSelectDoctorState}
                selectDoctorCity={selectDoctorCity}
                setSelectDoctorCity={setSelectDoctorCity}
                selectDoctorPinCode={selectDoctorPinCode}
                setSelectDoctorPinCode={setSelectDoctorPinCode}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                // formErrors={formErrors}
                    errors={errors}
                    setAssignedDoctor = {setAssignedDoctor}
                doctorPhotoUpload={doctorPhotoUpload}
                loaderCount={loaderCount}
                data={result && result.doctorDetails}
                isEdit={true}
                isShowView={0}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>Bank Details</Accordion.Header>
            <Accordion.Body>
              <BankDetailsCattleForm
                    isDisabled={false} 
                    data={[]}
                    updateValue={personalchk}
                    personalDetail={bankDetails}
                    bankLoad={bankLoad}
                    uploadSingleFile={uploadSingleFile}
                    deleteFile={deleteFile}
                    // formErrors={formErrors}
                    bankLoader={bankLoader}
                    bankPhotoUpload={bankPhotoUpload}
                    errors={errors}
              />
            </Accordion.Body>
          </Accordion.Item> 
          <Accordion.Item eventKey="5">
            <Accordion.Header>Claim Settlement</Accordion.Header>
            <Accordion.Body>
                <ClaimSettlement
                    isDisabled={false} 
                    updateData={personalchk}
                    claimSettlementDetails={claimSettlementDetails}
                    isEdit={true}
                />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
            <div className="row row-cols-1 row-cols-lg-1 mt-3 form d-flex justify-content-center align-items-center w-100">
                <button className="btn btn-primary m-2 mb-4" onClick={handleSubmit}>
                    Update
                </button>
            </div>
      </div> 
      </>
      :
      <Loading />}
    </div>
  );
};

export default EditIntimateClaim;
