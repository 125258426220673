import axios from "../../config/axios";
import {
  FETCH_ENDORSEMENT_MANAGEMENT_FAILURE,
  FETCH_ENDORSEMENT_MANAGEMENT_REQUEST,
  FETCH_ENDORSEMENT_MANAGEMENT_SUCCESS,
} from "./types";

export const fetchEndorsementManagementDetailSuccess = (model) => {
  return {
    type: FETCH_ENDORSEMENT_MANAGEMENT_SUCCESS,
    payload: model,
  };
};
export const fetchEndorsementManagementDetailFailure = (error) => {
  return {
    type: FETCH_ENDORSEMENT_MANAGEMENT_FAILURE,
    payload: error,
  };
};

export const getEndorsementManagementDetail = (currentPage, pagelimit) => async (dispatch) => {
  // dispatch(fetchCATTLEDetailRequest);
  await axios
    .post(`cattle/listEndorsement/?page=${currentPage}&limit=${pagelimit}`)
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(fetchEndorsementManagementDetailSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchEndorsementManagementDetailFailure(errorMsg));
    });
};