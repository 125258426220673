import axios from "../../config/axios";
import {
  FETCH_POSINFO_FAILURE,
  FETCH_POSINFO_REQUEST,
  FETCH_POSINFO_SUCCESS,
} from "./types";

export const fetchPosInfoRequest = () => {
  return {
    type: FETCH_POSINFO_REQUEST,
  };
};
export const fetchPosInfoSuccess = (model) => {
  return {
    type: FETCH_POSINFO_SUCCESS,
    payload: model,
  };
};
export const fetchPosInfoFailure = (error) => {
  return {
    type: FETCH_POSINFO_FAILURE,
    payload: error,
  };
};

export const getPosInfo = (Id) => async (dispatch) => {
  dispatch(fetchPosInfoRequest);
  await axios
    .get(`admin/getPosInfo/${Id}`)
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(fetchPosInfoSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchPosInfoFailure(errorMsg));
    });
};
