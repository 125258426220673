import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getBranchList } from "../store/actions/branchList";
import { cattleformat_date, editformat_date, stringFormat } from "../utils/methods";
import DatePicker from "react-datepicker";
import { useState } from "react";
const PolicyIssuance = ({
  personalDetail = [],
  updateValue = void 0,
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {

  const [IssureDate, setIssureDate] = useState("");
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [holdDate, setHoldDate] = useState()
  const [cancelDate, setCancelDate] = useState()
   const onIssureDate = (date) => {
        setIssureDate(date)
        updateValue("policy_issuance_date", cattleformat_date(date))
  }
  const onHoldDate = (date) => {
        setHoldDate(date)
        updateValue("policy_hold_date", cattleformat_date(date))
  }
   const onCancelDate = (date) => {
        setCancelDate(date)
        updateValue("policy_cancel_date", cattleformat_date(date))
  }
   const onStartDate = (date) => {
        setStartDate(date)
        updateValue("policy_start_date", cattleformat_date(date))
    }

    const onEndDate = (date) => {
        setEndDate(date)
        updateValue("policy_end_date", cattleformat_date(date))
    }


  return (
    <div className="row row-cols-1 row-cols-lg-2 gy-4 form pt-3 pb-3 mt-1">
      <div className="col position-relative">
        {!isDisabled ? (
          <select
            className="w-100"
            value={personalDetail["policy_issuance_status"]}
            onChange={(e) =>  updateValue("policy_issuance_status", e)}
          >
            <option value="">Select</option>
            <option value="Issued">Issued</option>
            <option value="Pending">Pending</option>
            <option value="On Hold">On Hold</option>
            <option value="Canceled">Canceled</option>
          </select>
        ) : (
          <input
            className="w-100"
            value={stringFormat(data.policy_issuance_status)}
            disabled={true}
          />
        )}
        <label className="ms-3 position-absolute" htmlFor="branch_select">
          Policy Issuance Status
        </label>
        <p className="p-error">
          {errors?.policy_issuance_status || formErrors?.policy_issuance_status}
        </p>
      </div>
      {(personalDetail["policy_issuance_status"] == "Issued" || (!personalDetail["policy_issuance_status"] && data?.policy_issuance_status=="Issued")) &&
        <>
          <div className="col position-relative">
          <input
            className="w-100"
            id="policy_number"
            type="text"
            name="policy_number"
            maxLength={50}
            onChange={(e) => updateValue("policy_number", e)}
            value={
              !isDisabled
                ? personalDetail.policy_number
                : stringFormat(data.policy_number)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Policy Number">
            Policy Number
          </label>
        </div>
        <div className="col position-relative">
        {!isDisabled ? (
          <>
              <DatePicker
                dateFormat="dd-MM-yyyy"
                placeholderText="Issuance Date"
                selected={IssureDate || editformat_date(data?.policy_issuance_date)}
                 onKeyDown={(e) => {
                  if(e.which == 8 || e.code == "Backspace" ||
                  e.which == 46 || e.code == "Delete") return
                  
                  e.preventDefault();
                }}
                onChange={(date) => onIssureDate(date)}
              />
          <label className="ms-3 position-absolute" htmlFor="Date">
            Date
            </label>
            </>
        ) : (
          <input
            className="w-100"
            value={stringFormat(data.policy_issuance_date)}
            disabled={true}
          />
        )}
        <label className="ms-3 position-absolute" htmlFor="Policy Issuance Date">
          Policy Issuance Date
        </label>
      
        </div>
       
         <div className="col position-relative">
             {!isDisabled ? (
          <>
          <DatePicker
            dateFormat="dd-MM-yyyy"
            placeholderText="Start Date"
                selected={startDate || editformat_date(data?.policy_start_date)}
                 onKeyDown={(e) => {
                  if(e.which == 8 || e.code == "Backspace" ||
                  e.which == 46 || e.code == "Delete") return
                  
                  e.preventDefault();
                }}
             onChange={(date) => onStartDate(date)}
          />
         
                     </>
        ) : (
          <input
            className="w-100"
            value={stringFormat(data.policy_start_date)}
            disabled={true}
              />
              
          )}
           <label className="ms-3 position-absolute" htmlFor="others">
            Policy Start Period
              </label>
        </div>
        <div className="col position-relative">
            {!isDisabled ? (
          <>
          <DatePicker
            dateFormat="dd-MM-yyyy"
            placeholderText="End Date"
                selected={endDate || editformat_date(data.policy_end_date)}
                 onKeyDown={(e) => {
                  if(e.which == 8 || e.code == "Backspace" ||
                  e.which == 46 || e.code == "Delete") return
                  
                  e.preventDefault();
                }}
            onChange={(date) => onEndDate(date)}
              />
                              </>
        ) : (
          <input
            className="w-100"
            value={stringFormat(data.policy_end_date)}
            disabled={true}
              />
              
          )}
          <label className="ms-3 position-absolute" htmlFor="others">
            Policy End Period
          </label>
        </div>
        </>
      }
       {(personalDetail["policy_issuance_status"] == "On Hold" || (!personalDetail["policy_issuance_status"] && data?.policy_issuance_status=="On Hold")) &&
        <div className="col position-relative">
        {!isDisabled ? (
          <>
            <DatePicker
                dateFormat="dd-MM-yyyy"
                placeholderText="Hold Date"
                selected={holdDate || editformat_date(data?.policy_hold_date)}
                 onKeyDown={(e) => {
                  if(e.which == 8 || e.code == "Backspace" ||
                  e.which == 46 || e.code == "Delete") return
                  
                  e.preventDefault();
                }}
                onChange={(date) => onHoldDate(date)}
              />
          <label className="ms-3 position-absolute" htmlFor="Date">
            Date
            </label>
            </>
        ) : (
          <input
            className="w-100"
           value={stringFormat(data.policy_hold_date)}
            disabled={true}
          />
        )}
        <label className="ms-3 position-absolute" htmlFor="Policy Issuance Date">
          Policy Hold Date
        </label>
      
        </div>
}
      {(personalDetail["policy_issuance_status"] == "Canceled" || (!personalDetail["policy_issuance_status"] && data?.policy_issuance_status=="Canceled")) && <div className="col position-relative">
         {!isDisabled ? (
          <>
          <DatePicker
            dateFormat="dd-MM-yyyy"
            placeholderText="Cancel Date"
              selected={cancelDate || editformat_date(data.policy_cancel_date)}
               onKeyDown={(e) => {
                  if(e.which == 8 || e.code == "Backspace" ||
                  e.which == 46 || e.code == "Delete") return
                  
                  e.preventDefault();
                }}
            onChange={(date) => onCancelDate(date)}
            />
                    </>
        ) : (
          <input
            className="w-100"
           value={stringFormat(data.policy_cancel_date)}
            disabled={true}
          />
        )}
          <label className="ms-3 position-absolute" htmlFor="others">
            Policy Cancel Date
          </label>
        </div>
      }
      
        <div className="col position-relative">
          <input
            className="w-100"
            id="policy_remark"
            type="text"
            name="policy_remark"
            maxLength={50}
            onChange={(e) => updateValue("policy_remark", e)}
            value={
              !isDisabled
                ? personalDetail.policy_remark
                : stringFormat(data.policy_remark)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Policy Remark">
            Policy Remark
          </label>
        </div>
    </div>
  );
};

export default PolicyIssuance;
