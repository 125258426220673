import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import AddInvestigator from "../components/AddInvestigator";
import { viewInvestigator } from "../store/actions/personalDetails";
import { toast } from "react-toastify";

const ViewInvestigatorManagement = (props) => {
  const { id } = props;
  const [show, setShow] = useState(false);
  const [viewInvestigatorDetail, setViewInvestigatorDetail] = useState();

  useEffect(() => {
    viewInvestigator(id)
    .then((res) => {
        if (res?.status === 1) {
            setViewInvestigatorDetail(res.data)
        }
    })
    .catch((error) => {
      toast.error(error.message);
    });
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
     <div className="pos cattle-insurance">
       {show ? <div className="">
                <AddInvestigator
                    isDisabled={true} 
                    data={viewInvestigatorDetail && viewInvestigatorDetail}
                    isShowView={0}
                    isEdit={true}
                />
      </div> : <Loading />}
    </div>
  );
};

export default ViewInvestigatorManagement;
