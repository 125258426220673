import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import X from "../assets/img/x.png";
import { getQualification } from "../store/actions/qualification";
import { file, stringFormat } from "../utils/methods";

const EducationDetailsFrom = ({
  personalchk = void 0,
  personalDetail = [],
  formErrors = [],
  errors = [],
  selectQualification = "",
  setSelectQualification = void 0,
  eduload = false,
  offersFile = "",
  uploadSingleFile = void 0,
  deleteFile = void 0,
  isDisabled = false,
  data = [],
}) => {
  const dispatch = useDispatch();
  const qualificationState = useSelector(
    (state) => state.posQualification.data.data
  );

  useEffect(() => {
    !isDisabled && dispatch(getQualification());
  }, []);

  return (
    <>
      <div className="row row-cols-1 row-cols-lg-2 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
          {isDisabled ? (
            <select
              className="w-100"
              value={selectQualification}
              onChange={(e) => setSelectQualification(e.target.value)}
            >
              <option value="">Select Qualification</option>
              {qualificationState &&
                qualificationState.length > 0 &&
                qualificationState.map(({ id, qualification }) => {
                  return (
                    <option key={id} id={id} value={qualification}>
                      {qualification}
                    </option>
                  );
                })}
            </select>
          ) : (
            <input
              className="w-100"
              value={stringFormat(data.educationalQualification)}
              disabled={true}
            />
          )}
          <label className="ms-3 position-absolute" htmlFor="address_line_1">
            Last Educational Qualification*
          </label>
          <p className="p-error">
            {errors?.selectQualification || formErrors?.selectQualification}
          </p>
        </div>
        {(selectQualification === "Others" ||
          data?.educationalQualification === "Others") &&
          data?.othersIfAny !== null && (
            <div className="col position-relative">
              <input
                className="w-100"
                id="offers"
                type="text"
                name="offers"
                onChange={(e) => personalchk("othersIfAny", e)}
                value={
                  !isDisabled
                    ? personalDetail.othersIfAny
                    : stringFormat(data.othersIfAny)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="others">
                Other (if any)
              </label>
              <p className="p-error">
                {errors?.othersIfAny || formErrors?.othersIfAny}
              </p>
            </div>
          )}
      </div>
      <div className="row form col-12 d-flex flex-column">
        <label htmlFor="Upload Image">Education Proof*</label>
        {!isDisabled ? (
          <div>
            {offersFile.length !== 2 && (
              <label
                className="btn btn-primary"
                style={{
                  borderRadius: "5px",
                  width: 150,
                  marginLeft: "0.5rem",
                }}
              >
                {eduload ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Choose File"
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="file"
                  onChange={(e) => uploadSingleFile(e, "Offers")}
                />
              </label>
            )}
            <div>
              {!offersFile.length > 0 && !isDisabled ? (
                <div>
                  <label>
                    {!offersFile.length && eduload && (
                      <div
                        className="Group"
                        src="images/not-found.png"
                        alt="alternative"
                      ></div>
                    )}
                    <input
                      type="file"
                      className="d-none"
                      size="60"
                      name="file"
                      disabled={offersFile.length === 2}
                      onChange={(e) => uploadSingleFile(e, "Offers")}
                    />
                  </label>
                  {!offersFile.length > 0 && (
                    <p className="p-error">
                      {errors.offer || formErrors.offer}
                    </p>
                  )}
                </div>
              ) : (
                <div className="row mt-3">
                  {offersFile.length > 0 &&
                    offersFile.map((item, index) => {
                      return (
                        <div
                          className={`img-preview ${index === 1 && "mt-4 mt-sm-0 ms-sm-5"
                            }`}
                          key={item}
                        >
                          {item.includes(".pdf")==true ? (
                        <a className='btn btn-primary' href={file(item)} target="_blank">Preview PDF File</a>   
                              ):(
                          <LazyLoadImage effect="blur" src={file(item)} alt="preview" />
                          )}
                              
                          <img
                            onClick={() => deleteFile(index, "Offers")}
                            className="clear"
                            src={X}
                            alt="clear-preview"
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="row mt-3">
              {data?.eduProofImageUrl1 !== null && (
                <div className="img-preview">
                  {data?.eduProofImageUrl1?.includes(".pdf")==true ? (
                  <a className='btn btn-primary' href={file(data?.eduProofImageUrl1)} target="_blank">Preview PDF File</a>   
                  ):(
                    <LazyLoadImage effect="blur" src={file(data?.eduProofImageUrl1)} alt="preview" />
                  )
                  }
                </div>
              )}
              {data?.eduProofImageUrl2 !== null && (
                <div className="img-preview">
                  {data?.eduProofImageUrl2?.includes(".pdf")==true ? (
                  <a className='btn btn-primary' href={file(data?.eduProofImageUrl2)} target="_blank">Preview PDF File</a>   
                  ):(
                  <LazyLoadImage effect="blur" src={file(data?.eduProofImageUrl2)} alt="preview" />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EducationDetailsFrom;
