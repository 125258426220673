import { useState } from "react";
import { Card, Button, Modal } from "react-bootstrap";
import { format_date } from "../utils/methods";
import axios from "../config/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { file, stringFormat } from "../utils/methods";

const AgreementForm = ({ data }) => {
  const [remark, setRemark] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const approveAgreement = () => {
    makeReq("Agreement Approved");
  };

  const rejectAgreement = () => {
    if (remark === "") {
      toast.error("Please enter remark.");
      return;
    }
    setShow(false);
    makeReq("Agreement Rejected");
  };

  function makeReq(status) {
    axios
      .post("admin/updateAgreementAdmin", {
        posId: data?.id,
        agreementStatus: status,
        reason: status === "Agreement Approved" ? "" : remark,
      })
      .then((res) => {
        if (res.data.error) {
          toast.error("Something went wrong!");
        } else {
          status === "Agreement Approved" &&
            toast.success("Agreement Approved Sucessfully.");
          status === "Agreement Rejected" &&
            toast.success("Agreement Rejected Sucessfully.");
          navigate("/pos/all");
        }
      })
      .catch((error) => {});
  }

  return (
    <div className="row row-cols-1 row-cols-lg-1 gy-4 form pb-3">
      <div className="col">
        {(data?.posStatus === "Agreement Uploaded" ||
          data?.posStatus === "Agreement Approved" ||
          data?.posStatus === "Agreement Rejected") && (
          <Card className="border-primary" style={{ maxWidth: "100%" }}>
            <Card.Body className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
              <div className="d-flex flex-column mb-2 mb-lg-0">
                <div>
                  Agreement File :
                  <a
                    href={file(data[0]?.fileName)}
                    target="_blank"
                    className="agreement_download_btn"
                  >
                    <i className="bi bi-paperclip ms-2"></i> Download file{" "}
                  </a>
                </div>
                <div>
                  Uploaded Date :{" "}
                  <span className="ms-2 agreement_text">
                    {format_date(data[0]?.createdAt)}
                  </span>
                </div>
                {data?.posStatus === "Agreement Rejected" && (
                  <div>
                    Remark :{" "}
                    <span className="agreement_text">{stringFormat(data[0]?.remark)}</span>
                  </div>
                )}
              </div>
              {data?.posStatus !== "Agreement Approved" &&
              data?.posStatus !== "Agreement Rejected" ? (
                <div>
                  <Button
                    variant="primary"
                    className="w-auto"
                    onClick={() => approveAgreement()}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="danger ms-1"
                    className="w-auto"
                    onClick={() => setShow(true)}
                  >
                    Reject
                  </Button>
                </div>
              ) : (
                <div>
                  {data?.posStatus === "Agreement Approved" && (
                    <>
                      <i
                        className="bi bi-check-circle-fill text-success me-2"
                        style={{ marginTop: "0.2rem" }}
                      ></i>
                      <span style={{ fontSize: "15px", color: "#0f5132" }}>
                        Approved
                      </span>
                    </>
                  )}
                  {data?.posStatus === "Agreement Rejected" && (
                    <>
                      <i
                        className="bi bi-x-circle-fill text-danger me-2"
                        style={{ marginTop: "0.2rem" }}
                      ></i>
                      <span style={{ fontSize: "15px", color: "#842029" }}>
                        Rejected
                      </span>
                    </>
                  )}
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="row row-cols-lg-1 form">
            <div className="col">
              <textarea
                className="w-100"
                id="address1"
                type="text"
                name="address1"
                rows="3"
                autoFocus={true}
                placeholder="Enter remark here"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => rejectAgreement()}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AgreementForm;
