import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import IntimateClaim from "../components/IntimateClaim";
import ClaimDoctorDetails from "../components/ClaimDoctorDetails";
import ClaimInvestigatorDetails from "../components/ClaimInvestigatorDetails";
import ClaimSettlement from "../components/ClaimSettlement";
import { toast } from "react-toastify";
import { addClaim } from "../store/actions/personalDetails";
import { useNavigate } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { updatePhotoDoctorUpload, updateInvestigatorCertificate } from "../store/actions/Image";
import { checkImgValidation } from "../utils/methods";

const AddIntimateClaim = () => {
    const fname_regex = /^[a-zA-Z ]+$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const mobile_regex = /^[0-9\b]+$/;

    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [intimateDetails, setIntimateDetails] = useState({
        claim_intimation_date: "",
        claim_reason: "",
        date_of_happening: "",
        additional_remarks:"",
    })
    const [investigatorDetails, setInvestigatorDetails] = useState({
        investigator_reg_ID: "",
        investigator_email: "",
        investigator_phone: null,
        investigator_name: "",
        investigator_state: "",
        investigator_city: "",
        investigator_zipcode: null,
        investigator_block: "",
        investigator_village: "",
        investigator_address: "",
        investigator_location: "",
        investigator_upload: ""
    })
    const [doctorDetails, setDoctorDetails] = useState({
        doctor_reg_ID: "",
        doctor_designation: "",
        doctor_email: "",
        doctor_phone: null,
        doctor_name: "",
        doctor_state: "",
        doctor_city: "",
        doctor_zipcode: null,
        doctor_block: "",
        doctor_village: "",
        doctor_address: "",
        doctor_location: "",
        doctor_upload: ""
    })
    const [claimSettlementDetails, setClaimSettlementDetails] = useState({
        claim_status: "",
        claim_partition: "",
        claim_amount:"",
        claim_additional_remarks:"",
    })
    const [searchedTagData, setSearchedTagData] = useState();
    const [assignedInvestigator, setAssignedInvestigator] = useState();
    const [assignedDoctor, setAssignedDoctor] = useState();
    const [selectInvestigatorState, setSelectInvestigatorState] = useState("");
    const [selectInvestigatorCity, setSelectInvestigatorCity] = useState("");
    const [selectInvestigatorPinCode, setSelectInvestigatorPinCode] = useState("");
    const [selectDoctorState, setSelectDoctorState] = useState("");
    const [selectDoctorCity, setSelectDoctorCity] = useState("");
    const [selectDoctorPinCode, setSelectDoctorPinCode] = useState("");
    
    const [doctorPhotoUpload, setDoctorPhotoUpload] = useState([]);
    const [doctorDocumentCount, setDoctorDocumentCount] = useState(1);
    const [loaderCount, setLoaderCount] = useState([]);
    const [investigatorPhotoUpload, setInvestigatotPhotoUpload] = useState([]);
    const [investigatorDocumentCount, setInvestigatorDocumentCount] = useState(1);
    const [investigatorLoaderCount, setInvestigatorLoaderCount] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const claimDetail = {
            ...searchedTagData,
            claim_intimation_date: intimateDetails.claim_intimation_date,
            claim_reason: intimateDetails.claim_reason.label,
            date_of_happening: intimateDetails.date_of_happening,
            additional_remarks: intimateDetails.additional_remarks,
        }
        const investigator = {
            investigator_reg_ID: investigatorDetails.investigator_reg_ID || assignedInvestigator?.investigator_reg_ID,
            investigator_email: investigatorDetails.investigator_email || assignedInvestigator?.investigator_email,
            investigator_phone: String(investigatorDetails.investigator_phone || assignedInvestigator?.investigator_phone),
            investigator_name: investigatorDetails.investigator_name || assignedInvestigator?.investigator_name,
            investigator_state: selectInvestigatorState || assignedInvestigator?.investigator_state,
            investigator_city: selectInvestigatorCity || assignedInvestigator?.investigator_city,
            investigator_zipcode: selectInvestigatorPinCode || assignedInvestigator?.investigator_zipcode,
            investigator_block: investigatorDetails.investigator_block || assignedInvestigator?.investigator_block,
            investigator_village: investigatorDetails.investigator_village || assignedInvestigator?.investigator_village,
            investigator_address: investigatorDetails.investigator_address || assignedInvestigator?.investigator_address,
            investigator_location: investigatorDetails.investigator_location || assignedInvestigator?.investigator_location,
            investigator_upload: investigatorPhotoUpload?.toString() || assignedInvestigator?.investigator_upload || "", 
        }
        const doctor = {
            doctor_reg_ID: doctorDetails.doctor_reg_ID || assignedDoctor?.doctor_reg_ID,
            doctor_designation: doctorDetails.doctor_designation || assignedDoctor?.doctor_designation,
            doctor_email: doctorDetails.doctor_email || assignedDoctor?.doctor_email,
            doctor_phone: String(doctorDetails.doctor_phone || assignedDoctor?.doctor_phone),
            doctor_name: doctorDetails.doctor_name || assignedDoctor?.doctor_name,
            doctor_state: selectDoctorState || assignedDoctor?.doctor_state,
            doctor_city: selectDoctorCity || assignedDoctor?.doctor_city,
            doctor_zipcode: selectDoctorPinCode || assignedDoctor?.doctor_zipcode,
            doctor_block: doctorDetails.doctor_block || assignedDoctor?.doctor_block,
            doctor_village: doctorDetails.doctor_village || assignedDoctor?.doctor_village,
            doctor_address: doctorDetails.doctor_address || assignedDoctor?.doctor_address,
            doctor_location: doctorDetails.doctor_location || assignedDoctor?.doctor_location,
            doctor_upload: doctorPhotoUpload?.toString() || assignedDoctor?.doctor_upload || ""
        }
        const claimSettle = {
            claim_status: claimSettlementDetails.claim_status.label,
            claim_partition: Number(claimSettlementDetails.claim_partition),
            claim_amount: Number(claimSettlementDetails.claim_amount),
            claim_additional_remarks: claimSettlementDetails.claim_additional_remarks,
        }
        addClaim({claimDetails: claimDetail, investigatorDetails: investigator, docterDetails: doctor, claimSettlement: claimSettle})
        .then((res) => {
          res?.status === 0 && (toast.error(res?.message) );
          if (res?.status === 1) {
            toast.success("Claim Intimate added successfully.");
            navigate("/cattle-insurance/claim");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }

    const personalchk = (sectionname, e) => {
        if (sectionname === "claim_reason" || sectionname === "claim_intimation_date" || sectionname === "date_of_happening") {
            setIntimateDetails({ ...intimateDetails, [sectionname]: e});
        }
        if (sectionname === "additional_remarks") {
          setIntimateDetails({ ...intimateDetails, [sectionname]: e.target.value});
        }
        if (sectionname === "doctor_state" || sectionname === "doctor_city" || sectionname === "doctor_zipcode") {
            setDoctorDetails({ ...doctorDetails, [sectionname]: e});
            // setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "doctor_reg_ID" || sectionname === "doctor_designation" || sectionname === "doctor_name" || sectionname === "doctor_block" || sectionname === "doctor_village" || sectionname === "doctor_address" || sectionname === "doctor_location") {
            setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value});
            // setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "doctor_phone") {
            if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
              setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
            } else {
              e.target.value !== "e" &&
                e.target.value.length <= 10 &&
                setDoctorDetails({
                  ...doctorDetails,
                  [sectionname]: parseInt(e.target.value),
                });
            //   setErrors({ ...errors, [sectionname]: "" });
            }
        }
        if (sectionname === "investigator_phone") {
            if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
              setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
            } else {
              e.target.value !== "e" &&
                e.target.value.length <= 10 &&
                setInvestigatorDetails({
                  ...investigatorDetails,
                  [sectionname]: parseInt(e.target.value),
                });
            //   setErrors({ ...errors, [sectionname]: "" });
            }
        }
        if (sectionname === "investigator_email") {
            if (e.target.value !== "" && !email_regex.test(e.target.value)) {
              setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
              setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value });
            } else {
              setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value });
            //   setErrors({ ...errors, [sectionname]: "" });
            }
        }
        if (sectionname === "doctor_email") {
            if (e.target.value !== "" && !email_regex.test(e.target.value)) {
                setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
                setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value });
            } else {
                setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value });
                // setErrors({ ...errors, [sectionname]: "" });
            }
        }
        if (sectionname === "investigator_state" || sectionname === "investigator_city" || sectionname === "investigator_zipcode") {
            setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e});
            // setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "investigator_reg_ID" || sectionname === "investigator_name" || sectionname === "investigator_block" || sectionname === "investigator_village" || sectionname === "investigator_address" || sectionname === "investigator_location") {
            setInvestigatorDetails({ ...investigatorDetails, [sectionname]: e.target.value});
            // setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "claim_status") {
            setClaimSettlementDetails({ ...claimSettlementDetails, [sectionname]: e});
        }
        if (sectionname === "claim_partition" || sectionname === "claim_amount" || sectionname === "claim_additional_remarks") {
            setClaimSettlementDetails({ ...claimSettlementDetails, [sectionname]: e.target.value});
        }
    };

    const uploadSingleFile = (e, field) => {
        // validtion && setFormErrors(validate(addCattleOwnerDetails));
  if (checkImgValidation(e?.target?.files?.[0]) === false) {
      return;
    }
        if (field.split("-")[0] === "doctor_upload") {
          const arr = [...loaderCount];
          const index = field.split("-")[1];
          arr[index]=true
         setLoaderCount(arr)
          const formData = new FormData();
          formData.append("doctor_upload", e.target.files[0]);
          updatePhotoDoctorUpload(formData)
            .then((res) => {
              if (res.status === 0) {
                setErrors({ ...errors, photoURL: res?.message });
                arr[index]=false
                setLoaderCount(arr)
                // setOtherLoader(false);
              } else {
                arr[index]=false
                setLoaderCount(arr)
                const temp = [...doctorPhotoUpload];
                // setOtherLoader(false);
                temp[Number(field.split("-")[1])] = res.photoURL
                setDoctorPhotoUpload(temp);
              }
            })
            .catch((error) => {
              setErrors({ ...errors, photoURL: error.message });
            });
        }
        if (field.split("-")[0] === "investigator_upload") {
            const arr = [...loaderCount];
            const index = field.split("-")[1];
            arr[index]=true
            setInvestigatorLoaderCount(arr)
            const formData = new FormData();
            formData.append("investigator_upload", e.target.files[0]);
            updateInvestigatorCertificate(formData)
              .then((res) => {
                if (res.status === 0) {
                  setErrors({ ...errors, photoURL: res?.message });
                  arr[index]=false
                  setInvestigatorLoaderCount(arr)
                  // setOtherLoader(false);
                } else {
                  arr[index]=false
                  setInvestigatorLoaderCount(arr)
                  const temp = [...investigatorPhotoUpload];
                  // setOtherLoader(false);
                  temp[Number(field.split("-")[1])] = res.photoURL
                  setInvestigatotPhotoUpload(temp);
                }
              })
              .catch((error) => {
                setErrors({ ...errors, photoURL: error.message });
              });
          }
      };
    
      const deleteFile = (e, field) => {
        if (field.split("-")[0] === "doctor_upload") {
          const temp = [...doctorPhotoUpload]
          delete temp[e]
          setDoctorPhotoUpload(temp);
        }
        if (field.split("-")[0] === "investigator_upload") {
            const temp = [...investigatorPhotoUpload]
            delete temp[e]
            setInvestigatotPhotoUpload(temp);
          }
      };

    useEffect(() => {
        setTimeout(() => {
        setShow(true);
        }, 500);
    }, [])

  return (
    <div className="pos cattle-insurance">
      <Header title={"Intimate Claim"} />
      {show ? 
      <>
      <div className="p-4 bg-white shadow rounded-3">
      <Accordion defaultActiveKey="0" flush> 
            <Accordion.Item eventKey="1">
            <Accordion.Header>Claim Form</Accordion.Header>
            <Accordion.Body>
                <IntimateClaim
                    updateData={personalchk}
                    intimateDetails={intimateDetails}
                    setSearchedTagData={setSearchedTagData}
                />
            </Accordion.Body>
          </Accordion.Item> 
          <Accordion.Item eventKey="3">
            <Accordion.Header>Assign Investigator</Accordion.Header>
            <Accordion.Body>
              <ClaimInvestigatorDetails
                personalchk={personalchk}
                personalDetail={investigatorDetails}
                selectInvestigatorState={selectInvestigatorState}
                setSelectInvestigatorState={setSelectInvestigatorState}
                selectInvestigatorCity={selectInvestigatorCity}
                setSelectInvestigatorCity={setSelectInvestigatorCity}
                selectInvestigatorPinCode={selectInvestigatorPinCode}
                setSelectInvestigatorPinCode={setSelectInvestigatorPinCode}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                setInvestigatorDocumentCount={setInvestigatorDocumentCount}
                investigatorDocumentCount={investigatorDocumentCount}
                investigatorPhotoUpload={investigatorPhotoUpload}
                investigatorLoaderCount={investigatorLoaderCount}
                // formErrors={formErrors}
                errors={errors}
                setAssignedInvestigator={setAssignedInvestigator}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Assign Doctor</Accordion.Header>
            <Accordion.Body>
              <ClaimDoctorDetails
                personalchk={personalchk}
                personalDetail={doctorDetails}
                setDoctorDocumentCount={setDoctorDocumentCount}
                doctorDocumentCount={doctorDocumentCount}
                selectDoctorState={selectDoctorState}
                setSelectDoctorState={setSelectDoctorState}
                selectDoctorCity={selectDoctorCity}
                setSelectDoctorCity={setSelectDoctorCity}
                selectDoctorPinCode={selectDoctorPinCode}
                setSelectDoctorPinCode={setSelectDoctorPinCode}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                // formErrors={formErrors}
                errors={errors}
                doctorPhotoUpload={doctorPhotoUpload}
                loaderCount={loaderCount}
                setAssignedDoctor={setAssignedDoctor}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Claim Settlement</Accordion.Header>
            <Accordion.Body>
                <ClaimSettlement
                    updateData={personalchk}
                    claimSettlementDetails={claimSettlementDetails}
                />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
            <div className="row row-cols-1 row-cols-lg-1 mt-3 form d-flex justify-content-center align-items-center w-100">
                <button className="btn btn-primary m-2 mb-4" onClick={handleSubmit}>
                    Submit
                </button>
            </div>
      </div> 
      </>
      :
      <Loading />}
    </div>
  );
};

export default AddIntimateClaim;
