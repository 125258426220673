import { useEffect, useState } from "react";

function useSidebarToggle() {
  const [toggle, setToggle] = useState(false);
  const [toggleClass, setToggleClass] = useState("");

  useEffect(() => {
    toggle ? setToggleClass("sidebar_toggle") : setToggleClass("");
  }, [toggle]);

  const toogleSidebar = () => {
    setToggle(!toggle);
  };

  return { toogleSidebar, toggleClass, toggle };
}

export default useSidebarToggle;
