import { FETCH_RELATIONMANAGER_FAILURE, FETCH_RELATIONMANAGER_REQUEST, FETCH_RELATIONMANAGER_SUCCESS } from "../actions/types";

const initialState = {
    data: [],
    error: ''
};

export const posRelationManagerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RELATIONMANAGER_REQUEST:
            return {
                ...state,
            }
        case FETCH_RELATIONMANAGER_SUCCESS:
            return {
                data: action.payload
            }
        case FETCH_RELATIONMANAGER_FAILURE:
            return {
                data: [],
                error: action.payload
            }
        default:
            return state;
    }
}