import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { listRole } from "../store/actions/personalDetails";
import { toast } from "react-toastify";

const RoleDetails = () => {
  const [roleList, setRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    listRole()
      .then((res) => {
        if (res?.status === 1) {
          setRoleList(res.data)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [])

  roleList?.sort((a, b) => (b.id) - (a.id));

  if (isLoading) return <Loading />;

  return (
    <div className="pos cattle-insurance">
      <Header
        title={"Role Details"}
        btn={false}
        btnText="Add Role"
        btnLink="/cattle-insurance/add-role"
        btnClass="p-btn"
      />
      <>
        <div className="p-4 bg-white shadow rounded-3">
          <div className="table-responsive">

            <table className="table table-bordered table-striped" width="auto">
              <thead style={{ backgroundColor: "#edf5fb" }}>
                <tr scope="col" className="text-uppercase">
                  <th scope="col" className="text-center">Role Id</th>
                  <th scope="col" className="text-center">Role Name</th>
                  <th scope="col" className="text-center">Created By</th>
                  {/* <th scope="col" className="text-center">Created On</th> */}
                </tr>
              </thead>

              <tbody style={{ fontFamily: "calibri" }}>
                {roleList?.length ? roleList?.map((item, i) => (<tr>
                  <td className="text-center" key={i}>{item.role_id}</td>
                  <td className="text-center">{item.role_name}</td>
                  <td className="text-center">{item.created_by}</td>
                  {/* <td className="text-center">{item.created_on}</td> */}
                </tr>)) :
                  <tr>
                    <td colSpan={7} style={{ textAlign: 'center' }}>No Data Found</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </>
    </div>
  );
};

export default RoleDetails
