import { Card } from "react-bootstrap";
import "../assets/css/login.css";
import Logo from "../assets/img/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { superAdminLogin } from "../store/actions/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
      role: "super admin",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .max(50, "Must be 50 characters or less")
        .min(5, "Must be 5 characters or more")
        .required("Required"),
      role: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: ({ email, password, role }) => {
      dispatch(superAdminLogin(email, password, role)).then((res) => {
        if (res) {
          if (role === "Doctor") {
            navigate("/cattle-insurance/doctor-dashboard");
          } else if(role === "Investigator") {
            navigate("/cattle-insurance/investigator-dashboard");
          } else if(role === "Surveyor") {
            navigate("/cattle-insurance/surveyor-dashboard");
          }else {
            navigate("/pos/all");
          }
          window.location.reload();
        }
      });
    },
  });

  return (
    <div className="d-flex justify-content-center align-items-center my-auto login_container">
      <Card className="shadow p-4">
        <Card.Body>
          <div className="text-center">
            <img src={Logo} alt="logo" className="logo" />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="row mt-4">
              <div className="col-2 d-none d-lg-block">
                <label className="text-muted" htmlFor="Email">
                  Email*
                </label>
              </div>
              <div className="col-12 col-lg-10">
                <input
                  type="email"
                  className="form-control icueLoginEmail "
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="icueLoginEmail"
                  placeholder="Enter Email"
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "16px",
                      fontFamily: "Calibri",
                    }}
                  >
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-2 d-none d-lg-block">
                <label className="text-muted" htmlFor="Password">
                  Password*
                </label>
              </div>
              <div className="col-lg-10 col-12">
                <input
                  type="password"
                  className="form-control icueLoginPwd border-end-0 text-capitalize"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="icueLoginPwd"
                  placeholder="enter password"
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "16px",
                      fontFamily: "Calibri",
                    }}
                  >
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-2 d-none d-lg-block">
                <label className="text-muted" htmlFor="Role">
                  Role*
                </label>
              </div>
              <div className="col-lg-10 col-12">
                <select
                  className="form-select form-select-sm icueLoginRole text-muted text-capitalize"
                  aria-label="form-select-sm icueLoginRole"
                  name="role"
                  id="icueLoginRole"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="super admin">super admin</option>
                  <option value="admin">admin</option>
                  <option value="support">support</option>
                  <option value="Doctor">doctor</option>
                  <option value="Investigator">investigator</option>
                  <option value="Surveyor">surveyor</option>
                </select>
                {formik.touched.role && formik.errors.role ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "16px",
                      fontFamily: "Calibri",
                    }}
                  >
                    {formik.errors.role}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-2 d-none d-lg-block">{""}</div>
              <div className="col-lg-10 col-12">
                <button
                  type="submit"
                  className="btn btn-primary "
                  style={{
                    fontSize: "16px",
                    fontFamily: "Calibri",
                    width: "100%",
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
