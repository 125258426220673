import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPosDetail, getSearchResult } from "../store/actions/posDetail";
import { getPosInfo } from "../store/actions/posInfo";
import POSDetailsTable from "../components/POSDetailsTable";
import Header from "../components/Header";
import DatePicker from "react-datepicker";
import { format_date } from "../utils/methods";
import Loading from "../components/Loading";
import { getAdminRolesInfo } from "../store/actions/personalDetails";
import { ROLES } from "../utils/variables";

const POSDetails = () => {
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const posDetailState = useSelector((state) => state.posPosDetail.data.data);
  const total = useSelector((state) => state.posPosDetail.data.total);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchMob, setSearchMob] = useState("");
  const [status, setStatus] = useState("");
  const [pos_Name, setPos_Name] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const SearchResult = useSelector((state) => state.posposSearchResult.data);
  let searchTotal = useSelector((state) => state.posposSearchResult.data.total);
  const [searchPage, setSearchPage] = useState(1);
  const [show, setShow] = useState(false);
  var entries;

  const dispatch = useDispatch();
  const [posRole, setPOSRoles] = useState([])
  let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role

  useEffect(() => {
    let role_id = ROLES[role_name]
    if(role_id){
      getAdminRolesInfo(role_id)
      .then((res) => {
        if (res.status) {
          let pos = res.data.filter(obj => {
            return obj.module_name == "POS Management";
          });
          setPOSRoles(pos)
        }
      })
    }
  }, [])

  useEffect(() => {
    setPageCount(Math.ceil(total / itemsPerPage));
  }, [total]);

  useEffect(() => {
    setPageCount(Math.ceil(searchTotal / itemsPerPage));
  }, [SearchResult]);

  useEffect(() => {
    dispatch(getPosDetail(currentPage, itemsPerPage));
    if (isSearched) {
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
      const payLoad = {
        page: searchPage,
        limit: itemsPerPage,
        startDate: startDate ? format_date(startDate) : "",
        endDate: endDate ? format_date(endDate) : "",
        mobileNo: searchMob ? searchMob : "",
        posName: pos_Name ? pos_Name : "",
        posStatus: status ? status : "",
      };
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
      dispatch(getSearchResult(payLoad)).then();
      setIsSearched(true);
    } else {
      setPageCount(0);
      setPageCount(Math.ceil(total / itemsPerPage));
    }
  }, [itemsPerPage]);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, [])

  const setposDetail = (id) => {
    setposDetail(id);
    dispatch(getPosDetail(id));
  };

  const setposInfo = (id) => {
    setposInfo(id);
    dispatch(getPosInfo(id));
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    if (isSearched) {
      const payLoad = {
        page: event.selected + 1,
        limit: itemsPerPage,
        startDate: startDate ? format_date(startDate) : "",
        endDate: endDate ? format_date(endDate) : "",
        mobileNo: searchMob ? searchMob : "",
        posName: pos_Name ? pos_Name : "",
        posStatus: status ? status : "",
      };
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
      dispatch(getSearchResult(payLoad)).then();
      setIsSearched(true);
      setSearchPage(event.selected + 1);
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
    } else {
      dispatch(getPosDetail(event.selected + 1, itemsPerPage));
      setCurrentPage(event.selected + 1);
    }
  };

  const handleOnSearch = () => {
    const payLoad = {
      page: searchPage,
      limit: itemsPerPage,
      startDate: startDate ? format_date(startDate) : "",
      endDate: endDate ? format_date(endDate) : "",
      mobileNo: searchMob ? searchMob : "",
      posName: pos_Name ? pos_Name : "",
      posStatus: status ? status : "",
    };
    setPageCount(Math.ceil(searchTotal / itemsPerPage));
    dispatch(getSearchResult(payLoad)).then();
    setIsSearched(true);
  };

  const handleOnClearFilter = () => {
    setIsSearched(false);
    setPageCount(Math.ceil(total / itemsPerPage));
    setStartDate("");
    setEndDate("");
    setPos_Name("");
    setSearchMob("");
    setStatus("");
  };

  if (SearchResult.message === "success") {
    entries = SearchResult.data[0]?.length;
  }

  return (
    <>
      <Header
        title="POS Details"
        btn={posRole[0]?.create_action === 1 ? true : false}
        btnText="Add New POS"
        btnLink="/pos/add"
        btnClass="p-btn"
      >
      </Header>
      {show ? <>
        <div className="p-4 bg-white shadow rounded-3">
          <div className="row gy-2 row-cols-1 row-cols-lg-6 gx-2 d-flex justify-content-center">
            <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="name">From</label>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                placeholderText="Select Date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="name">To</label>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                placeholderText="Select Date"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
            <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="dropdown">POS First Name</label>
              <input
                type="text"
                name="name"
                value={pos_Name}
                onChange={(e) => {
                  setPos_Name(e.target.value);
                }}
              />
            </div>
            <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="number">Mobile Number/POSP Code</label>
              <input
                type="text"
                name="number"
                value={searchMob}
                onChange={(e) => {
                  setSearchMob(e.target.value);
                }}
              />
            </div>
            <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="dropdown">POS Status</label>
              <select
                id="pos_status"
                name="cars"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option value="">Select</option>

                <option value="Pass">Pass</option>
                <option value="Fail">Fail</option>
                <option value="Rejected">Rejected</option>
                <option value="Approved">Approved</option>
                <option value="Details Submitted">Details Submitted</option>
                <option value="Training Completed">Training Completed</option>
                <option value="Agreement Uploaded">Agreement Uploaded</option>
                <option value="Agreement Approved">Agreement Approved</option>
                <option value="Agreement Rejected">Agreement Rejected</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button
              className="btn btn-sm pos_filter_btn primary_btn me-2"
              onClick={() => handleOnSearch()}
            >
              Search
            </button>
            <button
              className="btn btn-sm pos_filter_btn"
              onClick={handleOnClearFilter}
            >
              Clear Filter
            </button>
          </div>
        </div>
        <div className="p-4 bg-white shadow rounded-3 mt-3">
          <POSDetailsTable
            data={isSearched ? SearchResult.data : posDetailState}
            total={isSearched ? searchTotal : total}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            paginationPageCount={pageCount}
            paginationHandlePageClick={handlePageClick}
            posRole={posRole[0]}
          />
        </div>
      </> : <Loading />}
    </>
  );
};

export default POSDetails;
