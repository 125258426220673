import { Link } from "react-router-dom";

const Header = ({
  title = "",
  btn = false,
  btnText = "",
  btnLink = "",
  btnClass = "",
  onClick,
  children
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center pt-4 pb-4 header">
      <h4 className="mainHeading" style={{color:"#334d6e"}}>{title}</h4>
      {btn && (
        <Link to={btnLink} className={`addbtn btn btn-primary ${btnClass}`} style={{fontFamily:"ErasDemiITC"}} onClick={onClick}>
          {children} {btnText}
        </Link>
      )}
    </div>
  );
};

export default Header;
