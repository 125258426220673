import React, {useState} from "react";
import { stringFormatHandleEmpty, cattleformat_date, editformat_date } from "../utils/methods";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from 'axios';
import { toast } from "react-toastify";
import Constant from "../utils/constant";
import { useEffect } from "react";

const IntimateClaim = ({
  updateData = void 0,
  intimateDetails = [],
  formErrors = [],
  errors = [],
  isDisabled = false,
  setSearchedTagData = void 0,
  data = [],
  isEdit = false
}) => {

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [intimationClaimDate, setIntimationClaimDate] = useState();
    const [dateOfHappening, setDateOfHappening] = useState();
    const [isShow, setIsShow] = useState(false);

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
    }
  
    const searchByNumber = () => {
          if (searchInput !== '') {
           axios.post(Constant.BASE_URL + 'cattle/searchCattleByTag', {ear_tag_no : searchInput})
              .then(response => {
                setFilteredResults(response.data.data[0])
                setSearchedTagData(response.data.data[0])
                if(response?.data?.status == 0){
                    toast.error("Ear Tag no. is not valid")
                    setIsShow(false)
                }
                if(response?.data?.status == 1){
                    toast.success("Ear Tag is Assigned")
                    setIsShow(true)
                }
              })
              .catch((error) => {
                toast.error(error.message);
              });
          }
          else{
              setFilteredResults([])
          }
    }

  const onIntimationClaimDate = (date) => {
    setIntimationClaimDate(date)
    updateData("claim_intimation_date", cattleformat_date(date))  
  }

  const onDateOfHappening = (date) => {
    setDateOfHappening(date)
    updateData("date_of_happening", cattleformat_date(date))  
  }

    const claimReason = [
        {
            label: 'Accident',
            value: 1
        },
        {
            label: 'Death',
            value: 2
        },
        {
            label: 'Theft',
            value: 3
        },
    ]

    // console.log(intimateDetails, 'intimateDetails')
  return (
    <> 
      {!isEdit && !isDisabled && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            type="text"
            onChange={(e) => {
                searchItems(e.target.value)
                // updateData("doctor_phone_search", e)
            }}
            value={searchInput}
            name="ear_tag_no"
            placeholder="Search by Ear Tag No."
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Ear Tag No.
          </label>
        </div>
        <div style={{ width: '20%' }}>
          <button style={{height:"45px", width:"115px", fontSize: '16px', marginTop: '5px'}} className="btn btn-primary" onClick={()=>searchByNumber()}>Search</button>
        </div>
      </div>}

      {isShow && <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3">
        <div>
          <b>Proposer Name :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{filteredResults?.policy_name || ''}</span>
        </div>
        <div>
          <b>Policy No :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{filteredResults?.customer_ID || ''}</span>
        </div>
        <div>
          <b>Ear Tag No :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{filteredResults?.ear_tag_no || ''}</span>
        </div>
        <div>
          <b>Policy Start Date :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px',textTransform:"capitalize" }}>{filteredResults?.policy_start_date || ''}</span>
        </div>
        <div className="mb-3">
          <b>Policy End Date :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px',textTransform:"capitalize" }}>{filteredResults?.policy_end_date || ''}</span>
        </div>
      </div>}

      {isDisabled && <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3">
        <div>
          <b>Proposer Name :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{data?.policy_name || ''}</span>
        </div>
        <div>
          <b>Policy No :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{data?.customer_ID || ''}</span>
        </div>
        <div>
          <b>Ear Tag No :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{data?.ear_tag_no || ''}</span>
        </div>
        <div>
          <b>Policy Start Date :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px',textTransform:"capitalize" }}>{data?.policy_start_date || ''}</span>
        </div>
        <div className="mb-3">
          <b>Policy End Date :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px',textTransform:"capitalize" }}>{data?.policy_start_date || ''}</span>
        </div>
      </div>}

       {!isDisabled && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    selected={intimationClaimDate || editformat_date(intimateDetails?.claim_intimation_date)}
                    onKeyDown={(e) => {
                        if(e.which == 8 || e.code == "Backspace" ||
                        e.which == 46 || e.code == "Delete") return
                        
                        e.preventDefault();
                    }}
                    name="claim_intimation_date"
                    onChange={(date) => onIntimationClaimDate(date)}
                />
                <label className="ms-3 position-absolute" htmlFor="others">
                    Claim Intimation Date
                </label>
                <p className="p-error">{errors?.claim_intimation_date || formErrors?.claim_intimation_date}</p>
            </div>
            <div className="col position-relative">
                <>
                    {isEdit ? <Select
                        options={claimReason}
                        isClearable={true}
                        onChange={(e) => (e != null ? updateData("claim_reason", e) : updateData("claim_reason", ""))}
                        className="searchable-select"
                        name="claim_reason"
                        defaultValue={{ label: intimateDetails?.claim_reason, value: intimateDetails?.claim_reason }}
                        isDisabled={isDisabled}
                    /> : 
                    <Select
                        options={claimReason}
                        isClearable={true}
                        onChange={(e) => (e != null ? updateData("claim_reason", e) : updateData("claim_reason", ""))}
                        className="searchable-select"
                        name="claim_reason"
                        value={intimateDetails.claim_reason}
                        isDisabled={isDisabled}
                    />
                    }
                    <label className="ms-3 position-absolute" htmlFor="Claim Reason">
                        Claim Reason
                    </label>
                    <p className="p-error">{errors?.claim_reason || formErrors?.claim_reason}</p>
                </>
            </div>
            <div className="col position-relative">
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    selected={dateOfHappening || editformat_date(intimateDetails?.date_of_happening)}
                    onKeyDown={(e) => {
                        if(e.which == 8 || e.code == "Backspace" ||
                        e.which == 46 || e.code == "Delete") return
                        
                        e.preventDefault();
                    }}
                    name="date_of_happening"
                    onChange={(date) => onDateOfHappening(date)}
                />
                <label className="ms-3 position-absolute" htmlFor="Date of">
                    Date of {intimateDetails?.claim_reason?.label || intimateDetails?.claim_reason || ""}
                </label>
                <p className="p-error">{errors?.date_of_happening || formErrors?.date_of_happening}</p>
            </div>
            <div className="col position-relative">
                <textarea
                    className="w-100"
                    id="additional_remarks"
                    rows="4" cols="50"
                    name="additional_remarks"
                    maxLength={50}
                    onChange={(e) => updateData("additional_remarks", e)}
                    value={intimateDetails.additional_remarks}
                    disabled={false}
                />
                <label className="ms-3 position-absolute" htmlFor="Additional Remarks">
                    Additional Remarks
                </label>
                    <p className="p-error">{errors?.additional_remarks || formErrors?.additional_remarks}</p>
            </div>
        </div>}

        {isDisabled && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
                    <input
                        className="w-100"
                        value={stringFormatHandleEmpty(data.claim_intimation_date)}
                        disabled={true}
                    />
                <label className="ms-3 position-absolute" htmlFor="others">
                    Claim Intimation Date
                </label>
            </div>
            <div className="col position-relative">
                <>
                    <input
                        className="w-100"
                        value={stringFormatHandleEmpty(data.claim_reason)}
                        disabled={true}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Claim Reason">
                        Claim Reason
                    </label>
                </>
            </div>
            <div className="col position-relative">
                    <input
                        className="w-100"
                        value={stringFormatHandleEmpty(data.date_of_happening)}
                        disabled={true}
                    />
                <label className="ms-3 position-absolute" htmlFor="Date of">
                    Date of {data?.claim_reason || ""}
                </label>
            </div>
            <div className="col position-relative">
                <textarea
                    className="w-100"
                    id="additional_remarks"
                    rows="4" cols="50"
                    name="additional_remarks"
                    maxLength={50}
                    value={data.additional_remarks}
                    disabled={true}
                />
                <label className="ms-3 position-absolute" htmlFor="Additional Remarks">
                    Additional Remarks
                </label>
                    <p className="p-error">{errors?.additional_remarks || formErrors?.additional_remarks}</p>
            </div>
        </div>}

    </>
  );
};

export default IntimateClaim;
