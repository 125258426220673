import SideBar from "./SideBar";
import TopBar from "./TopBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSidebarToggle from "../hooks/useSidebarToggle";

const Layout = ({ children }) => {
  const { toogleSidebar, toggleClass, toggle } = useSidebarToggle();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    !localStorage.getItem("superUser") &&
      !localStorage.getItem("superUser") &&
      navigate("/login");
    location.pathname === "/login" &&
      localStorage.getItem("superUser") &&
      localStorage.getItem("superUser") &&
      navigate("/dashboard");
  }, [location.pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {location.pathname !== "/login" && (
        <>
          <TopBar toogleSidebar={toogleSidebar} toggle={toggle} />
          <SideBar
            toggleClass={toggleClass}
            toggle={toggle}
            toogleSidebar={toogleSidebar}
          />
        </>
      )}
      <main
        className={`${
          location.pathname !== "/login" ? "main_section" : "vh-100 vw-100"
        } container-fluid p-4`}
      >
        {children}
      </main>
    </>
  );
};

export default Layout;
