import { FETCH_CLAIMINFO_FAILURE, FETCH_CLAIMINFO_REQUEST, FETCH_CLAIMINFO_SUCCESS } from "../actions/types";

const initialState = {
    data: {},
    error: ''
};

export const claimInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLAIMINFO_REQUEST:
            return {
                ...state,
            }
        case FETCH_CLAIMINFO_SUCCESS:
            return {
                data: action.payload
            }
        case FETCH_CLAIMINFO_FAILURE:
            return {
                data: {},
                error: action.payload
            }
        default:
            return state;
    }
}