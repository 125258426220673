import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../assets/img/x.png";
import { file, stringFormat } from "../utils/methods";
import Cow from ".././assets/img/cattle-images/Cow.png";
import Horse from ".././assets/img/cattle-images/Horse.png";
import Goat from ".././assets/img/cattle-images/Goat.png";
import Buffalo from ".././assets/img/cattle-images/Buffalo.png";
import ImagePlaceholder from "../assets/img/img_placeholder.png";
import CheckboxCircle from "../assets/img/checkbox-circle.svg"
import { deleteAnimalData, updateEarTagNo } from "../store/actions/personalDetails";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
const AnimalDetailsFrom = ({
  animalDetailsCount = 1,
  setAnimalDetailsCount = void 0,
  updateValue = void 0,
  personalDetail = [],
  showDelete = false,
  animalPhotoLoad = false,
  animalPhotoFile = "",
  animalGoodHealthLoad=false,
  animalGoodHealthFile= "",
  formErrors = [],
  errors = [],
  eduload = false,
  offersFile = "",
  editTag = false,
  setEditTag = void 0,
  hideEdit = false,

  uploadSingleFile = void 0,
  deleteFile = void 0,
  isDisabled = false,
  data = [],
}) => {
    useEffect(() => {
    data?.length && setAnimalDetailsCount && setAnimalDetailsCount(data?.length)
  }, [data])
  const updateEditTagArr = (idx) => {
    const temp = [...editTag]
    temp[idx]=true
    setEditTag(temp)
  }
  function isExistingValue(value,idx) {
    if (isDisabled && data?.[idx]?.animal_gender === value) {
      return true;
    }
    return false;
  }
   function isExistingValue1(value,idx) {
    if (isDisabled && data?.[idx]?.animal_PED === value) {
      return true;
    }
    return false;
  }
   function isExistingValue2(value,idx) {
    if (isDisabled && data?.[idx]?.animal_vacination === value) {
      return true;
    }
    return false;
  }
  const navigate = useNavigate();


  const animalLogos = [
    {
      key: 1,
      img: Cow,
      value: "Cow",
    },
    {
      key: 2,
      img: Horse,
      value: "Horse",
    },
    {
      key: 3,
      img: Goat,
      value: "Goat",
    },
    {
      key: 4,
      img: Buffalo,
      value: "Buffalo",
    },
  ];
  const updateTagNo = (preTag, index) => {
   const payload =  {
    prev_ear_tag_no:preTag ? preTag: "",
    ear_tag_no: personalDetail[`newEar_tag_no-${index}`] ? personalDetail[`newEar_tag_no-${index}`] : "" ,
    ear_tag_no_remark : personalDetail[`ear_tag_no_remark-${index}`] ? personalDetail[`ear_tag_no_remark-${index}`] : ""
    }

    updateEarTagNo(payload)
      
    .then((res) => {
 res?.status === 0 && toast.error(res?.message);
      if (res?.status === 1) {
      toast.success(res?.message);
        navigate("/cattle-insurance/all");
      }
    })
  }
 const deleteAnimal = (id) => {
      let Confrimtext = "Are you sure you want to delete this Animal Detail?"
    if (window.confirm(Confrimtext) != true) {
      return;
    }
    deleteAnimalData({id:id})
    .then((res) => {
 res?.status === 0 && toast.error(res?.message);
      if (res?.status === 1) {
        toast.success(res?.message);
        window.location.reload()
      }
    })
  }
  return (
    <>
       {!isDisabled && new Array(animalDetailsCount).fill(0).map((item, idx)=> {
         return <>
         <div key={idx}>
      <div className="d-flex flex-wrap">
        {animalLogos?.map(({ key, img, id, value }) => (
          <div
            style={{position:"relative"}}
            className={`brand-logo-wrapper flex-column d-flex justify-content-center align-items-center`}
            key={key}
            onClick={() => updateValue("animal_type-"+idx, {
              target: {
              value
            }})}
          >
            <img
              style={{ marginRight: "1.5rem" }}
              className={`img-group`}
              src={img}
            />
            <p style={{ marginRight: "1.5rem" }}>{value }</p>
             {personalDetail["animal_type-"+idx] === value && (
                                 <img src={CheckboxCircle} style={{position:"absolute", top:"0", right:"15px", width:"25px"}} />
                                )}
          </div>
        ))}
      </div>
     
             <div className="row row-cols-1 row-cols-lg-auto form setWidth" style={{ marginTop: "2rem" }} >
          <h6 style={{ marginRight: "2rem", marginBottom: "0", color:"#29166F", fontSize:"18px" }}>Gender</h6>
        <div className="col d-flex align-items-center align-items-center">
          {!isExistingValue("male",idx) ? (
            <input
              type="radio"
              id="male"
              name={"radio-group-gender_"+idx}
              className="me-3"
              value="male"
              onChange={(e) => updateValue("animal_gender-"+idx, e)}
              disabled={isDisabled}
              checked={personalDetail["animal_gender-"+idx]  === "male"}
              
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Male</span>
        </div>
        <div className="col d-flex align-items-center align-items-center">
          {!isExistingValue("female",idx) ? (
            <input
              type="radio"
              id="female"
              name={"radio-group-gender_"+idx}
              className="me-3"
              value="female"
              onChange={(e) => updateValue("animal_gender-"+idx, e)}
              checked={personalDetail["animal_gender-"+idx]  === "female"}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Female</span>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
          {!isDisabled ? (
            <select
              className="w-100"
              value={personalDetail["animal_age-"+idx]}
            
              onChange={(e) => updateValue("animal_age-"+idx,e)}
            >
             
                  <option value="">Select</option>
                  <option value={`<1`}>{`<1 Year`}</option>
                  {new Array(50).fill(0).map((item, idx) => {
                    return <option value={idx + 1} key={idx}>{`${idx+1} ${idx ? "Years" : "Year"}` }</option>
                  })}
            
            </select>
          ) : (
            <input
              className="w-100"
              value={stringFormat(data.age)}
              disabled={true}
            />
          )}
          <label className="ms-3 position-absolute" htmlFor="Age">
            Age
          </label>
          <p className="p-error">
          </p>
        </div>
        <div className="col position-relative">
          {!isDisabled ? (
            <select
              className="w-100"
              value={personalDetail["animal_color-"+idx]}
              onChange={(e) => updateValue("animal_color-"+idx,e)}
            >
                    <option value="">Select</option>
              <option value="black">Black</option>
              <option value="brown">Brown</option>
              <option value="white">White</option>
            
            </select>
          ) : (
            <input
              className="w-100"
              value={stringFormat(data.color)}
              disabled={true}
            />
          )}
          <label className="ms-3 position-absolute" htmlFor="Colour">
            Colour
          </label>
          <p className="p-error">
          </p>
        </div>
        <div className="col position-relative">
          {!isDisabled ? (
            <select
              className="w-100"
              value={personalDetail["animal_breed-"+idx]}
              onChange={(e) => updateValue("animal_breed-"+idx,e)}
            >
             
                     <option value="">Select</option>
              <option value="Exotic">Exotic</option>  
              <option value="Indigenous">Indigenous</option>  
              {/* );
                })} */}
            </select>
          ) : (
            <input
              className="w-100"
              value={stringFormat(data.breed)}
              disabled={true}
            />
          )}
          <label className="ms-3 position-absolute" htmlFor="Breed of animal">
            Breed of animal
          </label>
          <p className="p-error">
          </p>
        </div>
        {personalDetail["animal_gender-"+idx]  === "male" && personalDetail["animal_age-"+idx]>=3 && <div className="col position-relative">
         {!isDisabled ? (
            <select
              className="w-100"
              value={personalDetail["animal_purpose-"+idx]}
              onChange={(e) => updateValue("animal_purpose-"+idx,e)}
            >
              
              <option value="">Select</option>
              <option value="UsedForBreedingOnly">Used for breeding only</option>
              <option value="UsedForDraughtOnly">Used for draught only</option>
              <option value="UsedBothDraughtBreeding">Used both draught & Breeding</option>
              <option value="maleOthers">Others</option>
              
            </select>
          ) : (
            <input
              className="w-100"
              value={stringFormat(data.color)}
              disabled={true}
            />
          )}
          <label className="ms-3 position-absolute" htmlFor="animal_purpose">
            Purpose of animal
          </label>
          <p className="p-error">
          </p>
            </div>}
              {personalDetail["animal_gender-"+idx]  === "female" && personalDetail["animal_age-"+idx]>=4 && <div className="col position-relative">
         {!isDisabled ? (
            <select
              className="w-100"
              value={personalDetail["animal_purpose-"+idx]}
              onChange={(e) => updateValue("animal_purpose-"+idx,e)}
            >
             
              <option value="">Select</option>
              <option value="inMilk">In Milk</option>
              <option value="dry">Dry</option>
              <option value="NotClavedOnce">Not Claved Once</option>
              <option value="femaleOthers">Others</option>
           
            </select>
          ) : (
            <input
              className="w-100"
              value={stringFormat(data.color)}
              disabled={true}
            />
          )}
          <label className="ms-3 position-absolute" htmlFor="animal_purpose">
            Purpose of animal
          </label>
          <p className="p-error">
          </p>
        </div>}
        <div className="col position-relative">
          <input
            className="w-100"
            id="animal_sum_insured"
            type="number"
            name="animal_sum_insured"
            onChange={(e) => updateValue("animal_sum_insured-"+idx, e)}
            value={
              !isDisabled
                ? personalDetail["animal_sum_insured-"+idx]
                : stringFormat(data.animal_sum_insured)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Sum Insured">
            Sum Insured
          </label>
        </div>

        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name="ear_tag_no"
            onChange={(e) => updateValue("ear_tag_no-"+idx, e)}
            value={
              !isDisabled
                ? personalDetail["ear_tag_no-"+idx]
                : stringFormat(data.ear_tag_no)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Ear Tag No.">
            Ear Tag No.
          </label>
        </div>
      </div>
      <div className="row row-cols-2 row-cols-lg-auto form setWidth" style={{margin:"2rem 0"}}>
               <>
                  <h6 style={{ marginRight: "2rem", marginBottom: "5px", color:"#29166F", fontSize:"18px" }}>
            Vaccination ?{" "}
          </h6>
        <div className="col d-flex align-items-center">
         
          {!isExistingValue1("vaccination_yes",idx) ? (
            <input
              type="radio"
              id="vaccination_yes"
              name={"radio-group-vaccination-"+idx}
              className="me-3"
              value="vaccination_yes"
              onChange={(e) => updateValue("vaccination_status-" + idx, e)}
              disabled={isDisabled}
              checked={personalDetail["vaccination_status-" + idx]  === "vaccination_yes" }
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
        </div>
        <div className="col d-flex align-items-center">
          {!isExistingValue1("vaccination_no",idx) ? (
            <input
              type="radio"
              id="vaccination_no"
              name={"radio-group-vaccination-"+idx}
              className="me-3"
              value="vaccination_no"
              onChange={(e) => updateValue("vaccination_status-"+idx, e)}
              checked={personalDetail["vaccination_status-"+idx] === "vaccination_no"}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
              </div>
               {personalDetail["vaccination_status-"+idx] === "vaccination_yes" && <div className="col pedVac position-relative">
          <input
            className="w-100"
            id={"animal_vacination_details-" + idx}
            type="text"
            name={"animal_vacination_details-" + idx}
            onChange={(e) => updateValue("animal_vacination_details-"+idx, e)}
            value={
              !isDisabled
                ? personalDetail["animal_vacination_details-"+idx]
                : stringFormat(data.animal_vacination_details)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Vaccination Details">
            Vaccination Details
          </label>
        </div> }
        </>
        
      
      </div>
     <div className="row row-cols-2 row-cols-lg-auto form setWidth" style={{margin:"2rem 0"}}>
       <h6 style={{ marginRight: "2rem", marginBottom: "5px", color:"#29166F", fontSize:"18px" }}>
            Any PED ? {" "}
                </h6>
  <>
        <div className="col d-flex align-items-center">
          
          {!isExistingValue2("ped_yes",idx) ? (
            <input
              type="radio"
              id="ped_yes"
              name={"radio-group_ped-"+idx}
              className="me-3"
              value="ped_yes"
              onChange={(e) => updateValue("pedType-"+idx, e)}
              disabled={isDisabled}
              checked={personalDetail["pedType-"+idx] === "ped_yes" }
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
        </div>
        <div className="col d-flex align-items-center align-items-center">
          {!isExistingValue2("ped_no",idx) ? (
            <input
              type="radio"
              id="ped_no"
             name={"radio-group_ped-"+idx}
              className="me-3"
              value="ped_no"
              onChange={(e) => updateValue("pedType-"+idx, e)}
              checked={personalDetail["pedType-"+idx] === "ped_no"}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
              </div>
              {personalDetail["pedType-"+idx] === "ped_yes" &&  <div className="col position-relative pedVac">
          <input
            className="w-100"
            id={"animal_PED_details-"+idx}
            type="text"
            name={"animal_PED_details-"+idx}
            onChange={(e) => updateValue("animal_PED_details-"+idx, e)}
            value={
              !isDisabled
                ? personalDetail["animal_PED_details-"+idx]
                : stringFormat(data.animal_PED_details)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="PED">
            PED (Details)
          </label>
        </div> }
          </>
      
      </div> 
     <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3 mt-1">
        <div className="row form  d-flex flex-column">
          {!isDisabled && <label htmlFor="animal_photo" style={{color:"#29166F", fontSize:"18px", marginLeft:"10px"}}>Photograph</label>}
          <div>
            {!animalPhotoFile[idx]  &&
            !personalDetail[`animal_photo-${idx}`] &&
            !isDisabled ? (
              <div>
                <label>
                  {!animalPhotoFile[idx]  && animalPhotoLoad ? (
                    <div
                      className="Group"
                      src="images/not-found.png"
                      alt="alternative"
                    >
                      <span className="spinner-border spinner-border-lg"></span>
                    </div>
                  ) : (
                    <img
                      style={{ height: "190px" }}
                      src={ImagePlaceholder}
                      alt="alternative"
                    ></img>
                  )}
                  <input
                    type="file"
                    className="d-none"
                    size="60"
                    name="file"
                    disabled={animalPhotoFile.length === 5}
                    onChange={(e) => uploadSingleFile(e, "animal_photo-"+idx)}
                  />
                </label>
                {!animalPhotoFile[idx]  && (
                  <p className="p-error">
                    {errors.animalPhotoFile || formErrors.animalPhotoFile}
                  </p>
                )}
              </div>
            ) : (
              <div className="row form-group">
                {isDisabled && data[idx].animal_photo !== null && (
                  <div className="img-preview">
                    {data?.[idx].animal_photo?.includes(".pdf") == true ? (
                      <a
                        className="btn btn-primary"
                        href={file(data[idx].animal_photo)}
                        target="_blank"
                      >
                        Preview PDF File
                      </a>
                    ) : (
                      <LazyLoadImage
                        effect="blur"
                        src={file(data[idx].animal_photo)}
                        alt="preview"
                      />
                    )}
                  </div>
                )}
                {!isDisabled && (
                  <div className="img-preview">
                    {(
                      animalPhotoFile?.[idx] || personalDetail[`animal_photo-${idx}`]
                    )?.includes(".pdf") == true ? (
                      <a
                        className="btn btn-primary"
                        href={file(
                          animalPhotoFile[idx] || personalDetail[`animal_photo-${idx}`]
                        )}
                        target="_blank"
                      >
                        Preview PDF File
                      </a>
                    ) : (
                      <img
                        src={file(
                          animalPhotoFile?.[idx] || personalDetail[`animal_photo-${idx}`]
                        )}
                        alt="preview"
                      />
                    )}
                    <img
                      onClick={() => deleteFile(idx, "animal_photo")}
                      className="clear"
                      src={X}
                      alt="clear-preview"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row form  d-flex flex-column">
          {!isDisabled && (
            <label htmlFor="Good Health Certificate" style={{color:"#29166F", fontSize:"18px", marginLeft:"10px"}}>Good Health Certificate</label>
          )}
          <div>
            {!animalGoodHealthFile[idx]  &&
            !personalDetail[`animal_health_certificate-${idx}`] &&
            !isDisabled ? (
              <div>
                <label>
                  {!animalGoodHealthFile[idx] && animalGoodHealthLoad ? (
                    <div
                      className="Group"
                      src="images/not-found.png"
                      alt="alternative"
                    >
                      <span className="spinner-border spinner-border-lg"></span>
                    </div>
                  ) : (
                    <img
                      style={{ height: "190px" }}
                      src={ImagePlaceholder}
                      alt="alternative"
                    ></img>
                  )}
                  <input
                    type="file"
                    className="d-none"
                    size="60"
                    name="file"
                    disabled={animalGoodHealthFile.length === 5}
                    onChange={(e) => uploadSingleFile(e, "animal_health_certificate-"+idx)}
                  />
                </label>
                {!animalGoodHealthFile[idx]  && (
                  <p className="p-error">
                    {errors.animalGoodHealthFile || formErrors.animalGoodHealthFile}
                  </p>
                )}
              </div>
            ) : (
              <div className="row form-group">
                {isDisabled && data[idx].animal_health_certificate !== null && (
                  <div className="img-preview">
                    {data[idx].animal_health_certificate?.includes(".pdf") == true ? (
                      <a
                        className="btn btn-primary"
                        href={file(data[idx].animal_health_certificate)}
                        target="_blank"
                      >
                        Preview PDF File
                      </a>
                    ) : (
                      <LazyLoadImage
                        effect="blur"
                        src={file(data[idx].animal_health_certificate)}
                        alt="preview"
                      />
                    )}
                  </div>
                )}
                {!isDisabled && (
                  <div className="img-preview">
                    {(
                      animalGoodHealthFile?.[idx] || personalDetail[`animal_health_certificate-${idx}`]
                    )?.includes(".pdf") == true ? (
                      <a
                        className="btn btn-primary"
                        href={file(
                          animalGoodHealthFile[idx] || personalDetail[`animal_health_certificate-${idx}`]
                        )}
                        target="_blank"
                      >
                        Preview PDF File
                      </a>
                    ) : (
                      <img
                        src={file(
                          animalGoodHealthFile?.[idx] || personalDetail[`animal_health_certificate-${idx}`]
                        )}
                        alt="preview"
                      />
                    )}
                    <img
                      onClick={() => deleteFile(idx, "animal_health_certificate")}
                      className="clear"
                      src={X}
                      alt="clear-preview"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        </div>
           </div>
           <hr></hr>
         </>
       })}
      



      {isDisabled && data.map((item, idx) => {
         return <>
           <div key={idx}>
             <div className="d-flex flex-wrap justify-content-between">
      <div className="d-flex flex-wrap">
        {animalLogos?.map(({ key, img, id, value }) => (
          <div
            style={{position:"relative"}}
            className={`brand-logo-wrapper d-flex flex-column justify-content-center align-items-center`}
            key={key}
            onClick={() => updateValue("animal_type-"+idx, {
              target: {
              value
            }})}
          >
            <img
              style={{ marginRight: "1.5rem" }}
              className={`img-group`}
              src={img}
            />
             <p style={{ marginRight: "1.5rem" }}>{value }</p>
             {item?.animal_type === value && (
                                 <img src={CheckboxCircle} style={{position:"absolute", top:"0", right:"15px", width:"25px"}} />
                                )}
          </div>
        ))}
           
      </div>
      { showDelete==true && <Link to="#">
                <i
                  title="Delete Animal"
                  className="bi bi-trash p-1"
                   style={{ color: "#0a65ff", fontSize: "25px" }}
                   onClick={()=>deleteAnimal(item.id)}
                ></i> 
               </Link>}
               </div>
      <div className="row row-cols-1 row-cols-lg-auto form setWidth" style={{ marginTop: "2rem" }}>
          <h6 style={{ marginRight: "2rem", marginBottom: "0", color:"#29166F", fontSize:"18px" }}>Gender</h6>
        <div className="col d-flex align-items-center align-items-center">

          {!isExistingValue("male") ? (
            <input
              type="radio"
              id="male"
              name={"radio-group-gender_"+idx}
              className="me-3"
              value="male"
              onChange={(e) => updateValue("animal_gender-"+idx, e)}
              disabled={isDisabled}
              checked={item?.animal_gender === "male"}
              
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Male</span>
        </div>
        <div className="col d-flex align-items-center align-items-center">
          {!isExistingValue("female") ? (
            <input
              type="radio"
              id="female"
              name={"radio-group-gender_"+idx}
              className="me-3"
              value="female"
              onChange={(e) => updateValue("animal_gender-"+idx, e)}
              checked={item?.animal_gender  === "female"}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Female</span>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
      
            <input
              className="w-100"
              value={item?.animal_age}
              disabled={true}
            />
          <label className="ms-3 position-absolute" htmlFor="Age">
            Age
          </label>
        </div>
        <div className="col position-relative">
  
            <input
              className="w-100"
              value={item?.animal_color}
              disabled={true}
            />
          <label className="ms-3 position-absolute" htmlFor="Colour">
            Colour
          </label>
        </div>
        <div className="col position-relative">
            <input
              className="w-100"
              value={item?.animal_breed}
              disabled={true}
            />
          <label className="ms-3 position-absolute" htmlFor="Breed of animal">
            Breed of animal
          </label>
        </div>
        {item?.animal_gender === "male" && item?.animal_age>=3 && <div className="col position-relative">

            <input
              className="w-100"
               value={(item.animal_purpose)}
              disabled={true}
            />
          <label className="ms-3 position-absolute" htmlFor="animal_purpose">
            Purpose of animal
          </label>
          <p className="p-error">
          </p>
            </div>}
              {item?.animal_gender  === "female" && item?.animal_age>=4 && <div className="col position-relative">

            <input
              className="w-100"
              value={(item.animal_purpose)}
              disabled={true}
            />
        
          <label className="ms-3 position-absolute" htmlFor="animal_purpose">
            Purpose of animal
          </label>
          <p className="p-error">
          </p>
        </div>}
        <div className="col position-relative">
          <input
            className="w-100"
            id="animal_sum_insured"
            type="number"
            name="animal_sum_insured"
            onChange={(e) => updateValue("animal_sum_insured-"+idx, e)}
            value={
              !isDisabled
                ? personalDetail["animal_sum_insured-"+idx]
                : (item.animal_sum_insured)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Sum Insured">
            Sum Insured
          </label>
        </div>

        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name="ear_tag_no"
            onChange={(e) => updateValue("ear_tag_no-"+idx, e)}
            value={
              !isDisabled
                ? personalDetail["ear_tag_no-"+idx]
                : (item.ear_tag_no)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Ear Tag No.">
            Ear Tag No.
          </label>
               </div>
              
               {editTag[idx] ==true && <>
                  <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name="newEar_tag_no"
            onChange={(e) => updateValue("newEar_tag_no-"+idx, e)}
            value={
              !isDisabled
                ? personalDetail["newEar_tag_no-"+idx]
                : (item.newEar_tag_no)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="New Ear Tag No.">
            New Ear Tag No.
          </label>
                 </div> 
                          <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name="ear_tag_no_remark"
            onChange={(e) => updateValue("ear_tag_no_remark-"+idx, e)}
            value={
              isDisabled
                ? personalDetail["ear_tag_no_remark-"+idx]
                : (item.ear_tag_no_remark)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Ear Tag No. Remark">
            Ear Tag No. Remark
          </label>
                 </div> 
               </>}
               {hideEdit &&<>
                 <button className="btn btn-primary" style={{height:"45px", width:"115px", marginRight:"10px"}} onClick={() => updateEditTagArr(idx)}>Edit Tag No</button> 
                 {(editTag[idx] == true && personalDetail[`newEar_tag_no-${idx}`]) && <button style={{ height: "45px", width: "115px" }} className="btn btn-primary" onClick={() => updateTagNo(item.ear_tag_no, idx)}>Update Tag No</button>}
                </> }

      </div>
      <div className="row row-cols-2 row-cols-lg-auto form setWidth" style={{margin:"2rem 0"}}>
               <>
                  <h6 style={{ marginRight: "2rem", marginBottom: "5px", color:"#29166F", fontSize:"18px" }}>
            Vaccination ?{" "}
          </h6>
        <div className="col d-flex align-items-center">
         
          {!isExistingValue("vaccination_yes") ? (
            <input
              type="radio"
              id="vaccination_yes"
              name={"radio-group-vaccination-"+idx}
              className="me-3"
              value="vaccination_yes"
              onChange={(e) => updateValue("vaccination_status-" + idx, e)}
              disabled={isDisabled}
              checked={item?.animal_vacination==1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
        </div>
        <div className="col d-flex align-items-center">
          {!isExistingValue("vaccination_no") ? (
            <input
              type="radio"
              id="vaccination_no"
              name={"radio-group-vaccination-"+idx}
              className="me-3"
              value="vaccination_no"
              onChange={(e) => updateValue("vaccination_status-"+idx, e)}
              checked={item?.animal_vacination==0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
              </div>
               {item?.animal_vacination==1 && <div className="col position-relative pedVac">
          <input
            className="w-100"
            id={"animal_vacination_details-" + idx}
            type="text"
            name={"animal_vacination_details-" + idx}
            onChange={(e) => updateValue("animal_vacination_details-"+idx, e)}
            value={
              !isDisabled
                ? personalDetail["animal_vacination_details-"+idx]
                : stringFormat(item.animal_vacination_details)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Vaccination Details">
            Vaccination Details
          </label>
        </div> }
        </>
        
      
      </div>
     <div className="row row-cols-2 row-cols-lg-auto form setWidth" style={{margin:"2rem 0"}}>
       
               <>
                   <h6 style={{ marginRight: "2rem", marginBottom: "5px", color:"#29166F", fontSize:"18px" }}>
            Any PED ? {" "}
                </h6>
        <div className="col d-flex align-items-center">
        
          {!isExistingValue("ped_yes") ? (
            <input
              type="radio"
              id="ped_yes"
              name={"radio-group_ped-"+idx}
              className="me-3"
              value="ped_yes"
              onChange={(e) => updateValue("pedType-"+idx, e)}
              disabled={isDisabled}
              checked={item?.animal_PED==1}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Yes</span>
        </div>
        <div className="col d-flex align-items-center">
          {!isExistingValue("ped_no") ? (
            <input
              type="radio"
              id="ped_no"
             name={"radio-group_ped-"+idx}
              className="me-3"
              value="ped_no"
              onChange={(e) => updateValue("pedType-"+idx, e)}
              checked={item?.animal_PED==0}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>No</span>
              </div>
              {item?.animal_PED==1 &&  <div className="col position-relative pedVac">
          <input
            className="w-100"
            id={"animal_PED_details-"+idx}
            type="text"
            name={"animal_PED_details-"+idx}
            onChange={(e) => updateValue("animal_PED_details-"+idx, e)}
            value={
              !isDisabled
                ? personalDetail.animal_PED_details
                : stringFormat(item.animal_PED_details)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="PED">
            PED (Details)
          </label>
        </div> }
          </>
      
      </div> 
      <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3 mt-1">
               {item.animal_photo != null && <div className="row form  d-flex flex-column">
                 <label htmlFor="animal_photo" style={{ color: "#29166F", fontSize: "18px" }}>Photograph</label>
                 <div>
                   {!animalPhotoFile[idx] &&
                     !personalDetail.animalPhotoLoad &&
                     !isDisabled ? (
                     <div>
                       <label>
                         {!animalPhotoFile[idx] && animalPhotoLoad ? (
                           <div
                             className="Group"
                             src="images/not-found.png"
                             alt="alternative"
                           >
                             <span className="spinner-border spinner-border-lg"></span>
                           </div>
                         ) : (
                           <img
                             style={{ height: "190px" }}
                             src={ImagePlaceholder}
                             alt="alternative"
                           ></img>
                         )}
                         <input
                           type="file"
                           className="d-none"
                           size="60"
                           name="file"
                           disabled={animalPhotoFile.length === 5}
                           onChange={(e) => uploadSingleFile(e, "animal_photo-" + idx)}
                         />
                       </label>
                       {!animalPhotoFile[idx] && (
                         <p className="p-error">
                           {errors.animalPhotoFile || formErrors.animalPhotoFile}
                         </p>
                       )}
                     </div>
                   ) : (
                     <div className="row form-group">
                       {isDisabled && item?.animal_photo !== null && (
                         <div className="img-preview">
                           {item?.animal_photo?.includes(".pdf") == true ? (
                             <a
                               className="btn btn-primary"
                               href={file(item?.animal_photo)}
                               target="_blank"
                             >
                               Preview PDF File
                             </a>
                           ) : (
                             <LazyLoadImage
                               effect="blur"
                               src={file(item?.animal_photo)}
                               alt="preview"
                             />
                           )}
                         </div>
                       )}
                       {!isDisabled && (
                         <div className="img-preview">
                           {(
                             animalPhotoFile?.[idx] || personalDetail?.animalPhotoLoad
                           )?.includes(".pdf") == true ? (
                             <a
                               className="btn btn-primary"
                               href={file(
                                 animalPhotoFile[idx] || personalDetail?.animalPhotoLoad
                               )}
                               target="_blank"
                             >
                               Preview PDF File
                             </a>
                           ) : (
                             <img
                               src={file(
                                 animalPhotoFile?.[idx] || personalDetail?.animalPhotoLoad
                               )}
                               alt="preview"
                             />
                           )}
                           <img
                             onClick={() => deleteFile(idx, "animal_photo")}
                             className="clear"
                             src={X}
                             alt="clear-preview"
                           />
                         </div>
                       )}
                     </div>
                   )}
                 </div>
               </div>}
               {item.animal_health_certificate != null && <div className="row form col-12 d-flex flex-column">
                 <label htmlFor="Good Health Certificate" style={{ color: "#29166F", fontSize: "18px" }}>Good Health Certificate</label>
                 <div>
                   {!animalGoodHealthFile[idx] &&
                     !personalDetail.animalPhotoLoad &&
                     !isDisabled ? (
                     <div>
                       <label>
                         {!animalGoodHealthFile[idx] && animalGoodHealthLoad ? (
                           <div
                             className="Group"
                             src="images/not-found.png"
                             alt="alternative"
                           >
                             <span className="spinner-border spinner-border-lg"></span>
                           </div>
                         ) : (
                           <img
                             style={{ height: "190px" }}
                             src={ImagePlaceholder}
                             alt="alternative"
                           ></img>
                         )}
                         <input
                           type="file"
                           className="d-none"
                           size="60"
                           name="file"
                           disabled={animalGoodHealthFile.length === 5}
                           onChange={(e) => uploadSingleFile(e, "animal_health_certificate-" + idx)}
                         />
                       </label>
                       {!animalGoodHealthFile[idx] && (
                         <p className="p-error">
                           {errors.animalGoodHealthFile || formErrors.animalGoodHealthFile}
                         </p>
                       )}
                     </div>
                   ) : (
                     <div className="row form-group">
                       {isDisabled && item?.animal_health_certificate !== null && (
                         <div className="img-preview">
                           {item?.animal_health_certificate?.includes(".pdf") == true ? (
                             <a
                               className="btn btn-primary"
                               href={file(item?.animal_health_certificate)}
                               target="_blank"
                             >
                               Preview PDF File
                             </a>
                           ) : (
                             <LazyLoadImage
                               effect="blur"
                               src={file(item?.animal_health_certificate)}
                               alt="preview"
                             />
                           )}
                         </div>
                       )}
                       {!isDisabled && (
                         <div className="img-preview">
                           {(
                             animalGoodHealthFile?.[idx] || item?.animal_health_certificate
                           )?.includes(".pdf") == true ? (
                             <a
                               className="btn btn-primary"
                               href={file(
                                 animalGoodHealthFile[idx] || item?.animal_health_certificate
                               )}
                               target="_blank"
                             >
                               Preview PDF File
                             </a>
                           ) : (
                             <img
                               src={file(
                                 animalGoodHealthFile?.[idx] || item?.animal_health_certificate
                               )}
                               alt="preview"
                             />
                           )}
                           <img
                             onClick={() => deleteFile(idx, "animal_health_certificate")}
                             className="clear"
                             src={X}
                             alt="clear-preview"
                           />
                         </div>
                       )}
                     </div>
                   )}
                 </div>
               </div>}
        </div>
           </div>
           <hr></hr>
         </>
      })}
      {isDisabled==false && animalDetailsCount < 5 && <h6 style={{ cursor: "pointer" }} onClick={() => setAnimalDetailsCount(animalDetailsCount + 1)}>
        
       <button className="btn btn-primary d-block m-2 m-auto text-center" >
            Add Another Animal +
          </button>
      </h6>}
        
    </>
  );
};

export default AnimalDetailsFrom;
