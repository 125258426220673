import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { useParams, useNavigate, Link } from "react-router-dom"
import { listClaim, claimSearchData, getAdminRolesInfo } from "../store/actions/personalDetails";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { getClaimInfo } from "../store/actions/claimInfo";
import { cattleformat_date } from "../utils/methods";
import { useDispatch, useSelector } from "react-redux";
import Constant from "../utils/constant";
import xls from "../assets/img/xls.png"
import { getClaimManagementDetail } from "../store/actions/ClaimManagementDetails";
import ReactPaginate from "react-paginate";
import { ROLES } from "../utils/variables";

const ClaimDetails = () => {
    const [show, setShow] = useState(false);
    const [claimList, setClaimList] = useState([]);
    const [claimSearchList, setClaimSearchList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearched, setIsSearched] = useState(false);
    const [policyStartDate, setPolicyStartDate] = useState("");
    const [policyEndDate, setPolicyEndDate] = useState("");
    const [policyTagNo, setpolicyTagNo] = useState("");
  const List = useSelector((state) => state.claimManagementDetails.data.data);
    const total = useSelector((state) => state.claimManagementDetails.data.total);
    const dispatch = useDispatch();
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
  const totalPage = Math.ceil(total / 10);
  const [claimRole, setClaimRoles] = useState([])
  let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role

  useEffect(() => {
    let role_id = ROLES[role_name]
    if(role_id){
      getAdminRolesInfo(role_id)
      .then((res) => {
        if (res.status) {
          let claim = res.data.filter(obj => {
            return obj.module_name == "Claim Management";
          });
          setClaimRoles(claim)
        }
      })
    }
  }, [])

    useEffect(() => {
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [total]);

    useEffect(() => {
        dispatch(getClaimManagementDetail(currentPage, itemsPerPage))
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [currentPage, itemsPerPage])

    const paginationHandlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        // dispatch(getClaimManagementDetail(currentPage, itemsPerPage)).then()
    };
    useEffect(() => {
        setIsLoading(true)
        listClaim()
        .then((res) => {
            if (res?.status === 1) {
                setClaimList(res.data)
                setIsLoading(false)
            }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }, [])

    const handleOnSearch = () => {
        const payLoad = {
          ear_tag_no: policyTagNo ? policyTagNo : "",
          start_date: policyStartDate ? cattleformat_date(policyStartDate) : "",
          end_date: policyEndDate ? cattleformat_date(policyEndDate) : "",
        };
        claimSearchData(payLoad).then((res) => {
            if (res?.status === 1) {
                setClaimSearchList(res.data)
            }
            if (res?.status === 0) {
                setClaimSearchList([])
            }
        });
        setIsSearched(true);
      };
    
    const handleOnClearFilter = () => {
        setIsSearched(false);
        setpolicyTagNo("")
        setPolicyStartDate("");
        setPolicyEndDate("");
        setClaimSearchList(List)
    };
      useEffect(() => {
    setClaimSearchList(List)
  },[List,policyTagNo==""])

    claimSearchList?.sort((a, b) => (b.id) - (a.id));
const handleExportExcelData = () => {
    fetch(Constant.BASE_URL + `cattle/claimExcel`, { method: 'post' } )
     .then(response => {
       
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
          a.download = `Claim-Details.xlsx`;
					a.click();
				});
				//window.location.href = response.url;
		});
    } 
const ShowEntries = ({ totalPage, total, setItemsPerPage, itemsPerPage }) => {
   
  const temp = [];
  for (let i = 1; i <= totalPage; i++) {
    i === totalPage ? temp.push(total) : temp.push(10 * i);
  }
  return (
    <div className="d-flex justify-content-between">
    <div className="d-flex  col-lg-3 align-items-center mb-3 p-0">
      <span>Show</span>
      <select
        className="form-select ms-2 me-2"
        value={itemsPerPage}
        onChange={(e) => setItemsPerPage(e.target.value)}
      >
        {temp.map((d, i) => (
          <option value={d} key={Date.now() + i} defaultValue={itemsPerPage === d}>
            {d}
          </option>
        ))}
      </select>
      <span>Entries</span>
          </div>
      <div className="text-right" style={{cursor:"pointer"}}  onClick={()=>handleExportExcelData()} ><img style={{width:"50px", height:"50px", marginBottom:"10px"}} src={xls}></img></div>
          
</div>
  );
};
    if(isLoading) return <Loading />;

  return (
    <div className="pos cattle-insurance">
      <Header 
        title={"Claim Details"}
        btn={claimRole[0]?.create_action === 1 ? true : false}
        btnText="Intimate Claim"
        btnLink="/cattle-insurance/intimate-claim"
        btnClass="p-btn"
      />
      <>
        <div className="p-4 bg-white shadow rounded-3 mb-3">
            <div className="row gy-2 row-cols-1 row-cols-lg-4 d-flex justify-content-center">
                <div className="col p-2 me-2 pos_filter rounded">
                <label htmlFor="name">Ear Tag No.</label>
                <input
                type="text"
                name="name"
                placeholder="Ear Tag No."
                value={policyTagNo}
                onChange={(e) => {
                    setpolicyTagNo(e.target.value);
                }}
                />
            </div>
            <div className="col p-2 me-2 pos_filter rounded">
                <label htmlFor="name">Start Date</label>
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    placeholderText="dd-mm-yyyy"
                        onKeyDown={(e) => {
                        if(e.which == 8 || e.code == "Backspace" ||
                        e.which == 46 || e.code == "Delete") return
                        
                        e.preventDefault();
                    }}
                    selected={policyStartDate}
                    onChange={(date) => setPolicyStartDate(date)}
                />
            </div>
            <div className="col p-2 me-2 pos_filter rounded">
                <label htmlFor="name">End Date</label>
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    placeholderText="dd-mm-yyyy"
                        onKeyDown={(e) => {
                        if(e.which == 8 || e.code == "Backspace" ||
                        e.which == 46 || e.code == "Delete") return
                        
                        e.preventDefault();
                    }}
                    selected={policyEndDate}
                    onChange={(date) => setPolicyEndDate(date)}
                />
            </div>
            
            </div>
            <div className="d-flex justify-content-center mt-3">
            <button
                className="btn btn-sm pos_filter_btn primary_btn me-2"
                onClick={() => handleOnSearch()}
            >
                Search
            </button>
            <button
                className="btn btn-sm pos_filter_btn"
                onClick={handleOnClearFilter}
            >
                Clear Filter
            </button>
            </div>
        </div>
              <div className="p-4 bg-white shadow rounded-3">
                    <ShowEntries
        totalPage={totalPage}
        total={total}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={itemsPerPage}
      />
                  <div className="table-responsive">
      {/* <div className="text-right d-flex justify-content-end"><img onClick={()=>handleExportExcelData()} style={{width:"50px", height:"50px", marginBottom:"10px", cursor:"pointer"}} src={xls}></img></div> */}
                      
           <table className="table table-bordered table-striped" width="auto">
                <thead style={{ backgroundColor: "#edf5fb" }}>
                    <tr scope="col" className="text-uppercase">
                    <th scope="col" className="text-center">Intimated claims</th>
                    <th scope="col" className="text-center">Policy number</th>
                    <th scope="col" className="text-center">Ear Tag number</th>
                    <th scope="col" className="text-center">Claim reason</th>
                    <th scope="col" className="text-center">Date of Death/Accident/Theft</th>
                    <th scope="col" className="text-center">Status</th>
                    {((claimRole[0]?.view_action === 1) || (claimRole[0]?.edit_action === 1)) && <th scope="col" className="text-center">Actions</th>}
                    </tr>
                </thead>
               
                <tbody style={{fontFamily:"calibri"}}>
                        {claimSearchList?.length ? claimSearchList?.map((item, i) => (<tr>
                            <td className="text-center" key={i}>{item.claim_intimation_date}</td>
                            <td className="text-center">{item.policy_number}</td>
                            <td className="text-center">{item.ear_tag_no}</td>
                            <td className="text-center">{item.claim_reason}</td>
                            <td className="text-center">{item.date_of_happening}</td>
                            <td className="text-center">{item.claim_status}</td>
                            {((claimRole[0]?.view_action === 1) || (claimRole[0]?.edit_action === 1)) && <td className="text-center">
                                {claimRole[0]?.view_action === 1 && <Link to={`/claim/${item?.claim_number}/view`} onClick={getClaimInfo}>
                                <i
                                    title="View"
                                    className="bi bi-eye-fill p-1"
                                    style={{ color: "#0a65ff" }}
                                />
                                </Link>}
                                {claimRole[0]?.edit_action === 1 && <Link to={`/claim/${item?.claim_number}/edit`} onClick={getClaimInfo}>
                                <i
                                    title="Claim"
                                    className="bi bi-pencil-square p-1"
                                    style={{ color: "#0a65ff" }}
                                /> 
                                </Link>}
                            </td>}
                          
                        </tr>)) : 
                        <tr>
                            <td colSpan={7} style={{ textAlign: 'center' }}>No Data Found</td>
                        </tr>
                        }
                </tbody>
                      </table>
                         <div className="table_paginate">
                <ReactPaginate
                    containerClassName="pagination"
                    nextClassName="page-item"
                    pageClassName="page-item"
                    nextLabel="Next"
                    onPageChange={paginationHandlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    disableInitialCallback={true}
                    previousLabel="Previous"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
          </div>
      </div>
      </>
    </div>
  );
};

export default ClaimDetails
