import axios from "../../config/axios";
import {
  FETCH_UPDATEPOS_FAILURE,
  FETCH_UPDATEPOS_REQUEST,
  FETCH_UPDATEPOS_SUCCESS,
} from "./types";

export const fetchUpdatePosRequest = () => {
  return {
    type: FETCH_UPDATEPOS_REQUEST,
  };
};
export const fetchUpdatePosSuccess = (model) => {
  return {
    type: FETCH_UPDATEPOS_SUCCESS,
    payload: model,
  };
};
export const fetchUpdatePosFailure = (error) => {
  return {
    type: FETCH_UPDATEPOS_FAILURE,
    payload: error,
  };
};

export const getUpdatePos = (payload) => {
  return new Promise((resolve) => {
    axios
      .patch("admin/updatePos",
        payload
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
