import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../assets/img/x.png";
import { file, stringFormat } from "../utils/methods";

const CattleUsesDetailsFrom = ({
  updateValue = void 0,
  personalDetail = [],
  formErrors = [],
  errors = [],
  selectQualification = "",
  setSelectQualification = void 0,
  eduload = false,
  offersFile = "",
  uploadSingleFile = void 0,
  deleteFile = void 0,
  isDisabled = false,
  data = [],
}) => {
  const dispatch = useDispatch();
  const qualificationState = useSelector(
    (state) => state.posQualification.data.data
  );


  return (
    <>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
           <input
            className="w-100"
            id="offers"
            type="number"
            name="male_Details_yrs"
            onChange={(e) => updateValue("male_Details_yrs", e)}
            value={
              !isDisabled
                ? personalDetail.male_Details_yrs
                : stringFormat(data?.maledata?.maleanimalageupto2years)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="male_Details_yrs">
            Male Details (upto 2 years)*
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            name="breeding"
            onChange={(e) => updateValue("breeding", e)}
            value={
              !isDisabled
                ? personalDetail.breeding
                : stringFormat(data?.maledata?.UsedForBreedingOnly)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Used for breeding only">
            Used for breeding only
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            name="draught"
            onChange={(e) => updateValue("draught", e)}
            value={
              !isDisabled ? personalDetail.draught : data?.maledata?.UsedForDraughtOnly
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Used for draught only">
            Used for draught only
          </label>
        </div>

        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
           type="number"
            name="draught_breeding"
            onChange={(e) => updateValue("draught_breeding", e)}
            value={
              !isDisabled
                ? personalDetail.draught_breeding
                :  data?.maledata?.UsedBothDraughtBreeding
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Used both draught & Breeding">
            Used both draught & Breeding
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
           type="number"
            name="male_others"
            onChange={(e) => updateValue("male_others", e)}
            value={
              !isDisabled
                ? personalDetail.male_others
                :  data?.maledata?.maleOthers
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Others
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            name="total_male"
            onChange={(e) => updateValue("total_male", e)}
            value={
              !isDisabled
                ? personalDetail.total_male
                :  data?.maledata?.malecount
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Total Male">
            Total Male
          </label>
        </div>
      </div>
      <hr></hr>
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-1 pb-3 mt-0">
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            min={1}
            max={3}
            name="female_details_yrs"
            onChange={(e) => updateValue("female_details_yrs", e)}
            value={
              !isDisabled
                ? personalDetail.female_details_yrs
                : data?.femaledata?.femaleanimalageuptooneyears
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Female Details">
            Female Details (under 1 year)
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            min={1}
            max={3}
            name="one_to_three"
            onChange={(e) => updateValue("one_to_three", e)}
            value={
              !isDisabled
                ? personalDetail.one_to_three
                : data?.femaledata?.femaleanimalage1to3years
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="1 to 3 years">
            1 to 3 years
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            name="milk"
            onChange={(e) => updateValue("milk", e)}
            value={!isDisabled ? personalDetail.milk : data?.femaledata?.inMilk}
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="In Milk">
            In Milk
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            name="dry"
            onChange={(e) => updateValue("dry", e)}
            value={!isDisabled ? personalDetail.dry : data?.femaledata?.dry}
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Dry">
            Dry
          </label>
        </div>

        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            name="claved"
            onChange={(e) => updateValue("claved", e)}
            value={
              !isDisabled ? personalDetail.claved : data?.femaledata?.NotClavedOnce
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Not Claved Once">
            Not Claved Once
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
           type="number"
            name="female_others"
            onChange={(e) => updateValue("female_others", e)}
            value={
              !isDisabled
                ? personalDetail.female_others
                : data?.femaledata?.femaleOthers
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Others
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="number"
            name="total_female"
            onChange={(e) => updateValue("total_female", e)}
            value={
              !isDisabled
                ? personalDetail.total_female
                : data?.femaledata?.femalecount
            }
            disabled
          />
          <label className="ms-3 position-absolute" htmlFor="Total Female">
            Total Female
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
           type="number"
            name="total_animal"
            onChange={(e) => updateValue("total_animal", e)}
            value={
              !isDisabled
                ? personalDetail.total_animal
                : (data?.maledata?.malecount + data?.femaledata?.femalecount)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Total Animals">
            Total Animals
          </label>
        </div>
      </div>
      
    </>
  );
};

export default CattleUsesDetailsFrom;
