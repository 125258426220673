import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringFormat, file } from "../utils/methods";
import Select from "react-select";
import { getState } from "../store/actions/listStates";
import { getCity } from "../store/actions/city";
import { getPinCode } from "../store/actions/pinCode";
import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../assets/img/x.png";
import ImagePlaceholder from "../assets/img/img_placeholder.png";

const OwnerDetails = ({
  personalchk = void 0,
  personalDetail = [],
  formErrors = [],
  errors = [],
  selectOwnerState = "",
  setSelectOwnerState = void 0,
  selectOwnerCity = "",
  setSelectOwnerCity = void 0,
  selectOwnerPinCode = "",
  setSelectOwnerPinCode = void 0,
  AadhaarOwnerLoad = false,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  aadharFileOwner = "",
  isDisabled = false,
  data = [],
  isEdit = false
}) => {

    const [checked, setChecked] = useState(false)

    const listState = useSelector((state) => state.posState.data);
    const listCity = useSelector((state) => state.posCity.data);
    const listPinCode = useSelector((state) => state.posPinCode.data);
  
    const dispatch = useDispatch();
    
    useEffect(() => {
      if (selectOwnerPinCode !== "") {
      delete formErrors.pincode;
      }
    }, [selectOwnerState, selectOwnerCity, selectOwnerPinCode]);
    
    useEffect(() => {
        !isDisabled && dispatch(getState());
    }, []);

    const setState = (id) => {
        setSelectOwnerState(id);
        if (id === "") {
        setSelectOwnerCity("");
        setSelectOwnerPinCode("");
        }
        dispatch(getCity(id));
    };

    const setCity = (id) => {
        setSelectOwnerCity(id);
        if (id === "") {
        setSelectOwnerPinCode("");
        }
        dispatch(getPinCode(id));
    };

    const setPinCode = (id) => {
        setSelectOwnerPinCode(id);
    };

  return (
    <> 

      <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
            <div className="col d-flex align-items-center">
              {!isDisabled ? <>
                <span className="label-text">
                    Is Owner different from proposer?
                </span> &nbsp;
                <input
                    className="me-2"
                    type="checkbox"
                    onChange={(e) => {
                      setChecked(!checked);
                      personalchk("owner_proposer_same", e)
                    }}
                    value={personalDetail.owner_proposer_same}
                    checked={personalDetail.owner_proposer_same == 1 ? true : false}
                />
                </> :
                <>
                  <span className="label-text">
                    Is Owner different from proposer?
                  </span> &nbsp;
                  <input
                      className="me-2"
                      type="checkbox"
                      checked={data?.owner_proposer_same == 1 ? true : false}
                  />
                </>}
            </div>
            {/* {!checked && <p className="p-error">{formErrors.isChecked}</p>} */}
        </div>
      </div>

      {(personalDetail.owner_proposer_same !== "" && personalDetail.owner_proposer_same) ? <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            id="owner_aadhar"
            type="number"
            name="owner_aadhar"
            onChange={(e) => personalchk("owner_aadhar", e)}
            value={
              !isDisabled ? personalDetail.owner_aadhar : stringFormat(data.owner_aadhar)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Aadhaar No.
          </label>
          <p className="p-error">{errors?.owner_aadhar || formErrors?.owner_aadhar}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="owner_first_name"
            type="text"
            name="owner_first_name"
            onChange={(e) => personalchk("owner_first_name", e)}
            value={
              !isDisabled ? personalDetail.owner_first_name : stringFormat(data.owner_first_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Owner First Name
          </label>
          <p className="p-error">{errors?.owner_first_name || formErrors?.owner_first_name}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="owner_middle_name"
            type="text"
            name="owner_middle_name"
            onChange={(e) => personalchk("owner_middle_name", e)}
            value={
              !isDisabled ? personalDetail.owner_middle_name : stringFormat(data.owner_middle_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Owner Middle Name
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="owner_last_name"
            type="text"
            name="owner_last_name"
            onChange={(e) => personalchk("owner_last_name", e)}
            value={
              !isDisabled ? personalDetail.owner_last_name : stringFormat(data.owner_last_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Owner Last Name">
            Owner Last Name
          </label>
          <p className="p-error">{errors?.owner_last_name || formErrors?.owner_last_name}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name="offers"
            onChange={(e) => personalchk("owner_address", e)}
            value={
              !isDisabled ? personalDetail.owner_address : stringFormat(data.owner_address)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Owner Address
          </label>
          <p className="p-error">{errors?.owner_address || formErrors?.owner_address}</p>
        </div>
        <div className="col position-relative">
          {!isDisabled ? <>
          {isEdit ? <Select
              options={listState}
              isClearable={!isDisabled}
              // onChange={(e) => (e != null ? setState(e.value) : setState(""))}
              onChange={(e) => {
                setState(e.value)
                personalchk("owner_state", e)
              }}
              className="searchable-select"
              name="owner_state"
              defaultValue={{ label: personalDetail.owner_state, value: personalDetail.owner_state }}
          /> : <Select
              options={listState}
              isClearable={!isDisabled}
              // onChange={(e) => (e != null ? setState(e.value) : setState(""))}
              onChange={(e) => {
                setState(e.value)
                personalchk("owner_state", e)
              }}
              className="searchable-select"
              name="owner_state"
              value={personalDetail.owner_state}
          />}
          </> : <>
          <input
              className="w-100"
              value={stringFormat(data.owner_state)}
              disabled={true}
          />
          </>}
          <label className="ms-3 position-absolute" htmlFor="landmark">
          Select State
          </label>
          {selectOwnerState === "" && (
          <p className="p-error">{errors?.owner_state || formErrors?.owner_state}</p>
          )}
          </div>
      </div> : ''}

      {(personalDetail.owner_proposer_same !== "" && personalDetail.owner_proposer_same) ? <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
                {!isDisabled ? <>
                {isEdit ? <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectOwnerState === "" ? true : false}
                    // onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    onChange={(e) => {
                      setCity(e.value)
                      personalchk("owner_city", e)
                    }}
                    className="searchable-select"
                    name="owner_city"
                    defaultValue={{ label: personalDetail.owner_city, value: personalDetail.owner_city }}
                /> : <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectOwnerState === "" ? true : false}
                    // onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    onChange={(e) => {
                      setCity(e.value)
                      personalchk("owner_city", e)
                    }}
                    className="searchable-select"
                    name="owner_city"
                    value={personalDetail.owner_city}
                />}
                </> : <>
                <input
                    className="w-100"
                    value={stringFormat(data.owner_city)}
                    disabled={true}
                />
                </>}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select City
                </label>
                {selectOwnerState === "" && (
                <p className="p-error">{errors?.owner_city || formErrors?.owner_city}</p>
                )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? <>
                {isEdit ? <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectOwnerCity === "" ? true : false}
                    // onChange={(e) =>
                    // e != null ? setPinCode(e.value) : setPinCode("")
                    // }
                    onChange={(e) => {
                      setPinCode(e.value)
                      personalchk("owner_zipcode", e)
                    }}
                    className="searchable-select"
                    name="owner_zipcode"
                    defaultValue={{ label: personalDetail.owner_zipcode , value: personalDetail.owner_zipcode }}
                /> : <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectOwnerCity === "" ? true : false}
                    // onChange={(e) =>
                    // e != null ? setPinCode(e.value) : setPinCode("")
                    // }
                    onChange={(e) => {
                      setPinCode(e.value)
                      personalchk("owner_zipcode", e)
                    }}
                    className="searchable-select"
                    name="owner_zipcode"
                    value={personalDetail.owner_zipcode}
                />}
                </> : <>
                <input
                    className="w-100"
                    value={stringFormat(data.owner_zipcode)}
                    disabled={true}
                />
                </>}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select Pincode
                </label>
                {selectOwnerState === "" && (
                <p className="p-error">{errors?.owner_zipcode || formErrors?.owner_zipcode}</p>
                )}
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="owner_block"
                type="text"
                name="owner_block"
                onChange={(e) => personalchk("owner_block", e)}
                value={
                  !isDisabled ? personalDetail.owner_block : stringFormat(data.owner_block)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Block
              </label>
              <p className="p-error">{errors?.owner_block || formErrors?.owner_block}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="owner_village"
                type="text"
                name="owner_village"
                onChange={(e) => personalchk("owner_village", e)}
                value={
                  !isDisabled ? personalDetail.owner_village : stringFormat(data.owner_village)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Village
              </label>
              <p className="p-error">{errors?.owner_village || formErrors?.owner_village}</p>
            </div>
        </div> : ''
        }

      {(personalDetail.owner_proposer_same !== "" && personalDetail.owner_proposer_same) ? <div className="row form col-12 d-flex flex-column">
        {!isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px"}}>Upload Aadhaar File</label>}
        <div>
          {!aadharFileOwner.length > 0 &&
          !personalDetail.owner_aadhar_photo &&
          !isDisabled ? (
            <div>
              <label>
                {!aadharFileOwner.length && AadhaarOwnerLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="AadhaarOwner"
                  disabled={aadharFileOwner.length === 2}
                  onChange={(e) => uploadSingleFile(e, "AadhaarOwner")}
                />
              </label>
              {!aadharFileOwner.length > 0 && (
                <p className="p-error">
                  {errors.aadharFileOwner || formErrors.aadharFileOwner}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.owner_aadhar_photo !== null && (
                <div className="img-preview">
                  {data?.owner_aadhar_photo?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.owner_aadhar_photo)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.owner_aadhar_photo)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    aadharFileOwner?.[0] || personalDetail?.owner_aadhar_photo
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        aadharFileOwner[0] || personalDetail?.owner_aadhar_photo
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        aadharFileOwner?.[0] || personalDetail?.owner_aadhar_photo
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "AadhaarOwner")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div> : ''}


      {isDisabled && <>
      {(data.owner_proposer_same !== "" && data.owner_proposer_same) ? <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            id="owner_aadhar"
            type="number"
            name="owner_aadhar"
            onChange={(e) => personalchk("owner_aadhar", e)}
            value={
              !isDisabled ? data.owner_aadhar : stringFormat(data.owner_aadhar)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Aadhaar No.
          </label>
          <p className="p-error">{errors?.owner_aadhar || formErrors?.owner_aadhar}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="owner_first_name"
            type="text"
            name="owner_first_name"
            onChange={(e) => personalchk("owner_first_name", e)}
            value={
              !isDisabled ? data.owner_first_name : stringFormat(data.owner_first_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Owner First Name
          </label>
          <p className="p-error">{errors?.owner_first_name || formErrors?.owner_first_name}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="owner_middle_name"
            type="text"
            name="owner_middle_name"
            onChange={(e) => personalchk("owner_middle_name", e)}
            value={
              !isDisabled ? data.owner_middle_name : stringFormat(data.owner_middle_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Owner Middle Name
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="owner_last_name"
            type="text"
            name="owner_last_name"
            onChange={(e) => personalchk("owner_last_name", e)}
            value={
              !isDisabled ? data.owner_last_name : stringFormat(data.owner_last_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Owner Last Name">
            Owner Last Name
          </label>
          <p className="p-error">{errors?.owner_last_name || formErrors?.owner_last_name}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="offers"
            type="text"
            name="offers"
            onChange={(e) => personalchk("owner_address", e)}
            value={
              !isDisabled ? data.owner_address : stringFormat(data.owner_address)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Owner Address
          </label>
          <p className="p-error">{errors?.owner_address || formErrors?.owner_address}</p>
        </div>
        <div className="col position-relative">
          {!isDisabled ? (
          <Select
              options={listState}
              isClearable={!isDisabled}
              onChange={(e) => (e != null ? setState(e.value) : setState(""))}
              className="searchable-select"
              name="owner_state"
              // value={data.owner_state}
          />
          ) : (
          <input
              className="w-100"
              value={stringFormat(data.owner_state)}
              disabled={true}
          />
          )}
          <label className="ms-3 position-absolute" htmlFor="landmark">
          Select State
          </label>
          {selectOwnerState === "" && (
          <p className="p-error">{errors?.owner_state || formErrors?.owner_state}</p>
          )}
          </div>
      </div> : ''}

      {(data.owner_proposer_same !== "" && data.owner_proposer_same) ? <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectOwnerState === "" ? true : false}
                    onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    className="searchable-select"
                    name="owner_city"
                    // value={data.owner_city}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormat(data.owner_city)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select City
                </label>
                {selectOwnerState === "" && (
                <p className="p-error">{errors?.owner_city || formErrors?.owner_city}</p>
                )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectOwnerCity === "" ? true : false}
                    onChange={(e) =>
                    e != null ? setPinCode(e.value) : setPinCode("")
                    }
                    className="searchable-select"
                    name="owner_zipcode"
                    // value={data.owner_zipcode}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormat(data.owner_zipcode)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select Pincode
                </label>
                {selectOwnerState === "" && (
                <p className="p-error">{errors?.owner_zipcode || formErrors?.owner_zipcode}</p>
                )}
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="owner_block"
                type="text"
                name="owner_block"
                onChange={(e) => personalchk("owner_block", e)}
                value={
                  !isDisabled ? personalDetail.owner_block : stringFormat(data.owner_block)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Block
              </label>
              <p className="p-error">{errors?.owner_block || formErrors?.owner_block}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="owner_village"
                type="text"
                name="owner_village"
                onChange={(e) => personalchk("owner_village", e)}
                value={
                  !isDisabled ? personalDetail.owner_village : stringFormat(data.owner_village)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Village
              </label>
              <p className="p-error">{errors?.owner_village || formErrors?.owner_village}</p>
            </div>
        </div> : ''
        }

      {(data.owner_proposer_same !== "" && data.owner_proposer_same) ? <div className="row form col-12 d-flex flex-column">
        {!isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px"}}>Upload Aadhar File</label>}
        <div>
          {!aadharFileOwner.length > 0 &&
          !data.owner_aadhar_photo &&
          !isDisabled ? (
            <div>
              <label>
                {!aadharFileOwner.length && AadhaarOwnerLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="AadhaarOwner"
                  disabled={aadharFileOwner.length === 2}
                  onChange={(e) => uploadSingleFile(e, "AadhaarOwner")}
                />
              </label>
              {!aadharFileOwner.length > 0 && (
                <p className="p-error">
                  {errors.aadharFileOwner || formErrors.aadharFileOwner}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.owner_aadhar_photo !== null && (
                <div className="img-preview">
                  {data?.owner_aadhar_photo?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.owner_aadhar_photo)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.owner_aadhar_photo)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    aadharFileOwner?.[0] || data?.owner_aadhar_photo
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        aadharFileOwner[0] || data?.owner_aadhar_photo
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        aadharFileOwner?.[0] || data?.owner_aadhar_photo
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "AadhaarOwner")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div> : ''}
      </>}


    </>
  );
};

export default OwnerDetails;
