import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { getCattleInfo } from "../store/actions/cattleInfo";
import { format_date } from "../utils/methods";
import { Link, Navigate } from "react-router-dom";
import xls from "../assets/img/xls.png"
import { deleteRowsCattle, exportExcelData } from "../store/actions/personalDetails";
import { toast } from "react-toastify";
import Constant from "../utils/constant";
const colorCode = [
  {
    text: "Pending",
    class: "bg-warning",
  },
  {
    text: "Issued",
    class: "bg-success text-white",
  },
  {
    text: "Canceled",
    class: "bg-danger text-white",
  },
  {
    text: "policy_issuance_status",
    class: "bg-info text-white",
  },
  {
    text: "On Hold",
    class: "bg-primary text-white",
  },
   {
    text: "hold",
    class: "bg-primary text-white",
  },
];

const TableHead = ({ cattleRole }) => {
  let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role;
  console.log('role_name', role_name)
  return (
    <thead style={{ backgroundColor: "#edf5fb" }}>
      <tr className="text-uppercase" style={{fontSize:"13px"}}>
        <th scope="col">Proposer name</th>
        <th scope="col">Email ID</th>
        <th scope="col">Owner Name</th>
        <th scope="col">State</th>
        <th scope="col">Policy Issuance Date</th>
        <th scope="col">Policy Issuance status</th>
        <th scope="col">Case ID</th>
        {((cattleRole?.delete_action === 1) || (cattleRole?.view_action === 1) || (cattleRole?.edit_action === 1)) && <th scope="col" className="text-center">ACTIONS</th>}
        {role_name=="Surveyor" && <th scope="col" className="text-center">ACTIONS</th>}
      </tr>
    </thead>
  );
};

function getClassName(text) {
  var classs = colorCode.find((c) => {
    return c.text === text && c.class;
  });
  return `badge rounded-pill text-dark ${classs && classs.class}`;
}

const TableBody = ({ data, cattleRole, isHideEditDelete }) => {
    let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role;
  const deleteRowEntry = (id, customer_ID) => {
      let Confrimtext = "Are you sure you want to delete this entry!"
    if (window.confirm(Confrimtext) != true) {
      return;
    }
    deleteRowsCattle({case_ID:id, customer_ID:customer_ID})
      
    .then((res) => {
 res?.status === 0 && toast.error(res?.message);
      if (res?.status === 1) {
        toast.success(res?.message);
        window.location.reload()
      }
    })
  }
  return (
    data && (
      <tbody style={{fontFamily:"calibri"}}>
        {data?.map((d, i) => (
          <tr key={Date.now() + i}>
            <td >
              {d?.proposer_name ? d.proposer_name : "-"}
            </td>
            <td >
              {d?.proposer_email ? d.proposer_email : "-"}
            </td>
   
            <td >
              {d?.owner_name ? d.owner_name : "-"}
            </td>
            <td >
              {d?.proposer_state ? d.proposer_state : "-"}
            </td>
            <td >
                {d?.policy_issuance_date ? (d.policy_issuance_date) : "-"}
            </td>
              <td  style={{fontFamily:"ErasDemiITC", fontSize:"1.2rem"}}>
              {d?.policy_issuance_status ? (
                <span className={getClassName(d.policy_issuance_status)}>{d.policy_issuance_status}</span>
              ) : (
                "-"
              )}
            </td>
            <td >
                {d?.case_ID ? (d.case_ID) : "-"}
            </td>
            {((cattleRole?.delete_action === 1) || (cattleRole?.view_action === 1) || (cattleRole?.edit_action === 1)) && <td className="text-center" width={'130px'}>
              {cattleRole?.view_action ? <Link to={`/cattle/${d?.case_ID}/view`} onClick={getCattleInfo}>
                <i
                  title="View"
                  className="bi bi-eye-fill p-1"
                  style={{ color: "#0a65ff" }}
                ></i>
              </Link> : ''}
              
              {cattleRole?.edit_action ? isHideEditDelete && <span>
              {d?.policy_issuance_status=="Issued" && <Link to={`/cattle/${d?.case_ID}/edit`}>
                <i
                  title="Endorsement"
                  className="bi bi-pencil-square p-1"
                  style={{ color: "#0a65ff" }}
              ></i> 
                </Link> } 
              </span> : ''}
              {cattleRole?.delete_action ?  isHideEditDelete && <Link to="#">
                <i
                  title="Delete"
                  className="bi bi-trash p-1"
                  style={{ color: "#0a65ff" }}
                  onClick={()=>deleteRowEntry(d?.case_ID, d?.customer_ID)}
                ></i> 
              </Link>: ''}
            </td>}

            {role_name == "Surveyor" ? <td className="text-center" width={'130px'}>
              <Link to={`/cattle/${d?.case_ID}/view`} onClick={getCattleInfo}>
                <i
                  title="View"
                  className="bi bi-eye-fill p-1"
                  style={{ color: "#0a65ff" }}
                ></i>
              </Link></td> : ''}
          </tr>
        ))}
      </tbody>
    )
  );
};

const NoDataTableBody = () => {
  return (
    <tbody>
      <tr>
        <td className="text-center" colSpan={8}>
          No Data Found.
        </td>
      </tr>
    </tbody>
  );
};

const ShowEntries = ({ totalPage, total, setIPP, ipp, isExcel }) => {
  const handleExportExcelData = () => {
      const created_by = JSON?.parse?.(localStorage?.getItem("superUser"))?.email
    fetch(Constant.BASE_URL + `cattle/earTagExcel/?created_by=${created_by}`, { method: 'post' } )
     .then(response => {
       
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
          a.download = `Cattle-Insurance-Deatils.xlsx`;
					a.click();
				});
				//window.location.href = response.url;
		});
  }
  const temp = [];
  for (let i = 1; i <= totalPage; i++) {
    i === totalPage ? temp.push(total) : temp.push(10 * i);
  }
  return (
    <div className="d-flex justify-content-between">
    <div className="d-flex  col-lg-3 align-items-center mb-3 p-0">
      <span>Show</span>
      <select
        className="form-select ms-2 me-2"
        value={ipp}
        onChange={(e) => setIPP(e.target.value)}
      >
        {temp.map((d, i) => (
          <option value={d} key={Date.now() + i} defaultValue={ipp === d}>
            {d}
          </option>
        ))}
      </select>
      <span>Entries</span>
    </div>
    {isExcel && <div className="text-right" style={{cursor:"pointer"}}  onClick={()=>handleExportExcelData()} ><img style={{width:"50px", height:"50px", marginBottom:"10px"}} src={xls}></img></div>}
</div>
  );
};

const POSDetailsTable = ({
  data = null,
  total = 0,
  itemsPerPage = 0,
  setItemsPerPage = void 0,
  paginationHandlePageClick = void 0,
  paginationPageCount = 0,
  cattleRole,
  isExcel = true,
  isHideEditDelete = true
}) => {
  const [ipp, setIPP] = useState(10);
  const totalPage = Math.ceil(total / 10);
  useEffect(() => {
    setItemsPerPage(ipp);
  }, [ipp, itemsPerPage]);
  return (
    <>
      <ShowEntries
        totalPage={totalPage}
        total={total}
        setIPP={setIPP}
        ipp={ipp}
        isExcel={isExcel}
      />
      <div className="table-responsive">
        <table className="table table-bordered table-striped" width="auto">
          <TableHead cattleRole={cattleRole} />
          {data ? <TableBody data={data} cattleRole={cattleRole} isHideEditDelete={isHideEditDelete} /> : <NoDataTableBody />}
        </table>
      </div>
      <div className="table_paginate">
        <ReactPaginate
          containerClassName="pagination"
          nextClassName="page-item"
          pageClassName="page-item"
          nextLabel="Next"
          onPageChange={paginationHandlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={paginationPageCount}
          previousLabel="Previous"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default POSDetailsTable;
