import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { useParams, useNavigate } from "react-router-dom"
import { listEndorsement, deleteEndorsementData, getAdminRolesInfo } from "../store/actions/personalDetails";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import xls from "../assets/img/xls.png"
import Constant from "../utils/constant";
import axios from "../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "../utils/variables";
import ReactPaginate from "react-paginate";
import { getEndorsementManagementDetail } from "../store/actions/EndorsementManagementDetails";

const EndorsementDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [cattleEndorsementList, setCattleEndorsementList] = useState();
  const [searchInput, setSearchInput] = useState('');
  const List = useSelector((state) => state.endorsementManagementDetails.data.data);
    const total = useSelector((state) => state.endorsementManagementDetails.data.total);
    const dispatch = useDispatch();
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
   const totalPage = Math.ceil(total / 10);
   const [endorsementRole, setEndorsementRoles] = useState([])
  let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role
  
  useEffect(() => {
    let role_id = ROLES[role_name]
    if(role_id){
      getAdminRolesInfo(role_id)
      .then((res) => {
        if (res.status) {
          let endorsement = res.data.filter(obj => {
            return obj.module_name == "Endorsement";
          });
          setEndorsementRoles(endorsement)
        }
      })
    }
  }, [])

    useEffect(() => {
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [total]);

    useEffect(() => {
        dispatch(getEndorsementManagementDetail(currentPage, itemsPerPage))
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [currentPage, itemsPerPage])

    const paginationHandlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        // dispatch(getEndorsementManagementDetail(currentPage, itemsPerPage)).then()
    };
    useEffect(() => {
        listEndorsement()
        .then((res) => {
            if (res?.status === 1) {
                setCattleEndorsementList(res.data)
            }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }, [searchInput==''])

    useEffect(() => {
        setTimeout(() => {
        setShow(true);
        }, 500);
    }, [])
  useEffect(() => {
    setCattleEndorsementList(List)
  },[List])
 const deleteEndorsement = (id) => {
      let Confrimtext = "Are you sure you want to delete this Endorsement?"
    if (window.confirm(Confrimtext) != true) {
      return;
    }
    deleteEndorsementData({endorsement_ID:id})
    .then((res) => {
 res?.status === 0 && toast.error(res?.message);
      if (res?.status === 1) {
        toast.success(res?.message);
         setTimeout(() => {
        window.location.reload()
        }, 900);
      }
    })
  }
  const clearResult = () => {
    setSearchInput('')
  }

  const searchByPolicyNumber = () => {
    if(searchInput=='') return   toast.error("Enter Policy Number");
    if (searchInput !== '') {
      axios.post(Constant.BASE_URL + 'cattle/searchEndorsement', { policy_number: searchInput })
        .then(response => {
          if (response?.data?.status == 0) {
            toast.error("Policy Number Not Found")
          }
          if (response?.data?.status == 1) {
            setCattleEndorsementList(response?.data?.data)
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }    
    }
  const handleExportExcelData = () => {
    fetch(Constant.BASE_URL + `cattle/endorsementExcel`, { method: 'post' } )
     .then(response => {
       
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
          a.download = `Endorsement-Details.xlsx`;
					a.click();
				});
		});
  }
  const ShowEntries = ({ totalPage, total, setItemsPerPage, itemsPerPage }) => {
   
  const temp = [];
  for (let i = 1; i <= totalPage; i++) {
    i === totalPage ? temp.push(total) : temp.push(10 * i);
  }
  return (
    <div className="d-flex justify-content-between">
    <div className="d-flex  col-lg-3 align-items-center mb-3 p-0">
      <span>Show</span>
      <select
        className="form-select ms-2 me-2"
        value={itemsPerPage}
        onChange={(e) => setItemsPerPage(e.target.value)}
      >
        {temp.map((d, i) => (
          <option value={d} key={Date.now() + i} defaultValue={itemsPerPage === d}>
            {d}
          </option>
        ))}
      </select>
      <span>Entries</span>
          </div>
      <div className="text-right" style={{cursor:"pointer"}}  onClick={()=>handleExportExcelData()} ><img style={{width:"50px", height:"50px", marginBottom:"10px"}} src={xls}></img></div>
          
</div>
  );
};
  cattleEndorsementList?.sort((a, b) => (b.endorsement_ID) - (a.endorsement_ID));
  return (
    <div className="pos cattle-insurance">
      <Header title={"Endorsement Details"} />
 {/* <div className="row row-cols-1 row-cols-lg-2 w-50 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            type="text"
            onChange={(e) => {
                setSearchInput(e.target.value)
            }}
            value={searchInput}
            name="policy_number_search"
            placeholder="Search by Policy Number"
          />
          <label className="ms-3 position-absolute" htmlFor="policy_number_search">
            Policy Number Search
          </label>
        </div>
        <div style={{ width: '20%' }}>
          <button style={{height:"45px", width:"115px", fontSize: '16px', marginTop: '5px'}} className="btn btn-primary" onClick={()=>searchByPolicyNumber()}>Search</button>
              </div>
          {searchInput && <div style={{ width: '20%' }}>
          <button style={{height:"45px", width:"115px", fontSize: '16px', marginTop: '5px', marginLeft:"10px"}} className="btn btn-primary" onClick={()=>clearResult()}>Clear</button>
        </div> }
      </div> */}
       <div className="p-4 bg-white shadow rounded-3 mb-3">
            <div className="row gy-2 row-cols-1 row-cols-lg-4 d-flex justify-content-center">
                <div className="col p-2 me-2 pos_filter rounded">
                <label htmlFor="name">Policy Number</label>
                <input
                type="text"
                name="policy_number_search"
                placeholder="Search by Policy Number"
                value={searchInput}
                onChange={(e) => {
                    setSearchInput(e.target.value)
                }}
                />
            </div>
           
            
            </div>
            <div className="d-flex justify-content-center mt-3">
            <button
                className="btn btn-sm pos_filter_btn primary_btn me-2"
                onClick={()=>searchByPolicyNumber()}
            >
                Search
            </button>
            <button
                className="btn btn-sm pos_filter_btn"
                onClick={()=>clearResult()}
            >
                Clear Filter
            </button>
            </div>
        </div>
      {show ? <div className="p-4 bg-white shadow rounded-3">
        
               <ShowEntries
        totalPage={totalPage}
        total={total}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={itemsPerPage}
      />
        <div className="table-responsive">
          
           <table className="table table-bordered table-striped" width="auto">
                <thead style={{ backgroundColor: "#edf5fb" }}>
                    <tr scope="col" className="text-uppercase" style={{fontSize:"13px"}}>
                    <th scope="col" className="text-center">Endorsement Id</th>
                    <th scope="col" className="text-center">Policy number</th>
                    <th scope="col" className="text-center">Endorsement number</th>
                    <th scope="col" className="text-center">Endorsement Timestamp</th>
                    <th scope="col" className="text-center">Endorsed remark</th>
                    {endorsementRole[0]?.delete_action === 1 && <th scope="col" className="text-center">Actions</th>}
                    </tr>
                </thead>
                <tbody style={{fontFamily:"calibri"}}>
                        {cattleEndorsementList?.length ? cattleEndorsementList?.map((item, i) => (<tr>
                            <td className="text-center" key={i}>{item.endorsement_ID}</td>
                            <td className="text-center">{item.policy_number}</td>
                            <td className="text-center">{item.endorsement_number}</td>
                            <td className="text-center">{item.timeStamp}</td>
                            <td className="text-center">{item.endorsement_remark}</td>
                            
                                {endorsementRole[0]?.delete_action === 1 && <td>
                                  <button className="btn btn-danger" style={{lineHeight:"0.75"}} onClick={()=>deleteEndorsement(item.endorsement_ID)}>
                                    Delete
                                  </button>
                                </td>}
                          
                        </tr>)) : <p style={{position:"absolute", left:"40%", marginTop:"10px"}}>No Data Found.</p>}
                </tbody>
          </table>
                    <div className="table_paginate">
                <ReactPaginate
                    containerClassName="pagination"
                    nextClassName="page-item"
                    pageClassName="page-item"
                    nextLabel="Next"
                    onPageChange={paginationHandlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    disableInitialCallback={true}
                    previousLabel="Previous"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
          </div>
      </div> : <Loading />}
    </div>
  );
};

export default EndorsementDetails;
