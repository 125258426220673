import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringFormat } from "../utils/methods";
import Select from "react-select";
import { getState } from "../store/actions/listStates";
import { getCity } from "../store/actions/city";
import { getPinCode } from "../store/actions/pinCode";

const ContactDetailsForm = ({
  personalchk = void 0,
  handleblue = void 0,
  formErrors = [],
  personalDetail = [],
  errors = [],
  selectState = "",
  setSelectState = void 0,
  selectCity = "",
  setSelectCity = void 0,
  selectPinCode = "",
  setSelectPinCode = void 0,
  isDisabled = false,
  data = [],
}) => {
  const listState = useSelector((state) => state.posState.data);
  const listCity = useSelector((state) => state.posCity.data);
  const listPinCode = useSelector((state) => state.posPinCode.data);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectPinCode !== "") {
      delete formErrors.pincode;
    }
  }, [selectState, selectCity, selectPinCode]);
  useEffect(() => {
    !isDisabled && dispatch(getState());
  }, []);

  const setState = (id) => {
    setSelectState(id);
    if (id === "") {
      setSelectCity("");
      setSelectPinCode("");
    }
    dispatch(getCity(id));
  };

  const setCity = (id) => {
    setSelectCity(id);
    if (id === "") {
      setSelectPinCode("");
    }
    dispatch(getPinCode(id));
  };

  const setPinCode = (id) => {
    setSelectPinCode(id);
  };

  return (
    <>
      <div className="row row-cols-1 row-cols-lg-2 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
          <textarea
            className="w-100"
            id="address1"
            type="text"
            name="address1"
            rows="3"
            onChange={(e) => personalchk("addressLine1", e)}
            value={
              !isDisabled
                ? personalDetail.addressLine1
                : stringFormat(data.addressLine1)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="address_line_1">
            Address Line 1*
          </label>
          <p className="p-error">
            {errors?.addressLine1 || formErrors?.addressLine1}
          </p>
        </div>
        <div className="col position-relative">
          <textarea
            className="w-100"
            id="address2"
            type="text"
            name="address2"
            rows="3"
            onBlur={handleblue}
            onChange={(e) => personalchk("addressLine2", e)}
            value={
              !isDisabled
                ? personalDetail.addressLine2
                : stringFormat(data.addressLine2)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="address_line_2">
            Address Line 2
          </label>
          <p className="p-error">
            {errors?.addressLine2 || formErrors?.addressLine2}
          </p>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-2 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            id="work"
            type="text"
            name="work"
            onChange={(e) => personalchk("landmark", e)}
            value={
              !isDisabled ? personalDetail.landmark : stringFormat(data.landmark)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="landmark">
            Landmark
          </label>
          <p className="p-error">{errors?.landmark || formErrors?.landmark}</p>
        </div>
        <div className="col position-relative">
          {!isDisabled ? (
            <Select
              options={listState}
              isClearable={!isDisabled}
              onChange={(e) => (e != null ? setState(e.value) : setState(""))}
              className="searchable-select"
            />
          ) : (
            <input
              className="w-100"
              value={stringFormat(data.state)}
              disabled={true}
            />
          )}
          <label className="ms-3 position-absolute" htmlFor="landmark">
            Select State
          </label>
          {selectState === "" && (
            <p className="p-error">{errors?.state || formErrors?.state}</p>
          )}
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-2 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          {!isDisabled ? (
            <Select
              options={listCity}
              isClearable={true}
              isDisabled={selectState === "" ? true : false}
              onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
              className="searchable-select"
            />
          ) : (
            <input
              className="w-100"
              value={stringFormat(data.city)}
              disabled={true}
            />
          )}
          <label className="ms-3 position-absolute" htmlFor="landmark">
            Select City
          </label>
          {selectState === "" && (
            <p className="p-error">{errors?.state || formErrors?.state}</p>
          )}
        </div>
        <div className="col position-relative">
          {!isDisabled ? (
            <Select
              options={listPinCode}
              isClearable={true}
              isDisabled={selectCity === "" ? true : false}
              onChange={(e) =>
                e != null ? setPinCode(e.value) : setPinCode("")
              }
              className="searchable-select"
            />
          ) : (
            <input
              className="w-100"
              value={stringFormat(data.pincode)}
              disabled={true}
            />
          )}
          <label className="ms-3 position-absolute" htmlFor="landmark">
            Select Pincode
          </label>
          {selectState === "" && (
            <p className="p-error">{errors?.state || formErrors?.state}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactDetailsForm;
