import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import ShareDetails from "../components/ShareDetails";
import { useParams, useNavigate } from "react-router-dom"
import { addCattleInsurer, addCattleUpdateInsurer, addCattleDeleteInsurer, getAdminRolesInfo } from "../store/actions/personalDetails";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import xls from "../assets/img/xls.png"
import Constant from "../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import {getShareManagementDetail} from "../store/actions/ShareManagementDetails"
import ReactPaginate from "react-paginate";
import { ROLES } from "../utils/variables";

const AddCattleInfo = () => {
    const cattleInsurerList = useSelector((state) => state.shareManagementDetails.data.data);
  const total = useSelector((state) => state.shareManagementDetails.data.total);
   const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [insurerDetails, setInsurerDetails] = useState({
        id: "",
        insurer_name: "",
        insurer_region: "",
        insurer_category: "",
        central_share:"",
        state_share:"",
        benficiary_share:""
    })
      const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
  const totalPage = Math.ceil(total / 10);
  const [shareRole, setShareRoles] = useState([])
  let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role

  useEffect(() => {
    let role_id = ROLES[role_name]
    if(role_id){
      getAdminRolesInfo(role_id)
      .then((res) => {
        if (res.status) {
          let share = res.data.filter(obj => {
            return obj.module_name == "Share Details";
          });
          setShareRoles(share)
        }
      })
    }
  }, [])

    useEffect(() => {
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [total]);

    useEffect(() => {
        dispatch(getShareManagementDetail(currentPage, itemsPerPage))
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [currentPage, itemsPerPage])

    const paginationHandlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        // dispatch(getShareManagementDetail(currentPage, itemsPerPage)).then()
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
          insurer_name: insurerDetails.insurer_name?.label,
          insurer_region: insurerDetails.insurer_region?.label,
          insurer_category: insurerDetails.insurer_category?.label,
          central_share: insurerDetails.central_share,
          state_share: insurerDetails.state_share,
          benficiary_share: insurerDetails.benficiary_share
        }
        addCattleInsurer( data )
        .then((res) => {
          res?.status === 0 && (toast.error(res?.message) );
          if (res?.status === 1) {
            toast.success("Share Insurer added successfully.");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }

    const handleUpdateSubmit = (e) => {
      e.preventDefault();
      const data = {
        id: parseInt(insurerDetails.id),
        insurer_name: insurerDetails.insurer_name?.label || insurerDetails.insurer_name,
        insurer_region: insurerDetails.insurer_region?.label || insurerDetails.insurer_region,
        insurer_category: insurerDetails.insurer_category?.label || insurerDetails.insurer_category,
        central_share: insurerDetails.central_share,
        state_share: insurerDetails.state_share,
        benficiary_share: insurerDetails.benficiary_share,
        is_active: 1
      }
      addCattleUpdateInsurer( data )
      .then((res) => {
        res?.status === 0 && (toast.error(res?.message) );
        if (res?.status === 1) {
          toast.success("Share Insurer Updated successfully.");
          setTimeout(() => {
            navigate('/cattle-insurance/insurer-details')
            window.location.reload()
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }
  
  const handleExportExcelData = () => {
    fetch(Constant.BASE_URL + `cattle/insurerExel`, { method: 'post' } )
     .then(response => {
       
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
          a.download = `Share-Details.xlsx`;
					a.click();
				});
				//window.location.href = response.url;
		});
  }

    const personalchk = (sectionname, e) => {
        if (sectionname === "insurer_name" || sectionname === "insurer_region" || sectionname === "insurer_category") {
            setInsurerDetails({ ...insurerDetails, [sectionname]: e});
        }
        if (sectionname === "central_share" || sectionname === "state_share" || sectionname === "benficiary_share") {
          setInsurerDetails({ ...insurerDetails, [sectionname]: e.target.value});
      }
    };

    const handleEdit = (data) => {
      setInsurerDetails(data)
    }

    const handleDelete = (id) => {
      var result = window.confirm("Are you sure to delete?");
      if(result){
          addCattleDeleteInsurer({id})
          .then((res) => {
            res?.status === 0 && (toast.error(res?.message) );
            if (res?.status === 1) {
              toast.success("Share Insurer deleted successfully.");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      }
    }

    useEffect(() => {
        setTimeout(() => {
        setShow(true);
        }, 500);
    }, [])
const ShowEntries = ({ totalPage, total, setItemsPerPage, itemsPerPage }) => {
   
  const temp = [];
  for (let i = 1; i <= totalPage; i++) {
    i === totalPage ? temp.push(total) : temp.push(10 * i);
  }
  return (
    <div className="d-flex justify-content-between">
    <div className="d-flex  col-lg-3 align-items-center mb-3 p-0">
      <span>Show</span>
      <select
        className="form-select ms-2 me-2"
        value={itemsPerPage}
        onChange={(e) => setItemsPerPage(e.target.value)}
      >
        {temp.map((d, i) => (
          <option value={d} key={Date.now() + i} defaultValue={itemsPerPage === d}>
            {d}
          </option>
        ))}
      </select>
      <span>Entries</span>
          </div>
      <div className="text-right" style={{cursor:"pointer"}}  onClick={()=>handleExportExcelData()} ><img style={{width:"50px", height:"50px", marginBottom:"10px"}} src={xls}></img></div>
          
</div>
  );
  };
    let totalValue = (Number(insurerDetails?.central_share) + Number(insurerDetails?.state_share) + Number(insurerDetails?.benficiary_share)) || 0;
    let isTotal = totalValue <= 100 ? false : true || "";

  return (
    <div className="pos cattle-insurance">
      <Header title={id ? "Edit Share Details" : "Share Details"} />
      {show ? <div className="p-4 bg-white shadow rounded-3">
            {id ? '' : shareRole[0]?.create_action === 1 && <ShareDetails
                updateData={personalchk}
                insurerDetail={insurerDetails}
            />}
            {id ? <ShareDetails
                updateData={personalchk}
                insurerDetail={insurerDetails}
                isEdit={id ? true : false}
            /> : ''}
            {isTotal == '' ? "" : <p className="text-center" style={{ color: 'red' }}>Value should not exceed more than 100%</p>}
            <div className="row row-cols-1 row-cols-lg-1 mt-3 form d-flex justify-content-center align-items-center w-100">
              {id ? <button className="btn btn-primary m-2 mb-4" onClick={handleUpdateSubmit} disabled={isTotal}>
                Update
              </button> :
              shareRole[0]?.create_action === 1 && <button className="btn btn-primary m-2 mb-4" onClick={handleSubmit} disabled={isTotal}>
                Submit
              </button>}
            </div>
        {id ? "" : <>
                   <ShowEntries
        totalPage={totalPage}
        total={total}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={itemsPerPage}
      />
          <div className="table-responsive">
          <table className="table table-bordered table-striped" width="auto">
                <thead style={{ backgroundColor: "#edf5fb" }}>
                    <tr className="text-uppercase" style={{fontSize:"13px"}}>
                    <th scope="col">Insurer Name</th>
                    <th scope="col">Insurer Region</th>
                    <th scope="col">Category</th>
                    <th scope="col">Central Share</th>
                    <th scope="col">State Share</th>
                    <th scope="col">Beneficiary Share</th>
                    {((shareRole[0]?.edit_action === 1) || (shareRole[0]?.delete_action === 1)) && <th scope="col" colSpan={'2'} className="text-center">Actions</th>}
                    </tr>
                </thead>
                <tbody style={{fontFamily:"calibri"}}>
                        {cattleInsurerList?.map((item, i) => (<tr>
                            <td key={i}>{item.insurer_name}</td>
                            <td>{item.insurer_region}</td>
                            <td>{item.insurer_category}</td>
                            <td>{item.central_share}%</td>
                            <td>{item.state_share}%</td>
                            <td>{item.benficiary_share}%</td>
                            {shareRole[0]?.edit_action === 1 && <td>
                                <Link className="btn btn-primary" to={`/cattle-insurance/insurer-details/${item.id}`} style={{lineHeight:"0.75"}} onClick={() => handleEdit(item)}>
                                    Edit
                                </Link>
                            </td>}
                            {shareRole[0]?.delete_action === 1 && <td>
                                <button className="btn btn-danger" style={{lineHeight:"0.75"}} onClick={() => handleDelete(item.id)}>
                                    Delete
                                </button>
                            </td>}
                        </tr>))}
                </tbody>
          </table>
          </div><div className="table_paginate">
                <ReactPaginate
                    containerClassName="pagination"
                    nextClassName="page-item"
                    pageClassName="page-item"
                    nextLabel="Next"
                    onPageChange={paginationHandlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    disableInitialCallback={true}
                    previousLabel="Previous"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div></>}
      </div> : <Loading />}
    </div>
  );
};

export default AddCattleInfo;
