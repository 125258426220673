import React, {useState} from "react";
import { stringFormatHandleEmpty } from "../utils/methods";
import Select from "react-select";

const ClaimSettlement = ({
  updateData = void 0,
  claimSettlementDetails = [],
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
  isEdit = false
}) => {

    const claimStatus = [
        {
            label: 'Partially Approved',
            value: 1
        },
        {
            label: 'Fully Approved',
            value: 2
        },
        {
            label: 'Rejected',
            value: 3
        },
    ]

    // console.log(claimSettlementDetails, 'claimSettlementDetails')
  return (
    <>

       {!isDisabled && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
                <>
                    {isEdit ? <Select
                        options={claimStatus}
                        isClearable={true}
                        onChange={(e) => (e != null ? updateData("claim_status", e) : updateData("claim_status", ""))}
                        className="searchable-select"
                        name="claim_status"
                        defaultValue={{ label: claimSettlementDetails?.claim_status, value: claimSettlementDetails?.claim_status }}
                        isDisabled={isDisabled}
                    /> :
                    <Select
                        options={claimStatus}
                        isClearable={true}
                        onChange={(e) => (e != null ? updateData("claim_status", e) : updateData("claim_status", ""))}
                        className="searchable-select"
                        name="claim_status"
                        value={claimSettlementDetails?.claim_status}
                        isDisabled={isDisabled}
                    />}
                    <label className="ms-3 position-absolute" htmlFor="Claim Status">
                        Claim Status
                    </label>
                    <p className="p-error">{errors?.claim_status || formErrors?.claim_status}</p>
                </>
            </div>
            {!isEdit && claimSettlementDetails?.claim_status?.label == "Partially Approved" ? <div className="col position-relative">
              <input
                className="w-100"
                id="claim_partition"
                type="number"
                name="claim_partition"
                onChange={(e) => updateData("claim_partition", e)}
                value={
                  !isDisabled ? claimSettlementDetails?.claim_partition : stringFormatHandleEmpty(data.claim_partition)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Claim Partition
              </label>
              <p className="p-error">{errors?.claim_partition || formErrors?.claim_partition}</p>
            </div> : ''}
            {isEdit && claimSettlementDetails?.claim_status == "Partially Approved" ? <div className="col position-relative">
              <input
                className="w-100"
                id="claim_partition"
                type="number"
                name="claim_partition"
                onChange={(e) => updateData("claim_partition", e)}
                value={
                  !isDisabled ? claimSettlementDetails?.claim_partition : stringFormatHandleEmpty(data.claim_partition)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Claim Partition
              </label>
              <p className="p-error">{errors?.claim_partition || formErrors?.claim_partition}</p>
            </div> : ''}
            {claimSettlementDetails?.claim_status?.label == "Rejected" || claimSettlementDetails?.claim_status == "Rejected" ? "" : <div className="col position-relative">
              <input
                className="w-100"
                id="claim_amount"
                type="number"
                name="claim_amount"
                onChange={(e) => updateData("claim_amount", e)}
                value={
                  !isDisabled ? claimSettlementDetails?.claim_amount : stringFormatHandleEmpty(data.claim_amount)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Claim Amount
              </label>
              <p className="p-error">{errors?.claim_amount || formErrors?.claim_amount}</p>
            </div>}
            <div className="col position-relative">
                <textarea
                    className="w-100"
                    id="claim_additional_remarks"
                    rows="4" cols="50"
                    name="claim_additional_remarks"
                    maxLength={50}
                    onChange={(e) => updateData("claim_additional_remarks", e)}
                    value={claimSettlementDetails?.claim_additional_remarks}
                    disabled={false}
                />
                <label className="ms-3 position-absolute" htmlFor="Additional Remarks">
                    Additional Remarks
                </label>
                    <p className="p-error">{errors?.claim_additional_remarks || formErrors?.claim_additional_remarks}</p>
            </div>
        </div>}

        {isDisabled && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
                    <input
                        className="w-100"
                        value={stringFormatHandleEmpty(data.claim_status)}
                        disabled={true}
                    />
                <label className="ms-3 position-absolute" htmlFor="others">
                    Claim Status
                </label>
            </div>
            {data?.claim_status == "Partially Approved" && <div className="col position-relative">
                <>
                    <input
                        className="w-100"
                        value={stringFormatHandleEmpty(data.claim_partition)}
                        disabled={true}
                    />
                    <label className="ms-3 position-absolute" htmlFor="Claim Reason">
                        Claim Partition
                    </label>
                </>
            </div>}
            <div className="col position-relative">
                    <input
                        className="w-100"
                        value={stringFormatHandleEmpty(data.claim_amount)}
                        disabled={true}
                    />
                <label className="ms-3 position-absolute" htmlFor="Date of">
                    Claim Amount
                </label>
            </div>
            <div className="col position-relative">
                <textarea
                    className="w-100"
                    id="claim_additional_remarks"
                    rows="4" cols="50"
                    name="claim_additional_remarks"
                    maxLength={50}
                    value={data.claim_additional_remarks}
                    disabled={true}
                />
                <label className="ms-3 position-absolute" htmlFor="Additional Remarks">
                    Additional Remarks
                </label>
                    <p className="p-error">{errors?.claim_additional_remarks || formErrors?.claim_additional_remarks}</p>
            </div>
        </div>}

    </>
  );
};

export default ClaimSettlement;
