import React, { useState, useEffect } from "react";
import AddDoctor from "../components/AddDoctor";
import { updatePhotoDoctorUpload } from "../store/actions/Image";
import { checkImgValidation } from "../utils/methods";
import { viewDoctor, editDoctor } from "../store/actions/personalDetails";
import { toast } from "react-toastify";
import Loading from "../components/Loading";

const EditDoctorManagement = (props) => {
    const {setIsEdit, id} = props
    const fname_regex = /^[a-zA-Z ]+$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const mobile_regex = /^[0-9\b]+$/;

    const [errors, setErrors] = useState({});
    const [doctorDetails, setDoctorDetails] = useState({
        doctor_reg_ID: "",
        doctor_designation: "",
        doctor_email: "",
        doctor_phone: null,
        doctor_name: "",
        doctor_state: "",
        doctor_city: "",
        doctor_zipcode: null,
        doctor_block: "",
        doctor_village: "",
        doctor_address: "",
        doctor_location: "",
        doctor_upload: ""
    })
    const [show, setShow] = useState(false);
    const [selectDoctorState, setSelectDoctorState] = useState("");
    const [selectDoctorCity, setSelectDoctorCity] = useState("");
    const [selectDoctorPinCode, setSelectDoctorPinCode] = useState("");
    
    const [doctorPhotoUpload, setDoctorPhotoUpload] = useState([]);
    const [doctorDocumentCount, setDoctorDocumentCount] = useState(1);
    const [loaderCount, setLoaderCount] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const doctor = {
            id: id,
            doctor_reg_ID: doctorDetails.doctor_reg_ID,
            doctor_designation: doctorDetails.doctor_designation,
            doctor_email: doctorDetails.doctor_email,
            doctor_phone: doctorDetails.doctor_phone,
            doctor_name: doctorDetails.doctor_name,
            doctor_state: selectDoctorState || doctorDetails.doctor_state,
            doctor_city: selectDoctorCity || doctorDetails.doctor_city,
            doctor_zipcode: selectDoctorPinCode || doctorDetails.doctor_zipcode,
            doctor_block: doctorDetails.doctor_block,
            doctor_village: doctorDetails.doctor_village,
            doctor_address: doctorDetails.doctor_address,
            doctor_location: doctorDetails.doctor_location,
            doctor_upload: doctorPhotoUpload[0] || doctorDetails.doctor_upload || ""
        }
        editDoctor(doctor)
        .then((res) => {
          res?.status === 0 && (toast.error(res?.message) );
          if (res?.status === 1) {
            toast.success("Doctor Updated successfully.");
            setTimeout(() => {
              window.location.reload()
            }, 500);
            setIsEdit(false)
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }

    const personalchk = (sectionname, e) => {
        if (sectionname === "doctor_state" || sectionname === "doctor_city" || sectionname === "doctor_zipcode") {
            setDoctorDetails({ ...doctorDetails, [sectionname]: e});
            // setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "doctor_reg_ID" || sectionname === "doctor_designation" || sectionname === "doctor_name" || sectionname === "doctor_block" || sectionname === "doctor_village" || sectionname === "doctor_address" || sectionname === "doctor_location") {
            setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value});
            // setErrors({ ...errors, [sectionname]: "" });
        }
        if (sectionname === "doctor_phone") {
            if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
              setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
            } else {
              e.target.value !== "e" &&
                e.target.value.length <= 10 &&
                setDoctorDetails({
                  ...doctorDetails,
                  [sectionname]: parseInt(e.target.value),
                });
            //   setErrors({ ...errors, [sectionname]: "" });
            }
        }
        if (sectionname === "doctor_email") {
            if (e.target.value !== "" && !email_regex.test(e.target.value)) {
                setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
                setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value });
            } else {
                setDoctorDetails({ ...doctorDetails, [sectionname]: e.target.value });
                // setErrors({ ...errors, [sectionname]: "" });
            }
        }
    };

    const uploadSingleFile = (e, field) => {
        // validtion && setFormErrors(validate(addCattleOwnerDetails));
        if (checkImgValidation(e?.target?.files?.[0]) === false) {
            return;
        }
        if (field === "doctor_upload") {
            setLoaderCount(true);
            const formData = new FormData();
            formData.append("doctor_upload", e.target.files[0]);
            updatePhotoDoctorUpload(formData)
            .then((res) => {
                if (res.status === 0) {
                  setErrors({ ...errors, photoURL: res?.message });
                  setLoaderCount(false);
                } else {
                  setLoaderCount(false);
                  setDoctorPhotoUpload([...doctorPhotoUpload, res.photoURL]);
                }
            })
            .catch((error) => {
                setErrors({ ...errors, photoURL: error.message });
            });
        }
      };
    
    const deleteFile = (e, field) => {
        if (field === "doctor_upload") {
            const s = doctorPhotoUpload.filter((item, index) => index !== e);
            setDoctorPhotoUpload(s);
            setDoctorDetails({ ...doctorDetails, ["doctor_upload"]: null });
        }
    };

    useEffect(() => {
        viewDoctor(id)
        .then((res) => {
            if (res?.status === 1) {
                setDoctorDetails(res.data)
            }
        })
        .catch((error) => {
          toast.error(error.message);
        });
        setTimeout(() => {
          setShow(true);
        }, 1200);
    }, []);

  return (
    <div className="pos cattle-insurance">
      {show ? <div className="">
            <AddDoctor
                personalchk={personalchk}
                personalDetail={doctorDetails}
                setDoctorDocumentCount={setDoctorDocumentCount}
                doctorDocumentCount={doctorDocumentCount}
                selectDoctorState={selectDoctorState}
                setSelectDoctorState={setSelectDoctorState}
                selectDoctorCity={selectDoctorCity}
                setSelectDoctorCity={setSelectDoctorCity}
                selectDoctorPinCode={selectDoctorPinCode}
                setSelectDoctorPinCode={setSelectDoctorPinCode}
                uploadSingleFile={uploadSingleFile}
                deleteFile={deleteFile}
                // formErrors={formErrors}
                errors={errors}
                doctorPhotoUpload={doctorPhotoUpload}
                loaderCount={loaderCount}
                isEdit={true}
                idDisabled = {true}
              />
            <div className="row row-cols-1 row-cols-lg-1 mt-3 form d-flex justify-content-center align-items-center w-100">
                <button className="btn btn-primary m-2 mb-4" onClick={handleSubmit}>
                    Update
                </button>
            </div>
      </div> : <Loading />}
    </div>
  );
};

export default EditDoctorManagement;
