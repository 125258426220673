import axios from "../../config/axios";
import {
  FETCH_CLAIM_MANAGEMENT_FAILURE,
  FETCH_CLAIM_MANAGEMENT_REQUEST,
  FETCH_CLAIM_MANAGEMENT_SUCCESS,
} from "./types";

export const fetchClaimManagementDetailSuccess = (model) => {
  return {
    type: FETCH_CLAIM_MANAGEMENT_SUCCESS,
    payload: model,
  };
};
export const fetchClaimManagementDetailFailure = (error) => {
  return {
    type: FETCH_CLAIM_MANAGEMENT_FAILURE,
    payload: error,
  };
};

export const getClaimManagementDetail = (currentPage, pagelimit) => async (dispatch) => {
  // dispatch(fetchCATTLEDetailRequest);
  await axios
    .post(`cattle/listCattleClaim/?page=${currentPage}&limit=${pagelimit}`)
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(fetchClaimManagementDetailSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchClaimManagementDetailFailure(errorMsg));
    });
};