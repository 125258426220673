import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClaimInfo } from "../store/actions/claimInfo";
import Header from "../components/Header";
import Loading from "../components/Loading";
import IntimateClaim from "../components/IntimateClaim";
import ClaimDoctorDetails from "../components/ClaimDoctorDetails";
import ClaimInvestigatorDetails from "../components/ClaimInvestigatorDetails";
import BankDetailsForm from "../components/BankDetailsCattleForm";
import ClaimSettlement from "../components/ClaimSettlement";
import { Accordion } from "react-bootstrap";

const ViewCattleDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.claimInfo.data.data);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(getClaimInfo(id));
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  console.log(result, 'result')

  return (
     <div className="pos cattle-insurance">
      <Header title="View Claim Intimation" />
       {show ? <div className="p-4 bg-white shadow rounded-3">
       <Accordion defaultActiveKey="0" flush> 
            <Accordion.Item eventKey="1">
            <Accordion.Header>Claim Form</Accordion.Header>
            <Accordion.Body>
                <IntimateClaim
                    isDisabled={true} 
                    data={result && result.viewClaimrResult}
                />
            </Accordion.Body>
          </Accordion.Item> 
          <Accordion.Item eventKey="3">
            <Accordion.Header>Assign Investigator</Accordion.Header>
            <Accordion.Body>
                <ClaimInvestigatorDetails
                    isDisabled={true} 
                    data={result && result.investigator_details}
                    isShowView={0}
                    isEdit={true}
                />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Assign Doctor</Accordion.Header>
            <Accordion.Body>
                <ClaimDoctorDetails
                    isDisabled={true} 
                    data={result && result.doctor_details}
                    isShowView={0}
                    isEdit={true}
                />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Bank Details</Accordion.Header>
            <Accordion.Body>
                <BankDetailsForm
                    isDisabled={true} 
                    data={result && result.viewBankDetails[0]}
                />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>Claim Settlement</Accordion.Header>
            <Accordion.Body>
                <ClaimSettlement
                    isDisabled={true} 
                    data={result && result.viewClaimrResult}
                />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div> : <Loading />}
    </div>
  );
};

export default ViewCattleDetails;
