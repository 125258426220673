import { Card, ListGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import down from "../assets/img/down-arrow.png"
import up from "../assets/img/up-arrow.png"
import { useState, useEffect } from "react";
import { getAdminRolesInfo } from "../store/actions/personalDetails";
import { ROLES } from "../utils/variables";

const MenuItem = ({ path, name, toggleClass }) => {
  const location = useLocation();

  return (
    <Link
      to={path}
      className={`${location.pathname === path && "active"}`}
      onClick={toggleClass}
    >
      <ListGroup.Item>{name}</ListGroup.Item>
    </Link>
  );
};

const SideBar = ({ toggleClass, toggle, toogleSidebar }) => {
  const { pathname } = window.location;
  const [toggleArrow, setToggleArrow] = useState(false);
  const [toggleArrowTwo, setToggleArrowTwo] = useState(false);
  const [cattleRole, setCattleRoles] = useState([])
  const [posRole, setPOSRoles] = useState([])
  let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role;

  useEffect(() => {
    let role_id = ROLES[role_name]
    if(role_id){
      getAdminRolesInfo(role_id)
      .then((res) => {
        if (res.status) {
          let cattle = res.data.filter(obj => {
            return obj.module_name == "Cattle Insurance ";
          });
          let pos = res.data.filter(obj => {
            return obj.module_name == "POS Management";
          });
          setCattleRoles(cattle)
          setPOSRoles(pos)
        }
      })
    }
  }, [])
  
  const handleArrowClick = () => {
    setToggleArrow(!toggleArrow);
    setToggleArrowTwo(false)
  };

  const handleArrowClickTwo = () => {
    setToggleArrow(false);
    setToggleArrowTwo(!toggleArrowTwo)
  };
  return (
    <Card className={`sidebar rounded-0 ${toggleClass}`}>
      {((ROLES[role_name] === 1) || (ROLES[role_name] === 2) || (ROLES[role_name] === 3)) && <Card.Body>
        <ListGroup variant="flush">
          {/* <MenuItem
            path="/dashboard"
            name="Dashboard"
            toggleClass={toggle ? toogleSidebar : void 0}
          /> */}
          <MenuItem
            path="/pos/all"
            name="POS Details"
            toggleClass={toggle ? toogleSidebar : void 0}
          />
          {posRole[0]?.create_action === 1 && <MenuItem
            path="/pos/add"
            name="Add POS"
            toggleClass={toggle ? toogleSidebar : void 0}
          />}
          <MenuItem
            path="/cattle-insurance/insurer-details"
            name="Share Details"
            toggleClass={toggle ? toogleSidebar : void 0}
          />
          <div className="py-3 px-2 d-flex justify-content-between" style={{ borderBottom:"1px solid lightgrey"}} onClick={handleArrowClick}>
            <div
              style={{
                fontFamily: "ErasDemiITC",
                color: "#334d6e",
                fontSize: "15px",
                cursor: "pointer",
               
              }}
            >
              Cattle Insurance
            </div>

            {!toggleArrow && (
              <div>
               <img src={down} style={{height:"15px"}}></img>
              </div>
            )}
            {toggleArrow && (
              <div>
                   <img src={up} style={{height:"15px"}}></img>
              </div>
            )}
          </div>

          {toggleArrow ? (
            <>
              <MenuItem
                path="/cattle-insurance/all"
                name="Cattle Insurance Details"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              {cattleRole[0]?.create_action === 1 && <MenuItem
                path="/cattle-insurance/add"
                name="Add Cattle Insurance"
                toggleClass={toggle ? toogleSidebar : void 0}
              />}
              <MenuItem
                path="/cattle-insurance/endorsement"
                name="Endorsement Management"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              <MenuItem
                path="/cattle-insurance/claim"
                name="Claim Management"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              <MenuItem
                path="/cattle-insurance/doctor"
                name="Doctor Management"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              <MenuItem
                path="/cattle-insurance/investigator"
                name="Investigator Management"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              <MenuItem
                path="/cattle-insurance/surveyor"
                name="Surveyor List"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
            </>
          ) : (
            ""
          )}
          {role_name === "Super Admin" && <div className="py-3 px-2 d-flex justify-content-between" style={{ borderBottom:"1px solid lightgrey"}} onClick={handleArrowClickTwo}>
            <div
              style={{
                fontFamily: "ErasDemiITC",
                color: "#334d6e",
                fontSize: "15px",
                cursor: "pointer",
               
              }}
            >
              Role Management
            </div>

            {!toggleArrowTwo && (
              <div>
               <img src={down} style={{height:"15px"}}></img>
              </div>
            )}
            {toggleArrowTwo && (
              <div>
                   <img src={up} style={{height:"15px"}}></img>
              </div>
            )}
          </div>}
          {toggleArrowTwo ? (
            <>
              <MenuItem
                path="/cattle-insurance/role"
                name="Role"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              <MenuItem
                path="/cattle-insurance/role-permission"
                name="Role Permission"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
            </>
          ) : (
            ""
          )}
        </ListGroup>
      </Card.Body>}
      {ROLES[role_name] === 6 && <Card.Body>
        <ListGroup variant="flush">
            <>
              <MenuItem
                path="/cattle-insurance/doctor-dashboard"
                name="Dashboard"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              <MenuItem
                path="/cattle-insurance/assigned-doctor"
                name="Cattle Insurance Details"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
            </>
        </ListGroup>
      </Card.Body>}
      {ROLES[role_name] === 7 && <Card.Body>
        <ListGroup variant="flush">
          <>
            <MenuItem
              path="/cattle-insurance/investigator-dashboard"
              name="Dashboard"
              toggleClass={toggle ? toogleSidebar : void 0}
            />
            <MenuItem
              path="/cattle-insurance/assigned-investigator"
              name="Cattle Insurance Details"
              toggleClass={toggle ? toogleSidebar : void 0}
            />
          </>
        </ListGroup>
      </Card.Body>}
       {role_name === "Surveyor"  && <Card.Body>
        <ListGroup variant="flush">
            <>
              <MenuItem
                path="/cattle-insurance/surveyor-dashboard"
                name="Dashboard"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              <MenuItem
                path="/cattle-insurance/assigned-surveyor"
                name="Cattle Insurance Details"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
            </>
        </ListGroup>
      </Card.Body>}
    </Card>
  );
};

export default SideBar;
