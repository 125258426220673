import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_BRANCHLIST_FAILURE, FETCH_BRANCHLIST_REQUEST, FETCH_BRANCHLIST_SUCCESS } from "./types"



export const fetchBranchListRequest = () => {
    return {
        type: FETCH_BRANCHLIST_REQUEST
    }
}
export const fetchBranchListSuccess = model => {
    return {
        type: FETCH_BRANCHLIST_SUCCESS,
        payload: model
    }
}
export const fetchBranchListFailure = error => {
    return {
        type: FETCH_BRANCHLIST_FAILURE,
        payload: error
    }
}


export const getBranchList = () => async (dispatch) => {
    dispatch(fetchBranchListRequest)
    await axios.get(Constant.BASE_URL + 'pos/getBranches')
        .then(response => {
            const result = response.data
            dispatch(fetchBranchListSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchBranchListFailure(errorMsg))
        })
}