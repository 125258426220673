import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getBranchList } from "../store/actions/branchList";
import { stringFormat } from "../utils/methods";

const BranchForm = ({
  personalDetail = [],
  personalchk = void 0,
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    !isDisabled && dispatch(getBranchList());
  }, []);

  useEffect(() => {
    data?.posStatus === "Details Submitted" && dispatch(getBranchList());
  }, [isDisabled]);

  const branchListState = useSelector((state) => state.posBranchList.data.data);
  return (
    <div className="row row-cols-1 row-cols-lg-2 gy-4 form pt-3 pb-3 mt-1">
      <div className="col position-relative">
        {!isDisabled ? (
          <select
            className="w-100"
            value={personalDetail.assignedBranch}
            onChange={(e) => personalchk("assignedBranch", e)}
          >
            <option value="">Select</option>
            {branchListState &&
              branchListState.length > 0 &&
              branchListState.map(({ id, branchName }) => {
                return (
                  <option key={id} id={id} value={branchName}>
                    {branchName}
                  </option>
                );
              })}
          </select>
        ) : (
          <input
            className="w-100"
            value={stringFormat(data.assignedBranch)}
            disabled={true}
          />
        )}
        <label className="ms-3 position-absolute" htmlFor="branch_select">
          Policicue Branch
        </label>
        <p className="p-error">
          {errors?.assignedBranch || formErrors?.assignedBranch}
        </p>
      </div>
      <div className="col position-relative">
        {!isDisabled ? (
          <select
            className="w-100"
            value={personalDetail.branchManager}
            onChange={(e) => personalchk("branchManager", e)}
          >
            <option value="">Select</option>
            {branchListState &&
              branchListState.length > 0 &&
              branchListState.map(({ id, relationshipManager }) => {
                return (
                  <option key={id} id={id} value={relationshipManager}>
                    {relationshipManager}
                  </option>
                );
              })}
          </select>
        ) : (
          <input
            className="w-100"
            value={stringFormat(data.branchManager)}
            disabled={true}
          />
        )}
        <label className="ms-3 position-absolute" htmlFor="branch_manager">
          Policicue Relationship Manager
        </label>
        <p className="p-error">
          {errors?.branchManager || formErrors?.branchManager}
        </p>
      </div>
    </div>
  );
};

export default BranchForm;
