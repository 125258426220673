import axios from "../../config/axios";
import {
  FETCH_DOCTOR_MANAGEMENT_FAILURE,
  FETCH_DOCTOR_MANAGEMENT_REQUEST,
  FETCH_DOCTOR_MANAGEMENT_SUCCESS,
} from "./types";

export const fetchDoctorManagementDetailSuccess = (model) => {
  return {
    type: FETCH_DOCTOR_MANAGEMENT_SUCCESS,
    payload: model,
  };
};
export const fetchDoctorManagementDetailFailure = (error) => {
  return {
    type: FETCH_DOCTOR_MANAGEMENT_FAILURE,
    payload: error,
  };
};

export const getDoctorManagementDetail = (currentPage, pagelimit) => async (dispatch) => {
  // dispatch(fetchCATTLEDetailRequest);
  await axios
    .post(`doctor/listDoctor/?page=${currentPage}&limit=${pagelimit}`)
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(fetchDoctorManagementDetailSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchDoctorManagementDetailFailure(errorMsg));
    });
};