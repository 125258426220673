import axios from "../../config/axios";
import {
  FETCH_CLAIMINFO_FAILURE,
  FETCH_CLAIMINFO_REQUEST,
  FETCH_CLAIMINFO_SUCCESS,
  GET_SEARCH_RESULT_SUCCESS,
  GET_SEARCH_RESULT_FAILURE,
} from "./types";

export const fetchClaimInfoRequest = () => {
  return {
    type: FETCH_CLAIMINFO_REQUEST,
  };
};
export const fetchClaimInfoSuccess = (model) => {
  return {
    type: FETCH_CLAIMINFO_SUCCESS,
    payload: model,
  };
};
export const fetchClaimInfoFailure = (error) => {
  return {
    type: FETCH_CLAIMINFO_FAILURE,
    payload: error,
  };
};

export const getSearchResultSuccess = (model) => {
    return {
      type: GET_SEARCH_RESULT_SUCCESS,
      payload: model,
    };
  };
export const getSearchResultFailure = (error) => {
return {
    type: GET_SEARCH_RESULT_FAILURE,
    payload: error,
};
};

export const getClaimInfo = (Id) => async (dispatch) => {
  dispatch(fetchClaimInfoRequest);
  await axios
    .post(`cattle/previewCattleClaim`, {claim_number:Id})
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(fetchClaimInfoSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchClaimInfoFailure(errorMsg));
    });
};