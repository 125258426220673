import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import Constant from "../utils/constant";
import axios from "../config/axios";
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from "react-paginate";
import AddInvestigator from "./AddInvestigatorManagement";
import EditInvestigatorManagement from "./EditInvestigatorManagement";
import ViewInvestigatorManagement from "./ViewInvestigatorManagement";
import { getInvestigatorManagementDetail } from "../store/actions/InvestigatorManagementDetails";
import { useDispatch, useSelector } from "react-redux";
import xls from "../assets/img/xls.png"
import { getAdminRolesInfo } from "../store/actions/personalDetails";
import { ROLES } from "../utils/variables";

const InvestigatorManagement = () => {
    const investigatorList = useSelector((state) => state.investigatorManagementDetail.data.data);
    const total = useSelector((state) => state.investigatorManagementDetail.data.total) || 0;
    const [checked, setChecked] = useState();
    const [isAdd, setIsAdd] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [getId, setGetId] = useState();
    const totalPage = Math.ceil(total / 10);
    const [investigatorRole, setInvestigatorRoles] = useState([])
    let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role

    useEffect(() => {
        let role_id = ROLES[role_name]
        if(role_id){
        getAdminRolesInfo(role_id)
        .then((res) => {
            if (res.status) {
            let investigator = res.data.filter(obj => {
                return obj.module_name == "Investigator Management";
            });
            setInvestigatorRoles(investigator)
            }
        })
        }
    }, [])
    
    useEffect(() => {
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [total]);

    useEffect(() => {
        dispatch(getInvestigatorManagementDetail(currentPage, itemsPerPage))
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [currentPage, checked, itemsPerPage])

    const paginationHandlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        // dispatch(getInvestigatorManagementDetail(currentPage, itemsPerPage)).then()
    };

    useEffect(() => {
        setTimeout(() => {
          setShow(true);
        }, 500);
    }, [])

    const onToggle = (id, event) => {
        let val = event.target.checked ? "active" : "deactive";
        let Confrimtext = `Are you sure you want to ${val} this investigator?`
        if (window.confirm(Confrimtext) != true) {
        return;
        }
        setChecked(event.target.checked)
        axios.post(Constant.BASE_URL + 'investigator/activeDeactiveInvestigator', {id : id, is_active: event.target.checked })
        .then(response => {
            if(response?.data?.status == 1){
                toast.success(response?.data?.message)
                // dispatch(getInvestigatorManagementDetail(currentPage, itemsPerPage)).then()
            }
        })
        .catch((error) => {
        toast.error(error.message);
        });
        
    }
      const handleExportExcelData = () => {
    fetch(Constant.BASE_URL + `investigator/investigatorExcel`, { method: 'post' } )
     .then(response => {
       
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
          a.download = `Investigator-Details.xlsx`;
					a.click();
				});
				//window.location.href = response.url;
		});
  }
const ShowEntries = ({ totalPage, total, setItemsPerPage, itemsPerPage }) => {
   
  const temp = [];
  for (let i = 1; i <= totalPage; i++) {
    i === totalPage ? temp.push(total) : temp.push(10 * i);
  }
  return (
    <div className="d-flex justify-content-between">
    <div className="d-flex  col-lg-3 align-items-center mb-3 p-0">
      <span>Show</span>
      <select
        className="form-select ms-2 me-2"
        value={itemsPerPage}
        onChange={(e) => setItemsPerPage(e.target.value)}
      >
        {temp.map((d, i) => (
          <option value={d} key={Date.now() + i} defaultValue={itemsPerPage === d}>
            {d}
          </option>
        ))}
      </select>
      <span>Entries</span>
          </div>
      <div className="text-right" style={{cursor:"pointer"}}  onClick={()=>handleExportExcelData()} ><img style={{width:"50px", height:"50px", marginBottom:"10px"}} src={xls}></img></div>
          
</div>
  );
};
    return (
        <>
        {show ?  
            <div className="pos cattle-insurance">
        <Header 
            title={"Investigator Details"} 
            btn={investigatorRole[0]?.create_action === 1 ? true : false}
            btnText="Add Investigator"
            btnClass="p-btn"
            onClick={() => setIsAdd(true)}
        />

                    <div className="p-4 bg-white shadow rounded-3">
                                      <ShowEntries
        totalPage={totalPage}
        total={total}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={itemsPerPage}
      />
                        <div className="table-responsive">
                            
            <table className="table table-bordered table-striped" width="auto">
                <thead style={{ backgroundColor: "#edf5fb" }}>
                    <tr scope="col" className="text-uppercase" style={{fontSize:"13px"}}>
                    <th scope="col" className="text-center">Investigator Reg ID</th>
                    <th scope="col" className="text-center">Investigator Name</th>
                    <th scope="col" className="text-center">Email</th>
                    <th scope="col" className="text-center">Mobile No.</th>
                    <th scope="col" className="text-center">Location</th>
                    {((investigatorRole[0]?.view_action === 1) || (investigatorRole[0]?.edit_action === 1)) && <th scope="col" className="text-center">Action</th>}
                    </tr>
                </thead>
                <tbody style={{fontFamily:"calibri"}}>
                        {investigatorList?.length ? investigatorList?.map((item, i) => (<tr key={i}>
                            <td className="text-center" key={i}>{item.investigator_reg_ID}</td>
                            <td className="text-center">{item.investigator_name}</td>
                            <td className="text-center">{item.investigator_email}</td>
                            <td className="text-center">{item.investigator_phone}</td>
                            <td className="text-center">{item.investigator_location}</td>
                            {((investigatorRole[0]?.view_action === 1) || (investigatorRole[0]?.edit_action === 1)) && <td className="text-center">
                                {investigatorRole[0]?.view_action === 1 && <span 
                                    onClick={
                                        () => {
                                            setIsView(true)
                                            setGetId(item.id)
                                        }
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                <i
                                    title="View"
                                    className="bi bi-eye-fill p-1"
                                    style={{ color: "#0a65ff" }}
                                />
                                </span>}
                                {investigatorRole[0]?.edit_action === 1 && <span 
                                    onClick={
                                        () => {
                                            setIsEdit(true)
                                            setGetId(item.id)
                                        }
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                <i
                                    title="Edit"
                                    className="bi bi-pencil-square p-1"
                                    style={{ color: "#0a65ff" }}
                                /> 
                                </span>}
                                {investigatorRole[0]?.edit_action === 1 && <label className="toggle-switch">
                                    <input type="checkbox" checked={item.is_active} onChange={(e) => {
                                        onToggle(item.id, e)
                                    }} />
                                    <span className="switch" />
                                </label>}
                            </td>}
                        </tr>)) : 
                        <tr>
                            <td colSpan={7} style={{ textAlign: 'center' }}>No Data Found</td>
                        </tr>}
                </tbody>
            </table>
            </div>
            <div className="table_paginate">
                <ReactPaginate
                    containerClassName="pagination"
                    nextClassName="page-item"
                    pageClassName="page-item"
                    nextLabel="Next"
                    onPageChange={paginationHandlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    previousLabel="Previous"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </div>
        <Modal show={isAdd} size="xl" onHide={() => setIsAdd(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Add Investigator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddInvestigator setIsAdd={setIsAdd} />
            </Modal.Body>
        </Modal>
        <Modal show={isView} size="xl" onHide={() => setIsView(false)}>
            <Modal.Header closeButton>
            <Modal.Title>View Investigator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ViewInvestigatorManagement id={getId} />
            </Modal.Body>
        </Modal>
        <Modal show={isEdit} size="xl" onHide={() => setIsEdit(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Edit Investigator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditInvestigatorManagement id={getId} setIsEdit={setIsEdit} />
            </Modal.Body>
        </Modal>
        </div> : <Loading />}
        </>
    );
};

export default InvestigatorManagement;
