import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringFormatHandleEmpty, file, cattleformat_date } from "../utils/methods";
import Select from "react-select";
import { getState } from "../store/actions/listStates";
import { getCity } from "../store/actions/city";
import { getPinCode } from "../store/actions/pinCode";
import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../assets/img/x.png";
import ImagePlaceholder from "../assets/img/img_placeholder.png";
import axios from 'axios';
import { toast } from "react-toastify";
import Constant from "../utils/constant";

const ClaimDoctorDetails = ({
  personalchk = void 0,
  searchDoctorNumber = [],
  personalDetail = [],
  setDoctorDocumentCount = void 0,
  doctorDocumentCount = 1,
  loaderCount,
  formErrors = [],
  errors = [],
  selectDoctorState = "",
  setSelectDoctorState = void 0,
  selectDoctorCity = "",
  setSelectDoctorCity = void 0,
  selectDoctorPinCode = "",
  setSelectDoctorPinCode = void 0,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  isDisabled = false,
  doctorPhotoUpload = "",
  data = [],
  isEdit = false,
  isShowView,
  setAssignedDoctor
}) => {
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [isShow, setIsShow] = useState(isShowView);

    const listState = useSelector((state) => state.posState.data);
    const listCity = useSelector((state) => state.posCity.data);
    const listPinCode = useSelector((state) => state.posPinCode.data);
  
    const dispatch = useDispatch();

    const otherdata = data?.doctor_upload?.split(",");

    useEffect(() => {
        setDoctorDocumentCount && setDoctorDocumentCount(otherdata?.length)
    }, [data?.otherdata])
    
    useEffect(() => {
      if (selectDoctorPinCode !== "") {
      delete formErrors.pincode;
      }
    }, [selectDoctorState, selectDoctorCity, selectDoctorPinCode]);
    
    useEffect(() => {
        !isDisabled && dispatch(getState());
    }, []);

    const setState = (id) => {
        setSelectDoctorState(id);
        if (id === "") {
        setSelectDoctorCity("");
        setSelectDoctorPinCode("");
        }
        dispatch(getCity(id));
    };

    const setCity = (id) => {
        setSelectDoctorCity(id);
        if (id === "") {
        setSelectDoctorPinCode("");
        }
        dispatch(getPinCode(id));
    };

    const setPinCode = (id) => {
        setSelectDoctorPinCode(id);
    };

    const searchItems = (searchValue) => {
      setSearchInput(searchValue)
    }

    const searchByNumber = () => {
          if (searchInput !== '') {
          axios.post(Constant.BASE_URL + 'doctor/searchDoctor', {doctor_phone : searchInput})
              .then(response => {
                console.log(response, 'response')
                setFilteredResults(response)
                setAssignedDoctor(response?.data?.data)
                setIsShow(response?.data?.status)
                if(response?.data?.status == 0){
                    toast.error("Doctor not found")
                }
                if(response?.data?.status == 1){
                    toast.success("Doctor is Assigned")
                }
              })
              .catch((error) => {
                toast.error(error.message);
              });
          }
          else{
              setFilteredResults([])
          }
    }
    
    const {data: assignedUser} = filteredResults;

  return (
    <> 
      
      {!isDisabled && !isEdit && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            type="number"
            maxLength={10}
            onChange={(e) => {
                searchItems(e.target.value)
                personalchk("doctor_phone_search", e)
            }}
            value={searchDoctorNumber.doctor_phone_search}
            name="doctor_phone_search"
            placeholder="Search by phone No."
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Doctor Search
          </label>
        </div>
        <div style={{ width: '20%' }}>
          <button style={{height:"45px", width:"115px", fontSize: '16px', marginTop: '5px'}} className="btn btn-primary" onClick={()=>searchByNumber()}>Assign</button>
        </div>
        {/* {assignedUser?.data ? <div style={{ alignSelf: 'center', fontFamily: "calibri", fontSize: '16px', width: '46%' }}>
          <b>Assigned Doctor :</b> {assignedUser?.data?.doctor_name || ''}, {assignedUser?.data?.doctor_phone || ''}, {assignedUser?.data?.doctor_location || ''}, {format_date(assignedUser?.data?.createdAt)}
        </div> : ''} */}
      </div>}

      {assignedUser?.data && <label htmlFor="" style={{color:"#29166F", fontSize:"18px"}}>Assigned Doctor</label>}
      {assignedUser?.data && <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3">
        <div>
          <b>Name :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.doctor_name || ''}</span>
        </div>
        <div>
          <b>Phone :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.doctor_phone || ''}</span>
        </div>
        <div>
          <b>Location :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.doctor_location || ''}</span>
        </div>
        <div>
          <b>Date :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px',textTransform:"capitalize" }}>{cattleformat_date(new Date().toISOString().slice(0, 10)) || ''}</span>
        </div>
      </div>}
      
      {isShow == 0 && !isEdit && <label htmlFor="" style={{color:"#29166F", fontSize:"18px", marginBottom: '20px', marginBottom: '20px'}}>Or Add New Doctor</label>}

        {isShow == 0 &&   <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-2">
            <div className="col position-relative">
            <input
                className="w-100"
                id="doctor_name"
                type="text"
                name="doctor_name"
                onChange={(e) => personalchk("doctor_name", e)}
                value={
                !isDisabled ? personalDetail.doctor_name : stringFormatHandleEmpty(data.doctor_name)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Veterinary Doctor Name
            </label>
            <p className="p-error">{errors?.doctor_name || formErrors?.doctor_name}</p>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                id="doctor_designation"
                type="text"
                name="doctor_designation"
                onChange={(e) => personalchk("doctor_designation", e)}
                value={
                !isDisabled ? personalDetail.doctor_designation : stringFormatHandleEmpty(data.doctor_designation)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Designation
            </label>
            <p className="p-error">{errors?.doctor_designation || formErrors?.doctor_designation}</p>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                id="doctor_reg_ID"
                type="text"
                name="doctor_reg_ID"
                onChange={(e) => personalchk("doctor_reg_ID", e)}
                value={
                !isDisabled ? personalDetail.doctor_reg_ID : stringFormatHandleEmpty(data.doctor_reg_ID)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Doctor Reg ID
            </label>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                type="text"
                name="doctor_address"
                onChange={(e) => personalchk("doctor_address", e)}
                value={
                !isDisabled ? personalDetail.doctor_address : stringFormatHandleEmpty(data.doctor_address)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Doctor Address
            </label>
            <p className="p-error">{errors?.doctor_address || formErrors?.doctor_address}</p>
            </div>
            <div className="col position-relative">
            {!isDisabled ? <>
            {isEdit ? <Select
                options={listState}
                isClearable={!isDisabled}
                onChange={(e) => (e != null ? setState(e.value) : setState(""))}
                className="searchable-select"
                name="doctor_state"
                defaultValue={{ label: personalDetail.doctor_state, value: personalDetail.doctor_state }}
            /> : <Select
                options={listState}
                isClearable={!isDisabled}
                onChange={(e) => (e != null ? setState(e.value) : setState(""))}
                className="searchable-select"
                name="doctor_state"
                // value={personalDetail.doctor_state}
            />}
            </> : (
            <input
                className="w-100"
                value={stringFormatHandleEmpty(data.doctor_state)}
                disabled={true}
            />
            )}
            <label className="ms-3 position-absolute" htmlFor="landmark">
            Select State
            </label>
            {selectDoctorState === "" && (
            <p className="p-error">{errors?.doctor_state || formErrors?.doctor_state}</p>
            )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? <>
                {isEdit ? <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectDoctorState === "" ? true : false}
                    onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    className="searchable-select"
                    name="doctor_city"
                    defaultValue={{ label: personalDetail.doctor_city, value: personalDetail.doctor_city }}
                /> : <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectDoctorState === "" ? true : false}
                    onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    className="searchable-select"
                    name="doctor_city"
                    // value={personalDetail.doctor_city}
                />}
                </> : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.doctor_city)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select City
                </label>
                {selectDoctorState === "" && (
                <p className="p-error">{errors?.doctor_city || formErrors?.doctor_city}</p>
                )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? <>
                {isEdit ? <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectDoctorCity === "" ? true : false}
                    onChange={(e) =>
                    e != null ? setPinCode(e.value) : setPinCode("")
                    }
                    className="searchable-select"
                    name="doctor_zipcode"
                    defaultValue={{ label: personalDetail.doctor_zipcode, value: personalDetail.doctor_zipcode }}
                  /> : <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectDoctorCity === "" ? true : false}
                    onChange={(e) =>
                    e != null ? setPinCode(e.value) : setPinCode("")
                    }
                    className="searchable-select"
                    name="doctor_zipcode"
                    // value={personalDetail.doctor_zipcode}
                />}
                </> : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.doctor_zipcode)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select Pincode
                </label>
                {selectDoctorState === "" && (
                <p className="p-error">{errors?.doctor_zipcode || formErrors?.doctor_zipcode}</p>
                )}
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_block"
                type="text"
                name="doctor_block"
                onChange={(e) => personalchk("doctor_block", e)}
                value={
                  !isDisabled ? personalDetail.doctor_block : stringFormatHandleEmpty(data.doctor_block)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Block
              </label>
              <p className="p-error">{errors?.doctor_block || formErrors?.doctor_block}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_village"
                type="text"
                name="doctor_village"
                onChange={(e) => personalchk("doctor_village", e)}
                value={
                  !isDisabled ? personalDetail.doctor_village : stringFormatHandleEmpty(data.doctor_village)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Village
              </label>
              <p className="p-error">{errors?.doctor_village || formErrors?.doctor_village}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_phone"
                type="number"
                name="doctor_phone"
                onChange={(e) => personalchk("doctor_phone", e)}
                value={
                  !isDisabled ? personalDetail.doctor_phone : stringFormatHandleEmpty(data.doctor_phone)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Mobile No.
              </label>
              <p className="p-error">{errors?.doctor_phone || formErrors?.doctor_phone}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_email"
                type="text"
                name="doctor_email"
                onChange={(e) => personalchk("doctor_email", e)}
                value={
                  !isDisabled ? personalDetail.doctor_email : stringFormatHandleEmpty(data.doctor_email)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Email
              </label>
              <p className="p-error">{errors?.doctor_email || formErrors?.doctor_email}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_location"
                type="text"
                name="doctor_location"
                onChange={(e) => personalchk("doctor_location", e)}
                value={
                  !isDisabled ? personalDetail.doctor_location : stringFormatHandleEmpty(data.doctor_location)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Location
              </label>
              <p className="p-error">{errors?.doctor_location || formErrors?.doctor_location}</p>
            </div>
        </div>}
        
        {!isEdit && isShow == 0 && <div className="row row-cols-1 row-cols-lg-5 gy-4 form pb-3">
            {!isDisabled && new Array(doctorDocumentCount).fill(0).map((item, idx) => {
            return (
                <div key={idx} style={{ padding: "19px" }}>
                {!isDisabled && (
                    <label
                    htmlFor="Other"
                    style={{ color: "#29166F", fontSize: "18px" }}
                    >
                    Doctor Image {idx + 1}
                    </label>
                )}
                {!doctorPhotoUpload[idx] &&
                !personalDetail[`doctor_upload-${idx}`] &&
                !isDisabled ? (
                    <div>
                    <label>
                        {!doctorPhotoUpload[idx] && loaderCount[idx] ? (
                        <div
                            className="Group"
                            src="images/not-found.png"
                            alt="alternative"
                        >
                            <span className="spinner-border spinner-border-lg"></span>
                        </div>
                        ) : (
                        <img
                            style={{ height: "190px" }}
                            src={ImagePlaceholder}
                            alt="alternative"
                        ></img>
                        )}
                        <input
                        type="file"
                        className="d-none"
                        size="60"
                        name="file"
                        disabled={doctorPhotoUpload.length === 10}
                        onChange={(e) => uploadSingleFile(e, "doctor_upload-" + idx)}
                        />
                    </label>
                    {!doctorPhotoUpload[idx] && (
                        <p className="p-error">
                        {errors.doctorPhotoUpload || formErrors.doctorPhotoUpload}
                        </p>
                    )}
                    </div>
                ) : (
                    <div className="row form-group">
                    {isDisabled && data?.[`doctor_upload-${idx}`] !== null && (
                        <div className="img-preview">
                        {data[`doctor_upload-${idx}`]?.includes(".pdf") == true ? (
                            <a
                            className="btn btn-primary"
                            href={file(data?.[`doctor_upload-${idx}`])}
                            target="_blank"
                            >
                            Preview PDF File
                            </a>
                        ) : (
                            <LazyLoadImage
                            effect="blur"
                            src={file(data?.[`doctor_upload-${idx}`])}
                            alt="preview"
                            />
                        )}
                        </div>
                    )}
                    {!isDisabled && (
                        <div className="img-preview">
                        {(
                            doctorPhotoUpload?.[idx] ||
                            personalDetail[`doctor_upload-${idx}`]
                        )?.includes(".pdf") == true ? (
                            <a
                            className="btn btn-primary"
                            href={file(
                                doctorPhotoUpload[idx] ||
                                personalDetail[`doctor_upload-${idx}`]
                            )}
                            target="_blank"
                            >
                            Preview PDF File
                            </a>
                        ) : (
                            <img
                            src={file(
                                doctorPhotoUpload?.[idx] ||
                                personalDetail[`doctor_upload-${idx}`]
                            )}
                            alt="preview"
                            />
                        )}
                        <img
                            onClick={() => deleteFile(idx, "doctor_upload")}
                            className="clear"
                            src={X}
                            alt="clear-preview"
                        />
                        </div>
                    )}
                    </div>
                )}
                </div>
            );
            })}
        </div>}

        {isEdit && <div className="row row-cols-1 row-cols-lg-5 gy-4 form pb-3">
            {!isDisabled && new Array(doctorDocumentCount).fill(0).map((item, idx) => {
            return (
                <div key={idx} style={{ padding: "19px" }}>
                {!isDisabled && (
                    <label
                    htmlFor="Other"
                    style={{ color: "#29166F", fontSize: "18px" }}
                    >
                    Doctor Image {idx + 1}
                    </label>
                )}
                {!doctorPhotoUpload[idx] &&
                !doctorPhotoUpload[`${idx}`] &&
                !isDisabled ? (
                    <div>
                    <label>
                        {!doctorPhotoUpload[idx] && loaderCount[idx] ? (
                        <div
                            className="Group"
                            src="images/not-found.png"
                            alt="alternative"
                        >
                            <span className="spinner-border spinner-border-lg"></span>
                        </div>
                        ) : (
                        <img
                            style={{ height: "190px" }}
                            src={ImagePlaceholder}
                            alt="alternative"
                        ></img>
                        )}
                        <input
                        type="file"
                        className="d-none"
                        size="60"
                        name="file"
                        disabled={doctorPhotoUpload.length === 10}
                        onChange={(e) => uploadSingleFile(e, "doctor_upload-" + idx)}
                        />
                    </label>
                    {!doctorPhotoUpload[idx] && (
                        <p className="p-error">
                        {errors.doctorPhotoUpload || formErrors.doctorPhotoUpload}
                        </p>
                    )}
                    </div>
                ) : (
                    <div className="row form-group">
                    {isDisabled && data?.[`doctor_upload-${idx}`] !== null && (
                        <div className="img-preview">
                        {data[`doctor_upload-${idx}`]?.includes(".pdf") == true ? (
                            <a
                            className="btn btn-primary"
                            href={file(data?.[`doctor_upload-${idx}`])}
                            target="_blank"
                            >
                            Preview PDF File
                            </a>
                        ) : (
                            <LazyLoadImage
                            effect="blur"
                            src={file(data?.[`doctor_upload-${idx}`])}
                            alt="preview"
                            />
                        )}
                        </div>
                    )}
                    {!isDisabled && (
                        <div className="img-preview">
                        {(
                            doctorPhotoUpload?.[idx] ||
                            doctorPhotoUpload[`${idx}`]
                        )?.includes(".pdf") == true ? (
                            <a
                            className="btn btn-primary"
                            href={file(
                                doctorPhotoUpload[idx] ||
                                doctorPhotoUpload[`${idx}`]
                            )}
                            target="_blank"
                            >
                            Preview PDF File
                            </a>
                        ) : (
                            <img
                            src={file(
                                doctorPhotoUpload?.[idx] ||
                                doctorPhotoUpload[`${idx}`]
                            )}
                            alt="preview"
                            />
                        )}
                        <img
                            onClick={() => deleteFile(idx, "doctor_upload")}
                            className="clear"
                            src={X}
                            alt="clear-preview"
                        />
                        </div>
                    )}
                    </div>
                )}
                </div>
            );
            })}
        </div>}

        <div className="row row-cols-1 row-cols-lg-5 gy-4 form pb-3">
        {isDisabled && otherdata?.map((item, idx) => {
            // if( item !==null) return
            return (
              item !="" && <div key={idx} style={{ padding: "19px" }}>
                <label
                  htmlFor="Other"
                  style={{ color: "#29166F", fontSize: "18px" }}
                >
                  Doctor Document {idx + 1}
                </label>

                <div className="row form-group">
                  {isDisabled && item !== null && (
                    <div className="img-preview">
                      {item?.includes(".pdf") == true ? (
                        <a
                          className="btn btn-primary"
                          href={file(item)}
                          target="_blank"
                        >
                          Preview PDF File
                        </a>
                      ) : (
                        <LazyLoadImage
                          effect="blur"
                          src={file(item)}
                          alt="preview"
                        />
                      )}
                    </div>
                  )}
                </div>
              </div> 
            );
          })}
        </div>

      {!isDisabled && isShow == 0 &&  doctorDocumentCount < 5 && (
        <h6
          style={{ cursor: "pointer" }}
          onClick={() => setDoctorDocumentCount(doctorDocumentCount + 1)}
        >
          <button className="btn btn-primary d-block m-2 m-auto text-center">
            Add More +
          </button>
        </h6>
      )}
    </>
  );
};

export default ClaimDoctorDetails;
