import axios from "../../config/axios";

export const updateTextDetails = (photo) => {
  return new Promise((resolve) => {
    axios
      .patch("pos/updateTextDetails", photo)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};
export const addPos = (photo) => {
  return new Promise((resolve) => {
    axios
      .post("admin/addPos", photo)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};


export const updateBankDetails = (payload) => {
  return new Promise((resolve) => {
    axios
      .patch("admin/updateBankDetails", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const updatePersonalDetails = (payload) => {
  return new Promise((resolve) => {
    axios
      .patch("admin/updatePersonalDetails", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const updateEducationDetails = (payload) => {
  return new Promise((resolve) => {
    axios
      .patch("admin/updateEducationDetails", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const addCattleInfo = (data) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/cattleOnSubmit", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
export const editCattleInfo = (data) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/endorsementOnSubmit", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
export const updateStatusDetails = (payload) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/editPolicyStatus", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const updateEarTagNo = (payload) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/updateEarTag", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const deleteRowsCattle = (payload) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/deleteRowsCattle", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const addCattleInsurer = (data) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/addCattleInsurer", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};


export const addCattleUpdateInsurer = (data) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/updateCattleInsurer", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const addCattleDeleteInsurer = (id) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/deleteCattleInsurer", id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};


export const deleteAnimalData = (id) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/deleteAnimal", id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const deleteEndorsementData = (id) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/deleteEndorsement", id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};


export const listEndorsement = () => {
  return new Promise((resolve) => {
    axios
      .post("cattle/listEndorsement")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const addClaim = (data) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/cattleClaimOnSubmit", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const listClaim = () => {
  return new Promise((resolve) => {
    axios
      .post("cattle/listCattleClaim")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const claimSearchData = (data) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/searchClaim", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const editClaim = (data) => {
  return new Promise((resolve) => {
    axios
      .post("cattle/updateClaimProcess", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const addDoctor = (data) => {
  return new Promise((resolve) => {
    axios
      .post("doctor/addDoctor", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const addInvestigator = (data) => {
  return new Promise((resolve) => {
    axios
      .post("investigator/addInvestigator", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const viewInvestigator = (id) => {
  return new Promise((resolve) => {
    axios
      .post("investigator/viewInvestigator", {id: id})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const viewDoctor = (id) => {
  return new Promise((resolve) => {
    axios
      .post("doctor/viewDoctor", {id: id})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const editDoctor = (data) => {
  return new Promise((resolve) => {
    axios
      .post("doctor/updateDoctor", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const editInvestigator = (data) => {
  return new Promise((resolve) => {
    axios
      .post("investigator/updateInvestigator", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const listRole = () => {
  return new Promise((resolve) => {
    axios
      .post("admin/listRoleMaster")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const listRolePermission = (id) => {
  return new Promise((resolve) => {
    axios
      .post("admin/listPermissionOnRole", {role_id: id})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const addPermissionMaster = (data) => {
  return new Promise((resolve) => {
    axios
      .post("admin/addPermissionMaster", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getAdminRolesInfo = (role) => {
  return new Promise((resolve) => {
    axios
      .post("pos/posRollAccess", {role_id: role})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const updateDoctorRequestStatus = (data) => {
  return new Promise((resolve) => {
    axios
      .post("doctor/updateRequestStatus", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const updateInvestigatorRequestStatus = (data) => {
  return new Promise((resolve) => {
    axios
      .post("investigator/updateRequestStatus", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const updateSurveyorRequestStatus = (data) => {
  return new Promise((resolve) => {
    axios
      .post("surveyor/updateRequestStatus", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};